<template>
  <div>
    <div class="add-image">
      <div v-if="isAllAreas">
        <div class="contral">
          <div class="contral-list cur disabled">
            <img src="@/assets/images/custom/addimg2.png" alt="">
            <span class="disabled">Add Image</span>
          </div>
          <div class="contral-list cur disabled" style="margin-left: 12px">
            <img src="@/assets/images/custom/addtext2.png" alt="">
            <span class="disabled">Add Text</span>
          </div>
        </div>
        <div class="image-main">
          <div class="image-header" @click="isCollapsed = !isCollapsed">
            Layers
            <img src="@/assets/images/custom/xia_jiantou.png" :style="isCollapsed ? '' : 'transform: rotate(180deg)'" alt="">
          </div>
        </div>
      </div>
      <div v-else>
        <div class="contral">
          <div class="contral-list cur " :class="canvas.enableTextAndImage ? '' : 'disabled'" @click="openAddImageDialog(canvas.enableTextAndImage)">
            <img v-if="canvas.enableTextAndImage" src="@/assets/images/custom/addimg.png" alt="">
            <img v-if="!canvas.enableTextAndImage" src="@/assets/images/custom/addimg2.png" alt="">
            <span :class="canvas.enableTextAndImage ? '' : 'disabled'">Add Image</span>
          </div>
          <div class="contral-list cur " :class="canvas.enableTextAndImage ? '' : 'disabled'" style="margin-left: 12px" @click="openFontDialog">
            <img v-if="canvas.enableTextAndImage" src="@/assets/images/custom/addtext.png" alt="">
            <img v-if="!canvas.enableTextAndImage" src="@/assets/images/custom/addtext2.png" alt="">
            <span :class="canvas.enableTextAndImage ? '' : 'disabled'">Add Text</span>
          </div>
        </div>
        <div class="image-main">
          <div class="image-header" @click="isCollapsed = !isCollapsed">
            Layers
            <img src="@/assets/images/custom/xia_jiantou.png" :style="isCollapsed ? '' : 'transform: rotate(180deg)'" alt="">
          </div>
          <transition name="collapse">
            <div v-if="isCollapsed" class="image-con">
              <draggable v-model="layers" chosen-class="chosen" force-fallback="true" group="people" animation="1000" @start="onStart" @end="onEnd">
                <transition-group>
                  <div v-for="(item,index) in layers" :key="index" :class="item.id == canvasActived.id ? 'curr' : ''" @click="handleItem(item)">
                    <div v-if="item.type == 'image' || item.type == 'text'" class="image-list">
                      <div class="yiDong" />
                      <!-- 左边 -->
                      <div class="image-list-left">
                        <div class="trans-bg" />
                        <img v-if="item.type == 'image'" :src="item.fileUrl" class="fileUrl" alt="">
                        <div v-if="item.type == 'text'" class="text">
                          <img src="@/assets/images/custom/txt.png" alt="">
                        </div>
                      </div>
                      <!-- 中间 -->
                      <div class="image-list-center">
                        <div class="image-list-name text-ellipsis">
                          <span v-if="item.type == 'image'">{{ item.fileName }}</span>
                          <span v-if="item.type == 'text'">{{ item.text }}</span>
                        </div>
                        <div class="image-list-desc" :class="item.dpiClass">
                          {{ item.dpi }}
                        </div>
                      </div>
                      <div v-if="item.repeatVisible == undefined" class="image-list-icon2">
                        <img v-show="!item.visible " src="@/assets/images/custom/biyan.png" alt="" @click.stop="handleHide(item)">
                      </div>
                      <div v-if="item.repeatVisible != undefined" class="image-list-icon2">
                        <img v-show="!item.repeatVisible" src="@/assets/images/custom/biyan.png" alt="" @click.stop="handleHide(item)">
                      </div>
                      <div class="image-list-icon">
                        <img src="@/assets/images/custom/shanchu.png" alt="" @click.stop="handleRemove($event,item)">
                        <!-- 平铺的 -->
                        <template v-if="item.isbgImage">
                          <img v-show="item.repeatVisible" src="@/assets/images/custom/eyes.png" alt="" @click.stop="handleHide(item)">
                          <img v-show="!item.repeatVisible" src="@/assets/images/custom/biyan.png" alt="" @click.stop="handleHide(item)">
                        </template>
                        <template v-else>
                          <img v-show="item.visible" src="@/assets/images/custom/eyes.png" alt="" @click.stop="handleHide(item)">
                          <img v-show="!item.visible" src="@/assets/images/custom/biyan.png" alt="" @click.stop="handleHide(item)">
                        </template>
                      </div>
                    </div>
                  </div>
                </transition-group>
              </draggable>
              <!-- 背景 -->
              <div v-if="canvas.backgroundColor" class="image-list" @click="handleBackground">
                <!-- <div class="yiDong" /> -->
                <!-- 左边 -->
                <div class="image-list-left" style="margin-left: 40px">
                  <div class="background-color-box">
                    <span class="backgroundColor" :style="{'backgroundColor': canvas.backgroundColor}" />
                  </div>
                </div>
                <!-- 中间 -->
                <div class="image-list-center">
                  <div class="image-list-name text-ellipsis">
                    <span>{{ canvas.backgroundColor }}</span>
                  </div>
                </div>
                <div class="image-list-icon2">
                  <img v-show="canvas.backgroundColor == 'transparent'" src="@/assets/images/custom/biyan.png" alt="" @click.stop="handleBackgroundHide(item)">
                </div>
                <div class="image-list-icon">
                  <img src="@/assets/images/custom/shanchu.png" alt="" @click.stop="handleBackgroundRemove($event,item)">
                  <template>
                    <img v-if="canvas.backgroundColor == 'transparent'" src="@/assets/images/custom/biyan.png" alt="" @click.stop="handleBackgroundHide(item)">
                    <img v-else src="@/assets/images/custom/eyes.png" alt="" @click.stop="handleBackgroundHide(item)">
                  </template>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <add-image ref="addImage" :oss-data="ossData" :canvas2="canvas" :save-canvas-state="saveCanvasState" />
    <add-text ref="addText" :canvas2="canvas" :save-canvas-state="saveCanvasState" />
  </div>
</template>
<script>
import addImage from './components/add_image.vue'
import addText from './components/add_text.vue'
import BaseView from '@/components/base/BaseView'
import draggable from 'vuedraggable'
export default {
  components: {
    draggable,
    addImage,
    addText
  },
  extends: BaseView,
  props: {
    saveCanvasState: {
      type: Function
    },
    isAllAreas2: {
      type: Boolean
    },
    canvas: {
      type: Object,
      default: () => ({})
    },
    // oss信息
    ossData: Object
  },
  data() {
    return {
      isAllAreas: false,
      isCollapsed: true,
      // 图片列表
      imageList: [],
      // 分页
      page: {
        current: 1,
        size: 15,
        fileType: 1
      },
      // 选中的图片ID
      selectImageId: '',
      canvas2: {},
      layers: [],
      canvasActived: {}
    }
  },
  watch: {
    isAllAreas2(newValue, oldValue) {
      this.isAllAreas = newValue
    },
    canvas: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== undefined) {
        // newValue.renderOnAddRemove = true
          const actived = newValue.getActiveObject()
          if (actived) {
            this.canvasActived = actived
          }
          const arr = newValue.getObjects()
          this.canvas2 = newValue
          this.layers = arr.reverse()
        // this.$forceUpdate()
        }
      },
      deep: true
    }
  },
  created() {
    if (this.canvas) {
      this.canvas2 = this.canvas
    }
    if (this.isAllAreas2) {
      this.isAllAreas = this.isAllAreas2
    }
  },
  mounted() {
  },
  methods: {
    handleBackground() {
      const param = {
        isShow: true,
        backgroundColor: this.canvas.backgroundColor
      }
      this.$bus.emit('showBackgroundSet', param)
    },
    handleItem(item) {
      const param = {
        isShow: false,
        backgroundColor: ''
      }
      this.$bus.emit('showBackgroundSet', param)
      console.log('handleItem...', item)
      const canvas = this.canvas
      canvas.setActiveObject(item)
      const evt = canvas.getActiveObject()
      if (evt) {
        evt.transparentCorners = true // 选中时，角是被填充了。true 空心；false 实心
        evt.hasBorders = true // 选中时，是否显示边，默认true（显示）
        evt.borderColor = 'red' // 选中时，边为红色
        evt.borderScaleFactor = 2 // 选中时，边的粗细
        evt.cornerColor = '#ff0000' // 选中时，角的颜色是 红色
        evt.cornerStrokeColor = '#ff0000' // 选中时，角的边框的颜色是 粉色
        evt.cornerStyle = 'rect' // 选中时，叫的属性。默认rect 矩形；circle 圆形
        evt.cornerSize = 24 // 选中时，角的大小为20
        evt.padding = 0 // 选中时，选框离图形的距离
        evt.borderOpacityWhenMoving = 0.6 // 当对象活动和移动时，对象控制边界的不透明度,
      }
      canvas.renderAll()
    },
    test(val) {
      const canvas = this.canvas
      const actived = canvas.getActiveObject()
      actived.set('layer', val)
      canvas.renderAll()
    },
    // 开始拖拽事件
    onStart() {
      const objects = this.canvas.getObjects()
      console.log('onStart...', objects)
      this.drag = true
    },
    // 拖拽结束事件
    onEnd() {
      this.drag = false
      setTimeout(() => {
        this.layers.reverse().forEach((item, index) => {
          this.canvas.moveTo(item, index + 1)
        })
      }, 1500)
    },
    handleRemove(event, obj) {
      event.stopPropagation()
      const canvas = this.canvas
      if (obj.type === 'backgroundColor') {
        console.log("obj.type === 'backgroundColor'")
        canvas.setBackgroundColor('', canvas.renderAll.bind(canvas))
        canvas.renderAll() // 重新渲染 canvas
        this.saveCanvasState()
        return
      } else if (obj.isbgImage) { // 删除拿去平铺的图片
        console.log('obj.type === obj.isbgImage')
        const arr = canvas.getObjects()
        arr.forEach((item, index) => {
          if (item.types === 'repeat') {
            canvas.remove(arr[index])
          }
        })
        canvas.remove(obj)
        canvas.renderAll() // 重新渲染 canvas
        this.saveCanvasState()
        return
      } else {
        console.log('obj.type === else')
        canvas.remove(obj)
        canvas.renderAll() // 重新渲染 canvas
      }
      // const arr = canvas.getObjects()
      // arr.forEach((item, index) => {
      //   if (item.id === obj.id) {
      //     canvas.remove(arr[index])
      //   }
      // })
      canvas.renderAll() // 重新渲染 canvas
      this.saveCanvasState()
    },
    handleBackgroundRemove() {
      this.canvas.setBackgroundColor('', this.canvas.renderAll.bind(this.canvas))
      this.canvas.renderAll() // 重新渲染 canvas
    },
    handleBackgroundHide() {
      if (this.canvas.backgroundColor !== 'transparent') {
        this.canvas.oraginColor = this.canvas.backgroundColor
        this.canvas.backgroundColor = 'transparent'
        this.canvas.setBackgroundColor('transparent', this.canvas.renderAll.bind(this.canvas))
        this.canvas.renderAll() // 重新渲染 canvas
        this.saveCanvasState()
        return
      } else if (this.canvas.backgroundColor === 'transparent') {
        this.canvas.setBackgroundColor(this.canvas.oraginColor, this.canvas.renderAll.bind(this.canvas))
        this.canvas.backgroundColor = this.canvas.oraginColor
        this.canvas.renderAll() // 重新渲染 canvas
        this.saveCanvasState()
      }
    },
    handleHide(obj) {
      console.log('obj....'.obj)
      const canvas = this.canvas
      const arr = canvas.getObjects()
      arr.forEach((item, index) => {
        if (item.id === obj.id) {
          if (arr[index].visible) {
            arr[index].visible = false
          } else {
            arr[index].visible = true
          }
          // 如果这张图片是拿去平铺的
          if (obj.isbgImage === true && obj.repeatVisible === true) {
            arr[index].repeatVisible = false
            arr[index].visible = false
            this.removeRepeat()
          } else if (obj.isbgImage === true && obj.repeatVisible === false) {
            arr[index].visible = true
            arr[index].repeatVisible = true
            canvas.setActiveObject(arr[index])
            canvas.renderAll()
            this.setBackgroundRepeat()
          }
          // 取消选中
          canvas.discardActiveObject()
        }
      })
      this.$forceUpdate()
      canvas.renderAll() // 重新渲染 canvas
      this.saveCanvasState()
    },
    // 删除平铺
    removeRepeat() {
      const canvas = this.canvas
      const arr = canvas.getObjects()
      arr.forEach((item, index) => {
        if (item.types === 'repeat') {
          canvas.remove(arr[index])
        }
      })
      canvas.renderAll() // 重新渲染 canvas
    },
    // 打开添加图片弹窗
    openAddImageDialog(data) {
      if (data) {
        this.$refs.addImage.addImageIsShowDialog = true
      }
    },
    openFontDialog() {
      this.$refs.addText.addTextDialog = true
    }
  }
}
</script>
<style scoped lang="scss">
.background-color-box{
  width: 40px;
  height: 40px;
  border: 1px solid #D9D9D9;
  span{
    display: inline-block;
    width: 26px;
    height: 26px;
    position: relative;
    top: 6px;
    left: 6px;
  }
}
.collapse-enter-active,
.collapse-leave-active {
  transition: height 0.5s ease;
}
.collapse-enter,
.collapse-leave-to {
  height: 0;
  overflow: hidden;
}
.sku-title {
  color: #333;
  display: flex;
  align-items: center;
  width: 316px;
  height: 65px;
  font-size: 18px;
  padding: 11px 40px 11px 19px;
  box-sizing: border-box;
  font-weight: bold;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.add-image {
  width: 353px;
  .contral {
    display: flex;
    align-items: center;
    height: 120px;
    justify-content: space-between;
    padding: 0 16px;
    &-list {
      text-align: center;
      width: 152px;
      height: 85px;
      border-radius: 12px;
      opacity: 1;
      border: 1px dashed #5F5F5F;
      &:hover{
        border: 1px dashed #ff5347;
      }
      &.disabled{
        border: 1px dashed #C4C4C4;
      }
      img {
        display: block;
        margin: 0 auto;
        margin-top: 13px;
        width: 32px;
        height: 32px;
      }
      span {
        color: #444444;
        font-size: 16px;
        &.disabled{
          color: #C4C4C4;
        }
      }
    }
  }
  .image-main {
    .image-header {
      width: 353px;
      height: 36px;
      display: flex;
      align-items: center;
      background: #e2e2e2;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 0 20px;
      font-size: 16px;
      img {
        margin-left: auto;
        width: 16px;
      }
    }
    .image-con {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 315px);
      .curr{
        background-color: #FFF4F4;
        border: 1px solid #FFF4F4 !important;
      }
      .image-list {
        display: flex;
        align-items: center;
        height: 60px;
        padding-right: 20px;
        position: relative;
        cursor: pointer;
        border: 1px solid #fff;
        &:hover {
          border: 1px solid #ffcac6;
        }
        &:hover .image-list-icon{
          display: block;
        }
        &-icon {
          position: absolute;
          bottom: 18px;
          right: 20px;
          display: none;
          img {
            width: 24px;
            height: 24px;
          }
        }
        &-icon2 {
          position: absolute;
          bottom: 18px;
          right: 20px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .yiDong{
          width: 42px;
          height: 60px;
          background: url(../../../assets/images/custom/yidong.png) no-repeat;
          background-size: 100%;
          &:hover{
            background: url(../../../assets/images/custom/yidong2.png) no-repeat;
            background-size: 100%;
          }
        }
        &-left {
          border: 1px solid #D9D9D9;
          border-radius: 4px 4px 4px 4px;
          overflow: hidden;
          position: relative;
          .trans-bg{
            background-image: url("../../../assets/img/bg.svg");
            background-repeat: repeat;
            background-size: 38%;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .fileUrl {
            width: 38px;
            height: 38px;
            object-fit: contain;
            position: relative;
            z-index: 1;
          }
          .text{
            width: 40px;
            height: 40px;
            background-color: #fff;
            border: 1px solid #D9D9D9;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            img{
              width: 18px;
              height: 20px;
            }
          }
        }
        &-center {
          margin-left: 9px;
          .image-list-name {
            font-size: 16px;
            font-weight: bold;
            width: 160px;
          }
          .image-list-desc {
            font-size: 12px;
            color: #46ce51;
            &.Good {
              color: #52c41a;
            }
            &.Normal{
              color: #FF9A40
            }
            &.Bad {
              color: red;
            }
          }
        }
      }
    }
  }
}
</style>
