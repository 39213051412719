<template>
  <div class="mask" v-if="show">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    show: Boolean
  }
}
</script>
<style scoped>
.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  background-color: rgba(0,0,0,0.5);
}
</style>
