<template>
  <div class="image-opera">
    <div class="image-opera-header">
      <span>Attribute Setting</span>
      <a-icon type="down" class="image-opera-header-icon" @click="toggleHeight" />
    </div>
    <div :style="{ height: getHeight }" class="animate-height">
      <div class="image-opera-align ">
        <div class="image-opera-align-list image-opera-align-left">
          <div class="image-opera-align-list-top">
            Align
          </div>
          <div class="image-opera-align-list-bottom">
            <!-- 左对齐 -->
            <a-tooltip placement="bottom">
              <template slot="title">
                Align Left
              </template>
              <div class="image-opera-align-list-bottom-list zuoduiqi" @click="align('left')" />
            </a-tooltip>
            <a-tooltip placement="bottom">
              <template slot="title">
                Align Horizontally
              </template>
              <div class="image-opera-align-list-bottom-list cuizhijuzhong" @click="align('horizontally')" />
            </a-tooltip>
            <a-tooltip placement="bottom">
              <template slot="title">
                Align Right
              </template>
              <div class="image-opera-align-list-bottom-list youduiqi" @click="align('right')" />
            </a-tooltip>
            <a-tooltip placement="bottom">
              <template slot="title">
                Align Top
              </template>
              <div class="image-opera-align-list-bottom-list dingduiqi" @click="align('top')" />
            </a-tooltip>
            <!-- 垂直居中 -->
            <a-tooltip placement="bottom">
              <template slot="title">
                Align Vertically
              </template>
              <div class="image-opera-align-list-bottom-list shuipingjuzhong" @click="align('verticalCenter')" />
            </a-tooltip>
            <!-- 底对齐 -->
            <a-tooltip placement="bottom">
              <template slot="title">
                Align Bottom
              </template>
              <div class="image-opera-align-list-bottom-list diduiqi" @click="align('bottom')" />
            </a-tooltip>
          </div>
        </div>
        <div class="image-opera-align-list image-opera-align-right">
          <div class="image-opera-align-list-top">
            Flip
          </div>
          <div class="image-opera-align-list-bottom">
            <a-tooltip placement="bottom">
              <template slot="title">
                Reflect Horizontally
              </template>
              <div class="image-opera-align-list-bottom-list shuipingfanzhuan" @click="handFlip('horizontal')" />
            </a-tooltip>
            <a-tooltip placement="bottom">
              <template slot="title">
                Reflect Vertically
              </template>
              <div class="image-opera-align-list-bottom-list cuizhifanzhuan" @click="handFlip('vertical')" />
            </a-tooltip>
          </div>
        </div>
      </div>
      <div class="image-opera-scale">
        <div class="image-opera-scale-list">
          <div class="image-opera-scale-list-top">
            Scale
          </div>
          <div class="image-opera-scale-list-box">
            <img src="@/assets/images/custom/dingjiekuang.png" class="image-opera-scale-list-box-img">
            <input ref="scale" :value="parseInt(change.scaleX * 100)+'%'" type="text" @blur="blurInput($event,'scale')">
            <div class="image-opera-scale-list-box-bs" @mousedown="onMouseDown($event,'scale')">
              <img src="@/assets/images/custom/sanshu.png" class="image-opera-scale-list-box-img">
            </div>
          </div>
        </div>
        <div class="image-opera-scale-list" style="margin-left: 15px">
          <div class="image-opera-scale-list-top">
            Background
          </div>
          <div class="image-opera-scale-list-box bgInput" :class="BgColor !== 'transparent' ? 'bgInpuActived' : ''">
            <!-- 空白情况下使用图片 -->
            <img src="@/assets/images/custom/beijingtouming.png" class="image-opera-scale-list-box-img" :style="{opacity: isTran ? 1 : 0}">
            <!-- <span class="image-opera-scale-list-box-color" /> -->
            <input v-model="BgColor" readonly type="color" class="colorpick" :style="{opacity: isTran ? 0 : 1}">
            <!-- <span>#</span> -->
            <input v-model="BgColor" oninput="if(this.value.length === 1) {this.value = '#';} else if (this.value.charAt(0) !== '#') {this.value = '#' + this.value.substring(1);}" type="text" style="width: 60px" maxlength="7" @focus="onBackgroundFocus" @blur="onBackgroundBlur">
          </div>
        </div>
      </div>
      <div class="image-opera-scale">
        <div class="image-opera-scale-list">
          <div class="image-opera-scale-list-top">
            Rotate
          </div>
          <div class="image-opera-scale-list-box">
            <img src="@/assets/images/custom/xuanzhuan.png" class="image-opera-scale-list-box-img">
            <input ref="rotate" :value="parseInt(change.angle)+'°'" type="text" @blur="blurInput($event,'rotate')">
            <div class="image-opera-scale-list-box-bs" @mousedown="onMouseDown($event, 'rotate')">
              <img src="@/assets/images/custom/sanshu.png" class="image-opera-scale-list-box-img">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="image-opera-header">
      <span>Pattern Layout</span>
      <a-icon type="down" class="image-opera-header-icon" @click="toggleHeight2" />
    </div>
    <div :style="{ height: getHeight2 }" class="animate-height">
      <div class="image-opera-pu">
        <div class="oplist">
          <a-tooltip placement="bottom">
            <template slot="title">
              Normal
            </template>
            <div class="image-opera-pu-list zhengchang" :class="!change.isbgImage ? 'zhengchang2' : 'zhengchang'" @click="handTiling('nomal')" />
          </a-tooltip>
          <p>Normal</p>
        </div>
        <div class="oplist">
          <a-tooltip placement="bottom">
            <template slot="title">
              Tile
            </template>
            <div class="image-opera-pu-list jichu" :class="change.isbgImage ? 'jichu2' : 'jichu'" @click="handTiling('basic')" />
          </a-tooltip>
          <p>Tile</p>
        </div>
      </div>
    <!-- <div v-if="change.isbgImage" class="image-opera-scale">
        <div class="image-opera-scale-list">
          <div class="image-opera-scale-list-top">
            Horizontal Spacing
          </div>
          <div class="image-opera-scale-list-box">
            <img src="@/assets/images/custom/dingjiekuang.png" class="image-opera-scale-list-box-img">
            <input ref="gapX" :value="change.gapX+'%'" type="text" @blur="blurInput($event,'gapX')">
            <div class="image-opera-scale-list-box-bs" @mousedown="onMouseDown($event, 'gapX')">
              <img src="@/assets/images/custom/sanshu.png" class="image-opera-scale-list-box-img">
            </div>
          </div>
        </div>
        <div class="image-opera-scale-list" style="margin-left: 15px">
          <div class="image-opera-scale-list-top">
            Vertical Spacing
          </div>
          <div class="image-opera-scale-list-box">
            <img src="@/assets/images/custom/xuanzhuan.png" class="image-opera-scale-list-box-img">
            <input ref="gapY" :value="change.gapY+'%'" type="text" @blur="blurInput($event,'gapY')">
            <div class="image-opera-scale-list-box-bs" @mousedown="onMouseDown($event, 'gapY')">
              <img src="@/assets/images/custom/sanshu.png" class="image-opera-scale-list-box-img">
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>

import BaseView from '@/components/base/BaseView'
export default {
  extends: BaseView,
  props: {
    canvas: {
      type: Object,
      default: () => ({})
    },
    saveCanvasState: {
      type: Function
    }
  },
  data() {
    return {
      isTitle: false,
      selectChange: '',
      change: {},
      isDragging: false,
      isTran: true,
      BgColor: 'transparent',
      isExpanded: true,
      isExpanded2: true
    }
  },
  computed: {
    getHeight() {
      return this.isExpanded ? 'auto' : '0'
    },
    getHeight2() {
      return this.isExpanded2 ? 'auto' : '0'
    }
  },
  watch: {
    canvas: {
      handler(newValue, oldValue) {
        if (newValue.backgroundColor) {
          // .match(/#[a-zA-Z0-9]*/)
          this.BgColor = newValue.backgroundColor
        } else {
          this.BgColor = 'transparent'
        }
        const actived = newValue.getActiveObject()
        if (actived) {
          this.change = actived
          // if (actived.isbgImage) {
          //   this.setBackgroundRepeat(this.change.gapX, this.change.gapY)
          // }
          this.$forceUpdate()
        }
      },
      deep: true
    },
    BgColor(newValue, oldValue) {
      if (newValue) {
        if (newValue !== 'transparent') {
          this.canvas.backgroundColor = newValue
          this.canvas.setBackgroundColor(newValue, this.canvas.renderAll.bind(this.canvas))
          this.isTran = false
          this.saveCanvasState()
        }
      }
    }
  },
  mounted() {
    const _this = this
    document.addEventListener('mouseup', function(event) {
      console.log('鼠标放开了....')
      if (_this.isDragging) {
        _this.onMouseUp()
      }
    })
    window.addEventListener('mousemove', this.onMouseMove) // 监听鼠标移动事件
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.onMouseUp)
    document.removeEventListener('mousemove', this.onMouseMove) // 停止监听鼠标移动事件
  },
  methods: {
    onBackgroundFocus() {
      if (this.BgColor === 'transparent') {
        this.BgColor = ''
      }
    },
    onBackgroundBlur() {
      if (this.BgColor === '') {
        this.BgColor = 'transparent'
      }
    },
    toggleHeight() {
      this.isExpanded = !this.isExpanded
    },
    toggleHeight2() {
      this.isExpanded2 = !this.isExpanded2
    },
    blurInput(e, val) {
      let value = 0
      if (val === 'scale') {
        value = this.$refs.scale.value.slice(0, -1)
        this.change.set({
          scaleX: Number(value) / 100,
          scaleY: Number(value) / 100
        })
        // 计算中心点
      }
      if (val === 'rotate') {
        value = this.$refs.rotate.value.slice(0, -1)
        this.change.rotate(Number(value))
      }
      if (val === 'gapX') {
        value = this.$refs.gapX.value.slice(0, -1)
        this.change.gapX = Number(value)
      }
      if (val === 'gapY') {
        value = this.$refs.gapY.value.slice(0, -1)
        this.change.gapY = Number(value)
      }
      this.canvas.renderAll()
      this.$forceUpdate()
      if (this.change.isbgImage) {
        this.setBackgroundRepeat(this.change.gapX, this.change.gapY)
      }
    },
    handFlip(val) {
      this.flip(val).then(res => {
        this.saveCanvasState()
      })
    },
    // 平铺画布
    handTiling(val) {
      var canvas = this.canvas
      if (val === 'basic') {
        // 创建 canvas 对象
        const objects = canvas.getObjects()
        objects.forEach(item => {
          if (item.types === 'repeat') {
            canvas.remove(item)
            canvas.renderAll()
          }
        })
        this.setBackgroundRepeat()
      }
      if (val === 'nomal') {
        const objects = canvas.getObjects()
        objects.forEach((item, index) => {
          item.set({
            isbgImage: false,
            visible: true
          })
          if (item.types === 'repeat') {
            canvas.remove(item)
          }
          canvas.renderAll()
        })
        this.saveCanvasState()
      }
    },
    // 鼠标按下
    onMouseDown(event, val) {
      event.preventDefault()
      this.selectChange = val
      this.startX = event.clientX
      this.isDragging = true
    },
    // 鼠标移动
    onMouseMove(event) {
      if (this.isDragging) {
        this.currentX = event.clientX // 记录当前鼠标位置的x坐标
        const distance = this.currentX - this.lastX // 计算鼠标移动的距离

        if (distance > 0) {
          this.onChange('add')
          console.log('向右滑动')
        // 在这里执行右滑逻辑
        } else if (distance < 0) {
          this.onChange('del')
          console.log('向左滑动')
        // 在这里执行左滑逻辑
        } else {
          console.log('未滑动')
        // 在这里执行未滑动逻辑
        }

        this.lastX = this.currentX // 更新上一次鼠标位置的x坐标
      }
    },
    // 鼠标放开
    onMouseUp() {
      console.log('放开了.......')
      this.isDragging = false
      if (this.change.isbgImage) {
        this.setBackgroundRepeat(this.change.gapX, this.change.gapY)
      }
      this.saveCanvasState()
    },
    onChange(isAdd) {
      if (this.selectChange === 'scale') {
        console.log('this.change.scaleX....', this.change.scaleX * 100)
        if (isAdd === 'add') {
          this.change.scaleX = this.change.scaleX + 0.02
          this.change.scaleY = this.change.scaleY + 0.02
        } else {
          if ((this.change.scaleX * 100) > 3) {
            this.change.scaleX = this.change.scaleX - 0.02
            this.change.scaleY = this.change.scaleY - 0.02
          }
        }
        this.change.set({
          // centeredScaling: true
          scaleX: this.change.scaleX,
          scaleY: this.change.scaleY
        })
      }
      if (this.selectChange === 'rotate') {
        if (isAdd === 'add') {
          this.change.angle = this.change.angle + 2
        } else {
          this.change.angle = this.change.angle - 2
        }
        this.change.rotate(this.change.angle)
      }
      if (this.selectChange === 'gapX') {
        if (isAdd === 'add') {
          this.change.gapX = this.change.gapX + 1
        } else {
          this.change.gapX = this.change.gapX - 1
        }
        this.change.set({
          gapX: this.change.gapX
        })
      }
      if (this.selectChange === 'gapY') {
        if (isAdd === 'add') {
          this.change.gapY = this.change.gapY + 1
        } else {
          this.change.gapY = this.change.gapY - 1
        }
        this.change.set({
          gapY: this.change.gapY
        })
      }

      this.canvas.renderAll()
      this.$forceUpdate()
    },
    // setColor(val) {
    //   this.BgColor = val
    // },
    handTitle() {
      this.isTitle = true
    },
    handCancelTitle() {
      this.isTitle = false
    }
  }
}
</script>
<style>
.ant-tooltip-arrow::before{
  background-color: #595959 !important;
}
.ant-tooltip-inner{
  background-color: #595959 !important;
  color: #fff !important;
}
</style>
<style scoped lang="scss">
.animate-height {
  transition: height 0.5s ease;
  overflow: hidden;
}
.image-opera {
  &-header {
    border-top: 1px dashed #d0d0d0;
    margin-top: 24px;
    padding-left: 34px;
    padding-right: 26px;
    height: 57px;
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }
    &-icon {
      margin-left: auto;
    }
  }
  &-pu {
    display: flex;
    align-items: center;
    padding-left: 38px;
    padding-right: 26px;
    &-list {
      margin-right: 26px;
      text-align: center;
      img {
        width: 47px;
        height: 47px;
      }
      p {
        color: #777777;
        font-size: 12px;
        margin-top: 8px;
      }
    }
  }
  &-align {
    display: flex;
    padding-left: 34px;
    padding-right: 26px;
    &-list {
      &-top {
        color: #bababa;
        font-size: 12px;
      }
      &-bottom {
        display: flex;
        margin-top: 6px;
        &-list{
          width: 28px;
          height: 28px;
          margin-right: 6px;
          &.zuoduiqi{
            width: 28px;
            height: 28px;
            background: url('../../../../assets/images/custom/zuoduiqi.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../../assets/images/sds/zuoduiqi.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.cuizhijuzhong{
            width: 28px;
            height: 28px;
            background: url('../../../../assets/images/custom/cuizhijuzhong.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../../assets/images/sds/cuizhijuzhong.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.youduiqi{
            width: 28px;
            height: 28px;
            background: url('../../../../assets/images/custom/youduiqi.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../../assets/images/sds/youduiqi.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.dingduiqi{
            width: 28px;
            height: 28px;
            background: url('../../../../assets/images/custom/dingduiqi.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../../assets/images/sds/dingduiqi.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.shuipingjuzhong{
            width: 28px;
            height: 28px;
            background: url('../../../../assets/images/custom/shuipingjuzhong.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../../assets/images/sds/shuipingjuzhong.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.diduiqi{
            width: 28px;
            height: 28px;
            background: url('../../../../assets/images/custom/diduiqi.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../../assets/images/sds/diduiqi.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.shuipingfanzhuan{
            width: 28px;
            height: 28px;
            background: url('../../../../assets/images/custom/shuipingfanzhuan.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../../assets/images/sds/shuipingfanzhuan.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.cuizhifanzhuan{
            width: 28px;
            height: 28px;
            background: url('../../../../assets/images/custom/cuizhifanzhuan.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../../assets/images/sds/cuizhifanzhuan.png') no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        img {
          width: 28px;
          height: 28px;
          margin-right: 4px;
          cursor: pointer;
        }
      }
    }
  }
  &-scale {
    display: flex;
    padding-left: 34px;
    padding-right: 26px;
    margin-top: 18px;
    .bgInput:hover{
      border: 2px solid #333;
    }
    .bgInpuActived{
      border: 2px solid #333;
    }
    &-list {
      &-top {
        color: #bababa;
        font-size: 12px;
      }
      &-box {
        width: 121px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid #BABABA;
        display: flex;
        align-items: center;
        margin-top: 8px;
        padding-left: 6px;
        box-sizing: border-box;
        overflow: hidden;
        &:hover{
          border: 2px solid #444;
        }
        .colorpick{
          width: 24px;
          height: 24px;
          position: absolute;
          opacity: 0;
        }
        &-touming{
          width: 24px;
          height: 24px;
        }
        &-img {
          width: 24px;
          height: 24px;
        }
        input {
          outline: none;
          margin-left: 5px;
          text-align: center;
          width: 40px;
        }
        &-color {
          width: 24px;
          height: 24px;
          background: #333333;
          border-radius: 4px;
        }
        &-bs {
          margin-left: auto;
          cursor: ew-resize;
          img {
            padding-right: 6px;
          }
        }
      }
    }
  }
}
.image-opera {
  &-pu {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding-left: 29px;
    padding-right: 26px;
    .oplist{
      width: 47px;
      margin-right: 26px;
      text-align: center;
      margin-left: 5px;
      p{
        margin: 0;
        margin-top: 8px;
        color: #777777;
      }
    }
    &-list {
      margin-right: 26px;
      text-align: center;
      width: 47px;
      height: 47px;
      &.zhengchang{
        width: 47px;
        height: 47px;
        background: url('../../../../assets/images/sds/zhengchang.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../../../../assets/images/sds/zhengchang2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      &.zhengchang2{
        width: 47px;
        height: 47px;
        background: url('../../../../assets/images/sds/zhengchang2.png') no-repeat;
        background-size: 100% 100%;
      }
      &.jichu{
        width: 47px;
        height: 47px;
        background: url('../../../../assets/images/sds/jichu.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../../../../assets/images/sds/jichu2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      &.jichu2{
        width: 47px;
        height: 47px;
        background: url('../../../../assets/images/sds/jichu2.png') no-repeat;
        background-size: 100% 100%;
      }
      &.hengjiangjiaocuo{
        width: 47px;
        height: 47px;
        background: url('../../../../assets/images/sds/hengjiangjiaocuo.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../../../../assets/images/sds/hengjiangjiaocuo2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      &.hengxiangjiaocuo{
        width: 47px;
        height: 47px;
        background: url('../../../../assets/images/sds/hengxiangjiaocuo.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../../../../assets/images/sds/hengxiangjiaocuo2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      img {
        width: 47px;
        height: 47px;
      }
      p {
        color: #777777;
        font-size: 12px;
        margin-top: 8px;
      }
    }
  }
}
</style>
