<template>
  <div class="sku">
    <div class="sku-main">
      <!-- 选择刀版 -->
      <!-- <div>
        <div class="sku-titles">选择刀版</div>
        <div class="sku-con">
          <div v-for="(item,index) in knifeSetInfoList" :key="index+item.name">
            <div class="sku-list" :class="{'actived': item.checked,'disabled':onDisabled(item)}" @click="changeSku(index,item)">{{ item.name }}</div>
          </div>
        </div>
      </div> -->
      <!-- 选择sku -->
      <div v-for="(item,index) in skuMaps2" :key="index+item.name">
        <div class="sku-titles">{{ item.name }}</div>
        <div v-if="item.type == 1" class="sku-con">
          <div v-for="(item2,index2) in item.items" :key="index2+item2.name">
            <div class="sku-list" :class="{'actived': item2.checked,'disabled':onDisabled(item2)}" @click="changeSku(index,index2,item2)">{{ item2.name }}</div>
          </div>
        </div>
        <div v-if="item.type == 2" class="sku-con">
          <div v-for="(item2,index2) in item.items" :key="index2+item2.name">
            <div class="sku-color-li" :class="{'actived': item2.checked,'disabled':onDisabled(item2)}" @click="changeSku(index,index2,item2)">
              <span class="sku-color" :class="getJson(item2) == '#ffffff' || getJson(item2) == '#FFFFFF' ? 'border-white' : 'border-other'" :style="{'background-color': getJson(item2)}" />
            </div>
          </div>
        </div>
        <div v-if="item.type == 3" class="sku-con">
          <div v-for="(item2,index2) in item.items" :key="index2+item2.name">
            <div class="sku-image" :class="{'actived': item2.checked,'disabled':onDisabled(item2)}" @click="changeSku(index,index2,item2)">
              <img :src="setParse(item2.option)">
              <span>{{ item2.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    skuMaps: {
      type: Array,
      default: () => []
    },
    skuInfos: {
      type: Array,
      default: () => []
    },
    firstSkuList: {
      type: Array,
      default: () => []
    },
    skuIds: {
      type: Array,
      default: () => []
    },
    knifeSetInfoList2: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      knifeSetInfoList: [],
      checkedSkuList: [],
      skuMaps2: {},
      skuInfos2: {},
      sku: {}
    }
  },
  watch: {
    knifeSetInfoList2(newValue, oldValue) {
      this.knifeSetInfoList = newValue
    },
    firstSkuList(newValue, oldValue) {
      this.checkedSkuList = newValue
    }
  },
  created() {
    if (this.firstSkuList) {
      this.checkedSkuList = this.firstSkuList
    }
    if (this.skuIds.length > 0) {
      this.skuMaps2 = this.skuMaps
      this.skuMaps2.forEach((skuItem, skuIndex) => {
        skuItem.items.forEach((item, index) => {
          item.ids.forEach((idsItem, idsIndex) => {
            if (this.skuIds.includes(idsItem)) {
              item.checked = true
            }
          })
        })
      })
    } else {
      if (this.skuMaps && this.checkedSkuList) {
        this.skuMaps2 = this.skuMaps
        this.setDefault()
      }
    }
    if (this.knifeSetInfoList2) {
      console.log('this.knifeSetInfoList2....', this.knifeSetInfoList2)
      this.knifeSetInfoList = this.knifeSetInfoList2
    }
  },
  methods: {
    getJson(val) {
      const json = JSON.parse(val.option)
      return json.color
    },
    setDefault() {
      const checkedSkuList = this.checkedSkuList
      // const firstSkuList = [11868, 11869, 11870, 11871]
      this.skuMaps2.forEach((skuItem, skuIndex) => {
        skuItem.items.forEach((item, index) => {
          item.ids.forEach((idsItem, idsIndex) => {
            if (checkedSkuList.includes(idsItem)) {
              item.checked = true
            }
          })
        })
      })
      this.$emit('setSkuIds', this.checkedSkuList)
    },
    setParse(obj) {
      if (typeof obj === 'string') {
        const option = JSON.parse(obj)
        return option.fileUrl
      }
    },
    changeSku(index, index2, item) {
      debugger
      if (this.onDisabled(item)) {
        return
      }
      this.skuMaps2[index].items[index2].checked = !item.checked
      this.$forceUpdate()
      this.getIds()
    },
    onDisabled(item) {
      let count = 0
      this.skuMaps2.forEach(item => {
        item.items.forEach(items => {
          if (items.checked) {
            count += 1
          }
        })
      })
      console.log('this.skumaps2....', this.skuMaps2)
      console.log('count....', count)
      if (count == 0) {
        return false
      } else {
        console.log('onDisabled....', item)
        let num = 0
        item.ids.forEach((idsItem, idsIndex) => {
          if (this.checkedSkuList.includes(idsItem)) {
            num = num + 1
          }
        })
        if (num === 0) {
          return true
        } else {
          return false
        }
      }
    },
    getIds() {
      const arr = []
      this.skuMaps2.forEach((item, index) => {
        item.items.forEach((item2, index2) => {
          if (item2.checked) {
            arr.push(...item2.ids)
          }
        })
      })
      // if(arr.length === 0){

      // }
      console.log('getIds...', arr)
      this.checkedSkuList = arr
      var count = {} // 创建一个对象来统计元素出现的次数
      // 遍历数组
      for (var i = 0; i < arr.length; i++) {
        var element = arr[i]
        // 如果元素已经在count对象中，则将其计数加1；否则，将其计数初始化为1
        if (count[element]) {
          count[element]++
        } else {
          count[element] = 1
        }
      }
      var result = []
      // 筛选出出现次数大于等于3的元素
      for (var key in count) {
        if (count.hasOwnProperty(key) && count[key] >= this.skuMaps2.length) {
          result.push(parseInt(key)) // 将元素转换为数字类型，并添加到结果数组中
        }
      }
      console.log(result)
      this.$emit('setSkuIds', result)
      return result
    }
  }
}
</script>
<style scoped lang="scss">
.sku-list {
  display: inline-block;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 10px;
  margin-top: 12px;
  cursor: pointer;
  border: 1px solid #ACACAC;
  color: #454545;
  img{
    width: 20px;
    height: 20px;
  }
  &.actived {
    border: 2px solid #333;
    color: #333;
  }
}
.sku-color-li{
  width: 28px;
  height: 28px;
  border: 2px solid #fff;
  border-radius: 28px;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  &.actived{
    border: 2px solid #333;
  }
  .border-white{
    border: 2px solid #C5C5C5;
  }
}
.sku-color {
  width: 20px;
  height: 20px;
  // background: #777777;
  border-radius: 20px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  top: 3px;
  left: 3px;
}
.sku-image {
  padding: 4px 8px;
  border-radius:4px;
  margin-right: 10px;
  margin-top: 12px;
  display: inline-block;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #777;
  img{
    width: 24px;
    height: 24px;
  }
  span{
    margin-left: 8px
  }
  &.actived {
    border: 2px solid #2D2D2D;;
    color: #2D2D2D;
  }
}

.sku {
  .sku-main {
    width: 353px;
    padding: 0 20px;
  }
  &-titles {
    font-size: 16px;
    font-weight: Medium;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  .disabled{
    cursor: no-drop !important;
    border: 1px solid #EFEFEF;
    color: #C0C0C0;
  }
  .sku-con {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
