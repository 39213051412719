<template>
  <div>
    <div class="image-opera">
      <div class="image-opera-header">
        <span>Background Color</span>
        <a-icon type="down" class="image-opera-header-icon" @click="toggleHeight" />
      </div>
      <div :style="{ height: getHeight }" class="animate-height">
        <div class="bg-con">
          <div v-for="(item,index) in bgList" :key="index" class="bg-span " :class="actived == index ? 'actived' : ''">
            <span :style="{'background':item}" @click="handleBackGround(item,index)" />
          </div>
        </div>
        <div class="color-con">
          <div class="xiguan">
            <img src="@/assets/images/custom/xiguan.png" alt="">
            <input v-model="BgColor" type="color" class="color-picker">
          </div>
          <div class="color-input">
            <span>#</span>
            <input v-model="color" type="text" maxlength="6"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    canvas2: {
      type: Object,
      default: () => ({})
    },
    backGroundColor: String
  },
  data() {
    return {
      actived: 0,
      bgList: ['#ffffff', '#B6B5B5', '#98989A', '#646A6A', '#000000', '#EB1D26', '#CA1028', '#643B13', '#BE8042', '#F88033', '#DF5D21', '#F4BC17', '#6EA748',
        '#2BA5C7', '#73AEE3', '#0C3F8B', '#8E69B0', '#502781', '#D173A7', '#C42A86'],
      color: 'ffffff',
      BgColor: '',
      isExpanded: true
    }
  },
  computed: {
    getHeight() {
      return this.isExpanded ? 'auto' : '0'
    }
  },
  watch: {
    color(newValue, oldValue) {
      this.canvas2.setBackgroundColor('#' + newValue, this.canvas2.renderAll.bind(this.canvas2))
    },
    BgColor(newValue, oldValue) {
      this.canvas2.setBackgroundColor(newValue, this.canvas2.renderAll.bind(this.canvas2))
    },
    backGroundColor(newValue, oldValue) {
      debugger
      this.canvas2.setBackgroundColor(newValue, this.canvas2.renderAll.bind(this.canvas2))
    }
  },
  created() {
    if (this.backGroundColor) {
      console.log('哈哈哈哈哈哈哈.....', this.backGroundColor)
      this.color = this.backGroundColor.split('#')[1]
    }
    // if (this.backGroundColor && this.backgroundColor != undefined) {
    //   debugger
    //   this.color = this.backgroundColor.split('#')[1]
    //   this.canvas2.setBackgroundColor(this.backGroundColor, this.canvas2.renderAll.bind(this.canvas2))
    // }
    // this.$bus.on('showBackgroundSet', (res) => {
    //   console.log('showBackgroundSet.....,', res.backgroundColor)
    //   this.BgColor = res.backgroundColor
    //   this.color = res.backgroundColor.split('#')[1]
    // })
  },
  methods: {
    handleBackGround(value, index) {
      this.actived = index
      this.canvas2.setBackgroundColor(value, this.canvas2.renderAll.bind(this.canvas2))
    },
    toggleHeight() {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>
<style scoped lang="scss">
.animate-height {
  transition: height 0.5s ease;
  overflow: hidden;
}
.image-opera {
  &-header {
    border-top: 1px dashed #d0d0d0;
    margin-top: 24px;
    padding-left: 34px;
    padding-right: 26px;
    height: 57px;
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: bold;
    }
    &-icon {
      margin-left: auto;
    }
  }
  .bg-con{
    margin: 0 30px;
    display: flex;
    flex-wrap: wrap;
    .bg-span{
      width: 24px;
      height: 24px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 3px 3px;
      &.actived{
        border: 1px solid #B3B3B3;
      }
      span{
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        border: 1px solid #B3B3B3;
        position: relative;
      }
    }
  }
  .color-con{
    margin: 0 30px;
    display: flex;
    margin-top: 15px;
    .xiguan{
      width: 36px;
      height: 36px;
      border: 1px solid #D9D9D9;
      border-radius: 4px 4px 4px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:hover{
        border: 2px solid #333;
      }
      .color-picker {
        position: absolute;
        top: 0;
        left: 0;
        width: 36px;
        height: 36px;
        z-index: 1;
        opacity: 0;
        &:focus {
          outline: none; /* 隐藏获得焦点时的边框 */
        }
      }
      img{
        width: 26px;
        height: 26px;
      }
    }
    .color-input{
      width: 219px;
      height: 36px;
      border: 1px solid #D9D9D9;
      margin-left: 10px;
      font-size: 16px;
      overflow: hidden;
      border-radius: 4px 4px 4px 4px;
      &:hover{
        border: 2px solid #333;
      }
      span{
        display: inline-block;
        width: 36px;
        height: 36px;
        text-align: center;
      }
      input{
        width: 150px;
        height: 34px;
        line-height: 36px;
        &:focus {
          outline: none; /* 隐藏获得焦点时的边框 */
        }
      }
    }
  }
}
</style>
