<template>
  <div>
    <!-- <a-spin v-show="form.loading && !form.error"></a-spin> -->
    <img
      v-show="form.error"
      src="../assets/img/error.png"
      :height="height"
      :width="width"
    >
    <img
      v-show="!form.loading && !form.error"
      :src="form.finImageUrl"
      :height="height"
      :width="width"
      @error="loadFail"
      @load="loadSuccess"
    >
  </div>
</template>
<script>
export default {
  props: {
    // 图片链接
    url: {
      type: String,
      default: ''
    },
    // 宽度
    width: {
      type: [Number, String],
      default: 200
    },
    // 高度
    height: {
      type: [Number, String],
      default: 200
    },
    // 图片质量
    quality: {
      type: Number,
      default: 80
    },
    id: {
      type: Number
    }
  },
  data() {
    return {
      form: {
        loading: true,
        finImageUrl: '',
        failCount: 0,
        dialogVisible: false,
        error: false
      }
    }
  },
  watch: {
    url() {
      this.urlChange()
    }
  },
  async mounted() {
    await this.loadFail()
  },
  methods: {
    // url变换,重新加载图片
    urlChange() {
      this.form.failCount = 0
      this.form.loading = true
      // 是图片为空，进入loadFail
      this.form.finImageUrl = ''
    },
    showPreview() {
      if (this.preview) {
        this.form.dialogVisible = true
      }
    },
    loadSuccess() {
      this.form.loading = false
      if (this.$parent.imgLodingFinsh) {
        this.$parent.imgLodingFinsh.push(this.id)
      }
    },
    loadFail() {
      if (!this.url) {
        return
      }
      if (this.url.indexOf('?') >= 0) {
        return
      }
      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = this.url
      image.onload = async() => {
        // console.log('image.width...', image.width)
        // console.log('image.height...', image.height)
        const scalWidth = Math.floor(image.width / 194)
        const scalHeight = Math.floor(image.height / 225)
        const w = Math.floor(image.width / scalWidth)
        const h = Math.floor(image.height / scalHeight)
        this.form.failCount = this.form.failCount + 1
        if (this.form.failCount === 1) {
          if (
            this.getImgformat(this.url, '.jpg') ||
          this.getImgformat(this.url, 'jpeg') ||
          this.getImgformat(this.url, '.png')
          ) {
            this.form.finImageUrl =
            this.url +
            `?x-oss-process=image/resize,w_${w},h_${h}/format,png/quality,q_${this.quality}`
          } else {
            this.form.finImageUrl =
            this.url +
            `?x-oss-process=image/format,png/quality,q_${this.quality}&t=1`
          }
          return
        }
        if (this.form.failCount === 2) {
          this.form.finImageUrl =
          this.url +
          `?x-oss-process=image/format,png/quality,q_${this.quality}`
          return
        }
        if (this.form.failCount === 3) {
          this.form.finImageUrl = this.url
          return
        }
        if (this.form.failCount === 4) {
          this.form.loading = false
          this.form.error = true
          return
        }
      }
    },
    // http://wwycda.oss-accelerate.aliyuncs.com/merchantUpload/8/124x124_202008112032.png

    getImgformat(path, type) {
      var fileType = path.substring(path.length - 4, path.length)
      if (fileType === type) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style scoped>
img {
  width: 194px;
  height: 225px;
  object-fit: contain;
}
img {
  transform: translate(0%, 0%) scale(1.2);
}
</style>
