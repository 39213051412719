<template>
  <mask-dialog :show="addTextDialog">
    <div class="addtext">
      <div class="title">Text</div>
      <div class="text">
        <textarea v-model="text" />
      </div>
      <div class="btns mt20 dis-flex">
        <div class="cancel" @click="addTextDialog = false">Cancel</div>
        <div class="done " :class="isDisabled ? 'disabled' : ''" @click="handleText(text)">Done</div>
      </div>
    </div>
  </mask-dialog>
</template>
<script>
import maskDialog from '../../components/mask.vue'
import BaseView from '@/components/base/BaseView'
export default {
  components: {
    maskDialog
  },
  extends: BaseView,
  props: {
    saveCanvasState: {
      type: Function,
      default: () => {}
    },
    canvas2: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // 弹窗
      addTextDialog: false,
      text: '',
      isDisabled: true,
      canvas: {},
      id: ''
    }
  },
  watch: {
    canvas2(newValue, oldValue) {
      this.canvas = newValue
    },
    text(newValue, oldValue) {
      if (newValue.length === 0) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    }
  },
  created() {
    if (this.canvas2) {
      this.canvas = this.canvas2
    }
  },
  methods: {
    handleText() {
      const canvas = this.canvas
      const objects = canvas.getObjects()
      if (this.id) {
        objects.forEach(item => {
          if (item.id === this.id) {
            item.set('text', this.text)
            canvas.renderAll()
            this.saveCanvasState()
            this.addTextDialog = false
          }
        })
      } else {
        this.addText(this.text).then(() => {
          console.log('文字添加了...')
          this.text = ''
          this.saveCanvasState()
          this.addTextDialog = false
        })
      }
    },
    open(data) {
      this.text = data.text
      this.id = data.id
      this.addTextDialog = true
    }
  }
}
</script>
<style scoped lang="scss">
.addtext {
  width: 464px;
  padding-bottom: 15px;
  background: #ffffff;
  border-radius: 4px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -102px;
  margin-left: -232px;
  .title {
    font-size: 16px;
    font-weight: bold;
    padding-top: 14px;
    text-align: center;
  }
  .text {
    text-align: center;
    textarea {
      width: 420px;
      height: 64px;
      border: 1px solid #979797;
      margin-top: 23px;
    }
  }
  .btns {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    .cancel {
      width: 93px;
      height: 38px;
      border-radius: 4px 4px 4px 4px;
      color: #555555;
      line-height: 38px;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
      &:hover{
        color: #8D8D8D;
        background: #F5F5F5;
      }
    }
    .done {
      width: 93px;
      height: 38px;
      border-radius: 4px 4px 4px 4px;
      color: #fff;
      background-color: #FF5347;
      line-height: 38px;
      font-size: 18px;
      text-align: center;
      margin-left: 20px;
      cursor: pointer;
      &.disabled{
        background-color: #FFCAC6;
      }
    }
  }
}
</style>

