<!-- eslint-disable vue/valid-template-root -->
<template />

<script>
import { fabric } from 'fabric'
import uuid from 'licia/uuid'
import { mapGetters } from 'vuex'
export default {
  name: 'BaseView',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('Canvas', {
      canvasSize: 'getCanvasSize',
      zoom: 'getZoom'
    })
  },
  created() {
  },
  mounted() {
  },
  methods: {
    // 对齐方式
    textAligns(val) {
      const { canvas } = this
      return new Promise((resolve) => {
        const actived = canvas.getActiveObject()
        actived.setCoords()
        // 左对齐
        // actived.setOptions('textAlign', val)
        actived.textAlign = val
        canvas.renderAll()
        resolve()
      })
    },
    align(val) {
      const { canvas } = this
      return new Promise((resolve) => {
        console.log('画布宽度....', canvas.width)
        console.log('hhhhhh2....', canvas)
        // image.width * (canvas.width / image.width)
        const w = canvas.width * (this.zoom / 100)

        console.log('缩放后的宽度....', w)
        console.log('hhhhhh4....', (canvas.width - w) / 2)
        const actived = canvas.getActiveObject()
        // 左对齐
        if (val === 'left') {
          actived.set('left', actived.width * actived.scaleX / 2)
        }
        if (val === 'center') {
          // 获取文本的宽度和高度
          const textWidth = actived.width

          // 获取画布的宽度和高度
          const canvasWidth = canvas.width

          // 将文本放置在画布中央
          actived.set({
            left: canvasWidth / 2 - textWidth / 2
          })
        }
        // 垂直居中
        if (val === 'verticalCenter') {
          canvas.viewportCenterObjectV(actived)
        }
        if (val === 'top') {
          actived.set('top', actived.height * actived.scaleY / 2)
        }
        if (val === 'right') {
          const rightAlignedPosition = ((actived.width * actived.scaleX / 2) + canvas.width) - actived.width * actived.scaleX
          actived.set({
            left: rightAlignedPosition
          })
        }
        if (val === 'bottom') {
          var activeObject = canvas.getActiveObject()

          if (activeObject) {
            const bottomAlignedPosition = ((actived.height * actived.scaleY / 2) + canvas.height) - actived.height * actived.scaleY

            // 移动活动对象
            activeObject.set('top', bottomAlignedPosition)

            // 更新画布
            canvas.renderAll()
          }
        }
        // 水平居中
        if (val === 'horizontally') {
          canvas.viewportCenterObjectH(actived)
        }
        canvas.renderAll()
      })
    },
    copyFlip(val) {
      return new Promise((resolve) => {
        const canvas = this.canvas
        var objectToFlip = canvas.getActiveObject()
        if (objectToFlip) {
          var clonedObject = fabric.util.object.clone(objectToFlip) // 复制对象
          if (val === 'horizontal') {
            clonedObject.left = (clonedObject.left + (clonedObject.width * clonedObject.scaleX)) - 1
            clonedObject.flipX = !clonedObject.flipX // 切换对象的 flipX 属性
          }
          if (val === 'vertical') {
            clonedObject.top = (clonedObject.top + (clonedObject.height * clonedObject.scaleY)) - 1
            clonedObject.flipY = !clonedObject.flipY // 切换对象的 flipX 属性
          }
          clonedObject.set({
            id: uuid()
          })
          canvas.add(clonedObject)
          canvas.renderAll() // 渲染画布
        }
        resolve()
      })
    },
    flip(val) {
      return new Promise((resolve) => {
        const canvas = this.canvas
        var objectToFlip = canvas.getActiveObject()
        if (objectToFlip) {
          // var clonedObject = fabric.util.object.clone(objectToFlip) // 复制对象
          if (val === 'horizontal') {
            objectToFlip.flipX = !objectToFlip.flipX // 切换对象的 flipX 属性
          }
          if (val === 'vertical') {
            objectToFlip.flipY = !objectToFlip.flipY // 切换对象的 flipX 属性
          }
          // canvas.add(clonedObject) // 将副本添加到画布中
          canvas.renderAll() // 渲染画布
        }
        resolve()
      })
    },
    // 复制对象
    copyObject(val) {
      const canvas = this.canvas
      var objectToCopy = canvas.getActiveObject() // 获取要复制的对象

      if (objectToCopy) {
        var clonedObject = fabric.util.object.clone(objectToCopy) // 复制对象
        var offsetX = 10 // 水平偏移量
        var offsetY = 10 // 垂直偏移量
        if (val === 'copy') {
          offsetX = 10 // 水平偏移量
          offsetY = 10 // 垂直偏移量
        }
        if (val === 'vertical') {
          offsetX = 0 // 水平偏移量，保持在相同的水平位置上
          offsetY = (objectToCopy.height * clonedObject.scaleY) - 1 // 垂直偏移量，将副本移到原始对象的下方
        }
        if (val === 'horizontal') {
          console.log('objectToCopy.width....', objectToCopy.width)
          console.log('objectToCopy.scaleX....', objectToCopy.scaleX)
          console.log('objectToCopy.width * clonedObject.scaleX....', objectToCopy.width * clonedObject.scaleX)
          offsetX = (objectToCopy.width * clonedObject.scaleX) - 1// 水平偏移量，将副本移到原始对象的右边
          offsetY = 0 // 垂直偏移量，保持在相同的高度上
        }
        clonedObject.set({
          left: clonedObject.left + offsetX,
          top: clonedObject.top + offsetY,
          scaleX: clonedObject.scaleX,
          scaleY: clonedObject.scaleY,
          id: uuid()
        })

        canvas.add(clonedObject) // 将副本添加到画布中
        canvas.setActiveObject(clonedObject) // 选中副本
        canvas.renderAll() // 渲染画布
      }
    },
    // 清空画布
    clearCanvas() {
      const canvas = this.canvas
      var prevOverlayImage = canvas.overlayImage
      canvas.clear()
      canvas.setOverlayImage(prevOverlayImage)
      canvas.renderAll()
    },
    // 删除活动对象
    removeObject() {
      const canvas = this.canvas
      const actived = canvas.getActiveObject()
      if (actived.type === 'backgroundColor') {
        canvas.setBackgroundColor('', canvas.renderAll.bind(canvas))
        canvas.remove(actived)
        this.saveCanvasState()
        return
      }
      // 删除拿去平铺的图片
      if (actived.isbgImage) {
        const arr = canvas.getObjects()
        arr.forEach((item, index) => {
          if (item.type === 'repeat') {
            canvas.remove(arr[index])
          }
        })
        canvas.remove(actived)
        canvas.renderAll() // 重新渲染 canvas
        this.saveCanvasState()
        return
      }
      const arr = canvas.getObjects()
      arr.forEach((item, index) => {
        if (item.id === actived.id) {
          canvas.remove(arr[index])
        }
      })
      canvas.renderAll() // 重新渲染 canvas
      this.saveCanvasState()
    },
    // addText() {

    // },
    // 添加文字
    addText(txt) {
      const _this = this
      return new Promise((resolve) => {
      // 创建 canvas 对象
        var canvas = this.canvas
        // 创建文本对象
        var text = new fabric.Text(txt, {
          left: 50,
          top: 50,
          fill: '#333333',
          fontFamily: 'Roboto',
          fontSize: 30,
          transparentCorners: true, // 选中时，角是被填充了。true 空心；false 实心
          hasBorders: true, // 选中时，是否显示边，默认true（显示）
          borderColor: 'red', // 选中时，边为红色
          borderScaleFactor: 2, // 选中时，边的粗细
          cornerColor: '#ff0000', // 选中时，角的颜色是 红色
          cornerStrokeColor: '#ff0000', // 选中时，角的边框的颜色是 粉色
          cornerStyle: 'rect', // 选中时，叫的属性。默认rect 矩形；circle 圆形
          cornerSize: 24, // 选中时，角的大小为24
          padding: 0, // 选中时，选框离图形的距离
          borderOpacityWhenMoving: 0.6, // 当对象活动和移动时，对象控制边界的不透明度
          id: uuid(),
          knifeId: canvas.knifeId,
          type: 'text',
          text: txt,
          dpi: 'High resolution',
          dpiClass: 'Good'
        })
        // 将文本对象添加到画布
        canvas.add(text)
        canvas.setActiveObject(text) // 选中副本
        // 获取当前选中的对象
        const activeObject = canvas.getActiveObject()
        // 获取文本的宽度和高度
        const textWidth = activeObject.width
        const textHeight = activeObject.height

        // 获取画布的宽度和高度
        const canvasWidth = canvas.width
        const canvasHeight = canvas.height

        // 将文本放置在画布中央
        activeObject.set({
          left: canvasWidth / 2 - textWidth / 2,
          top: canvasHeight / 2 - textHeight / 2,
          textAlign: 'center',
          textBaseline: 'middle'
        })

        canvas.renderAll()
        // this.addTextItem(text, txt)
        const param = {
          isShow: false,
          backgroundColor: ''
        }
        _this.$bus.emit('showBackgroundSet', param)
        resolve()
      })
    },
    // 添加文字
    // addTextItem(param, txt) {
    //   var canvas = this.canvas
    // },
    // 添加图片
    addImage(item) {
      const _this = this
      const canvas = this.canvas
      // 添加图片并居中显示在可编辑区域内
      var imageUrl = new Image()
      imageUrl.setAttribute('crossOrigin', 'anonymous')
      imageUrl.src = item.fileUrl
      let imgWidth = 0
      let imgHeight = 0
      let scal = 1
      imageUrl.onload = function() {
        var image = new fabric.Image(imageUrl)
        const imageMin = Math.min(image.width, image.height)
        const knifeSizeMin = Math.min(canvas.originalImageWidth, canvas.originalImageHeight)
        let canvasScale = 1
        // this.canvasSize.width / image.width

        if (canvas.originalImageWidth > canvas.originalImageHeight) {
          canvasScale = canvas.width / canvas.originalImageWidth
        } else {
          canvasScale = canvas.height / canvas.originalImageHeight
        }
        // 刀版的30%
        const knifewidthMin = canvas.originalImageWidth * 0.3
        const knifeHeightMin = canvas.originalImageHeight * 0.3
        // 四边都相等
        if (canvas.originalImageWidth === image.width / 2 && canvas.originalImageHeight === image.height / 2) {
          imgWidth = canvas.originalImageWidth * canvasScale
          imgHeight = canvas.originalImageHeight * canvasScale
        } else if (image.width > canvas.originalImageWidth && image.height > canvas.originalImageHeight) {
          scal = imageMin / knifeSizeMin
          imgWidth = image.width / scal * canvasScale
          imgHeight = image.height / scal * canvasScale
        } else if (imageMin > knifeSizeMin) {
          scal = imageMin / knifeSizeMin
          imgWidth = image.width / scal * canvasScale
          imgHeight = image.height / scal * canvasScale
        } else if (image.width < knifewidthMin) { // 4000  200   1200
          imgWidth = knifewidthMin * canvasScale
          scal = knifewidthMin / image.width
          imgHeight = image.height * scal * canvasScale
        } else if (image.height < knifeHeightMin) { // 4000  200   1200
          imgHeight = knifeHeightMin * canvasScale
          scal = knifeHeightMin / image.height
          imgWidth = image.width * scal * canvasScale
        } else {
          imgWidth = image.width * canvasScale
          imgHeight = image.height * canvasScale
        }
        _this.addImageItem(imgWidth, imgHeight, item, image, scal)
        const param = {
          isShow: false,
          backgroundColor: ''
        }
        _this.$bus.emit('showBackgroundSet', param)
      }
    },
    addImageItem(imgWidth, imgHeight, item, image, scal) {
      const _this = this
      const canvas = this.canvas
      image.set({
        centeredScaling: true,
        transformOrigin: true,
        originX: 'center',
        originY: 'center',
        // centeredScaling: true, // 设置缩放位置为中心点
        left: canvas.width / 2,
        top: canvas.height / 2,
        transparentCorners: true, // 选中时，角是被填充了。true 空心；false 实心
        hasBorders: true, // 选中时，是否显示边，默认true（显示）
        borderColor: 'red', // 选中时，边为红色
        borderScaleFactor: 2, // 选中时，边的粗细
        cornerColor: '#ff0000', // 选中时，角的颜色是 红色
        cornerStrokeColor: '#ff0000', // 选中时，角的边框的颜色是 粉色
        cornerStyle: 'rect', // 选中时，叫的属性。默认rect 矩形；circle 圆形
        cornerSize: 24, // 选中时，角的大小为20
        padding: 0, // 选中时，选框离图形的距离
        borderOpacityWhenMoving: 1, // 当对象活动和移动时，对象控制边界的不透明度,
        knifeId: canvas.knifeId,
        id: uuid(),
        fileUrl: item.fileUrl,
        smallImageUrl: item.smallImageUrl,
        type: 'image',
        scaleWidth: imgWidth,
        scaleHeight: imgHeight,
        // width: imgWidth,
        // height: imgHeight,
        fileName: item.fileName,
        // scaleX: canvas.width / imgWidth / scal,
        // scaleY: canvas.width / imgWidth / scal,
        dpi: '',
        dpiClass: ''
      })
      if (imgWidth > imgHeight) {
        image.scale(imgWidth / image.width)
      } else {
        image.scale(imgHeight / image.height)
      }
      canvas.add(image)
      canvas.renderAll()
      canvas.setActiveObject(image) // 选中副本
      setTimeout(() => {
        _this.countDPI(1)
      }, 100)
    },
    // 计算dpi(传的是当前图的大小) // type 1还没经过变化 2经过变化
    countDPI(type) {
      const canvas = this.canvas
      const { knifePlateImageDpiGoodMax, knifePlateImageDpiGoodMin, knifePlateImageDpiNormalMax, knifePlateImageDpiNormalMin, knifePlateImageDpiBadMin, knifePlateImageDpiBadMax, knifePlateImageTransverseInch } = this.canvas
      const actived = canvas.getActiveObject()
      if (actived.type === 'image') {
        const objectWH = this.countWidthHeight(actived)
        const param = {}
        let dpi = 0
        dpi = actived.width / ((actived.width * actived.scaleX) / actived.width) / knifePlateImageTransverseInch
        // ((图片原始的宽度 * (刀版宽度 / 画布宽度)) * (图片原始的宽度 * (刀版宽度 / 画布宽度))) / 图片变形后的宽度 / (英寸 * 图片缩放比例
        param.dpiValue = dpi
        if (dpi >= knifePlateImageDpiGoodMin && dpi <= knifePlateImageDpiGoodMax) {
          actived.dpi = 'High resolution'
          actived.dpiClass = 'Good'
        } else if (dpi >= knifePlateImageDpiNormalMin && dpi <= knifePlateImageDpiNormalMax) {
          actived.dpi = 'Medium resolution'
          actived.dpiClass = 'Normal'
        } else if (dpi >= knifePlateImageDpiBadMin && dpi <= knifePlateImageDpiBadMax) {
          actived.dpi = 'Low resolution'
          actived.dpiClass = 'Bad'
        } else {
          actived.dpi = 'High resolution'
          actived.dpiClass = 'Good'
        }
      }
    },
    // 设置蒙版
    async setOverlayImage(canvas, url) {
      return new Promise((resolve) => {
        const image = new Image()
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url

        image.onload = async() => {
          console.log('设置蒙版了...')
          let knifeScaling = 0
          if (image.width > image.height) {
            knifeScaling = image.width / this.canvasSize.width
          } else {
            knifeScaling = image.height / this.canvasSize.height
          }
          canvas.setWidth(image.width / knifeScaling)
          canvas.setHeight(image.height / knifeScaling)
          const scaling = canvas.width / image.width

          await canvas.setOverlayImage(url, canvas.renderAll.bind(canvas), {
            crossOrigin: 'Anonymous',
            angle: 0, // 图片旋转角度
            scaleX: canvas.width / image.width,
            scaleY: canvas.height / image.height,
            opacity: 0.97
          })
          const knifeToCanvasWidth = image.width * (canvas.width / image.width)
          const knifeToCanvasHeight = image.height * (canvas.height / image.height)
          var left = (canvas.width - (knifeToCanvasWidth)) / 2
          var top = (canvas.height - (knifeToCanvasHeight)) / 2
          const param = {
            originalImageWidth: image.width,
            originalImageHeight: image.height,
            knifeToCanvasWidth: knifeToCanvasWidth,
            knifeToCanvasHeight: knifeToCanvasHeight,
            scaleX: canvas.width / image.width,
            scaleY: canvas.height / image.height,
            scaling
          }
          // console.log('画布的宽度', image.width * (canvas.width / image.width))
          // console.log('画布的高度', image.height * (canvas.height / image.height))
          // console.log('刀版的宽度', (image.width * (canvas.width / image.width) * this.zoom / 100))
          // console.log('刀版的高度', (image.height * (canvas.height / image.height) * this.zoom / 100))
          console.log('方格的宽度....', image.width * (canvas.width / image.width))
          console.log('方格距离左边的距离....', left)
          var clipRect = new fabric.Rect({
            left,
            top,
            width: image.width * (canvas.width / image.width),
            height: image.height * (canvas.width / image.width),
            fill: 'transparent',
            stroke: 'red',
            strokeWidth: 2,
            selectable: false
          })
          canvas.controlsAboveOverlay = true
          // 设置裁剪框的剪切区域
          // canvas.add(clipRect)
          canvas.set('clipPath', clipRect)
          canvas.setZoom(this.zoom / 100)
          this.centerCanvas(canvas, knifeToCanvasWidth, knifeToCanvasHeight)
          resolve(param)
          // let knifeScaling = 0
          // if (image.width > image.height) {
          //   knifeScaling = image.width / this.canvasSize.width
          // } else {
          //   knifeScaling = image.height / this.canvasSize.height
          // }
          // canvas.setWidth(image.width / knifeScaling)
          // canvas.setHeight(image.height / knifeScaling)
          // // const scaling = canvas.width / image.width
          // // 计算覆盖图像的左上角坐标
          // var left = (this.canvasSize.width - (image.width * knifeScaling)) / 2
          // var top = (this.canvasSize.height - (image.height * knifeScaling)) / 2
          // await canvas.setOverlayImage(url, canvas.renderAll.bind(canvas), {
          //   left,
          //   top,
          //   crossOrigin: 'Anonymous',
          //   angle: 0, // 图片旋转角度
          //   scaleX: knifeScaling,
          //   scaleY: knifeScaling,
          //   opacity: 0.97
          // })
          // const param = {
          //   // 刀版原图大小
          //   originalImageWidth: image.width,
          //   originalImageHeight: image.height,
          //   // 刀版在画布的宽度
          //   knifeToCanvasWidth: image.width * knifeScaling,
          //   knifeToCanvasHeight: image.height * knifeScaling,
          //   scaleX: this.canvasSize.width / image.width,
          //   scaleY: this.canvasSize.height / image.height,
          //   scaling: knifeScaling
          // }
          // var clipRect = new fabric.Rect({
          //   left,
          //   top,
          //   width: image.width * knifeScaling,
          //   height: image.height * knifeScaling,
          //   fill: 'transparent',
          //   stroke: 'red',
          //   strokeWidth: 2,
          //   selectable: false
          // })
          // canvas.controlsAboveOverlay = true
          // // 设置裁剪框的剪切区域
          // canvas.set('clipPath', clipRect)
          // canvas.setZoom(this.zoom / 100)
          // this.centerCanvas(canvas)
          // resolve(param)
        }
      })
    },
    centerCanvas(canvas, knifeToCanvasWidth, knifeToCanvasHeight) {
      // console.log('画布的宽度', image.width * (canvas.width / image.width))
      //     console.log('画布的高度', image.height * (canvas.height / image.height))
      //     console.log('刀版的宽度', (image.width * (canvas.width / image.width) * this.zoom / 100))
      //     console.log('刀版的高度', (image.height * (canvas.height / image.height) * this.zoom / 100))
      const canvasWidth = canvas.width
      const canvasHeight = canvas.height
      const containerWidth = knifeToCanvasWidth * (this.zoom / 100)
      const containerHeight = knifeToCanvasHeight * (this.zoom / 100)
      const offsetX = (containerWidth - canvasWidth) / 2
      const offsetY = (containerHeight - canvasHeight) / 2
      console.log('offsetX....', offsetX)
      console.log('offsetY....', offsetY)
      canvas.absolutePan({ x: offsetX, y: offsetY })
    },
    // 设置背景平铺
    setBackgroundRepeat(gapX = 0, gapY = 0) {
      const canvas = this.canvas
      const activeObject = canvas.getActiveObject()
      const objects = canvas.getObjects()
      objects.forEach(item => {
        if (item.types === 'repeat') {
          console.log('设置背景平铺objects....', objects)
          canvas.remove(item)
        }
      })
      activeObject.set({
        isbgImage: true
      })
      if (!activeObject.gapX) {
        activeObject.set({
          gapX: 0,
          gapY: 0
        })
      } else {
        gapX = activeObject.gapX
        gapY = activeObject.gapY
      }
      const width = activeObject.width * activeObject.scaleX
      const height = activeObject.height * activeObject.scaleY
      const fileUrl = activeObject.fileUrl + '?x-oss-process=image/resize,m_pad,w_' + (Math.round(width) + gapX) + ',h_' + (Math.round(height) + gapY) + ',color_FF0000,t=pattern'
      sessionStorage.setItem('fillUrl', fileUrl)
      // 将活动对象的位置设置为底部
      // activeObject.set('zIndex', canvas.getObjects().length)
      // fabric.Image.fromURL(fileUrl, function(img) {
      //   const imageBase64 = img.toDataURL()
      fabric.Image.fromURL(fileUrl, function(img) {
        const angle = activeObject.angle * Math.PI / 180
        const scale = 1

        const newImage = new fabric.Image(img._element, {
          left: activeObject.left,
          top: activeObject.top,
          angle: activeObject.angle,
          scaleX: activeObject.scaleX,
          scaleY: activeObject.scaleY,
          originX: 'center',
          originY: 'center'
        })

        const shape = new fabric.Rect({
          knifeId: canvas.knifeId,
          id: uuid(),
          types: 'repeat',
          width: canvas.width,
          height: canvas.height,
          left: 0,
          top: 0,
          selectable: false
        })

        shape.set('fill', new fabric.Pattern({
          source: newImage._element,
          repeat: 'repeat',
          patternTransform: [Math.cos(angle) * scale, Math.sin(angle) * scale, -Math.sin(angle) * scale, Math.cos(angle) * scale, 0, 0]
        }))
        activeObject.visible = false
        activeObject.repeatVisible = true
        canvas.add(shape)
        // canvas.bringToFront(shape)
        canvas.sendToBack(shape)
        // canvas.remove(activeObject)
        canvas.renderAll()
      }, {
        crossOrigin: 'anonymous'
      })
    },
    // 计算宽高
    countWidthHeight(e) {
      const bound = e.getBoundingRect()
      const param = {}
      param.height = bound.height
      param.width = bound.width
      return param
    },
    selectLastOneObject(canvas) {
      const arr = canvas.getObjects()
      canvas.setActiveObject(arr[arr.length - 1])
      const actived = canvas.getActiveObject()
      canvas.viewportCenterObjectV(actived)
      canvas.viewportCenterObjectH(actived)
    },
    // 字体加粗
    handleFW() {
      return new Promise((resolve) => {
        const canvas = this.canvas
        const actived = canvas.getActiveObject()
        if (actived.fontWeight === 'bold') {
          actived.set('fontWeight', 'normal')
        } else {
          actived.set('fontWeight', 'bold')
        }
        canvas.renderAll()
        resolve()
      })
    },
    // 字体斜体
    handleI() {
      return new Promise((resolve) => {
        const canvas = this.canvas
        const actived = canvas.getActiveObject()
        if (actived.fontStyle === 'italic') {
          actived.set('fontStyle', 'normal')
        } else {
          actived.set('fontStyle', 'italic')
        }
        canvas.renderAll()
        resolve()
      })
    },
    // 字体下划线
    handleU() {
      return new Promise((resolve) => {
        const canvas = this.canvas
        const actived = canvas.getActiveObject()
        if (actived.underline) {
          actived.set('underline', false)
        } else {
          actived.set('underline', true)
        }
        canvas.renderAll()
        resolve()
      })
    }
  }
}
</script>
