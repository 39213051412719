<template>
  <div class="zoom">
    <!-- <span class="shou" :class="isMoveCanvas ? 'actived' : ''" @click="handMoveCanvas" /> -->
    <!-- <div class="line" /> -->
    <span class="suoxiao" @click="handleSuoXiao" />
    <a-slider :value="zoom" style="width: 59px" :min="20" @change="onChange" @afterChange="onAfterChange" />
    <span class="fangda" @click="handleFangDa" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    canvas2: Object,
    isMoveCanvas: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('Canvas', {
      zoom: 'getZoom',
      canvasSize: 'getCanvasSize'
    })
  },
  data() {
    return {
      canvas: {},
      isHandMove: true
    }
  },
  watch: {
    canvas2(newValue, oldValue) {
      this.canvas = newValue
    }
  },
  created() {
    if (this.canvas2) {
      this.canvas = this.canvas2
    }
  },
  methods: {
    async onChange(value) {
      this.canvas.setZoom(value / 100)
      this.canvas.zoom = value
      this.canvas.renderAll()
      this.centerCanvas(value)
      await this.$store.dispatch('Canvas/SET_ZOOM', value)
    },
    centerCanvas(value) {
      const canvasWidth = this.canvas.getWidth()
      const canvasHeight = this.canvas.getHeight()
      const containerWidth = this.canvas.getWidth() * (value / 100)
      const containerHeight = this.canvas.getHeight() * (value / 100)
      console.log('canvasWidth....', containerWidth)
      console.log('canvasHeight....', canvasHeight)
      console.log('containerWidth....', containerWidth)
      console.log('containerHeight....', containerHeight)
      const offsetX = (containerWidth - canvasWidth) / 2
      const offsetY = (containerHeight - canvasHeight) / 2
      this.canvas.absolutePan({ x: offsetX, y: offsetY })
    },
    onAfterChange(value) {
      console.log('afterChange: ', value)
    },
    // 画布移动
    handMoveCanvas() {
      if (this.isMoveCanvas) {
        this.$emit('handMoveCanvas', false)
      } else {
        this.$emit('handMoveCanvas', true)
      }
    },
    handleSuoXiao() {
      // this.zoom2 = this.zoom2 - 1
      this.$emit('onZoom', this.zoom2 - 1)
    },
    handleFangDa() {
      // this.zoom2 = this.zoom2 - 1
      if (this.zoom2 === 100) {
        return
      }
      this.$emit('onZoom', this.zoom2 + 1)
    }
  }
}
</script>
<style>
.zoom .ant-slider-handle{
  border: solid 0.104167vw #FF5347;
}
.zoom .ant-slider-track{
  background-color: #FF5347;
}
</style>
<style scoped lang="scss">
.zoom {
  // width: 201px;
  height: 40px;
  background: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 100px;
  position: absolute;
  z-index: 10;
  bottom: 30px;
  right: 30px;
  padding: 0 16px;
  box-sizing: border-box;
  .shou{
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url(../../../assets/images/custom/shou.png) no-repeat;
    background-size: 100%;
    &.actived{
      background: url(../../../assets/images/custom/shou2.png) no-repeat;
      background-size: 100%;
    }
    &:hover{
      background: url(../../../assets/images/custom/shou2.png) no-repeat;
      background-size: 100%;
    }
  }
  .suoxiao{
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(../../../assets/images/custom/suoxiao.png) no-repeat;
    background-size: 100%;
    &.actived{
      background: url(../../../assets/images/custom/suoxiao2.png) no-repeat;
      background-size: 100%;
    }
    &:hover{
      background: url(../../../assets/images/custom/suoxiao2.png) no-repeat;
      background-size: 100%;
    }
  }
  .fangda{
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(../../../assets/images/custom/fangda.png) no-repeat;
    background-size: 100%;
    &.actived{
      background: url(../../../assets/images/custom/fangda2.png) no-repeat;
      background-size: 100%;
    }
    &:hover{
      background: url(../../../assets/images/custom/fangda2.png) no-repeat;
      background-size: 100%;
    }
  }
  .line {
    width: 2px;
    height: 19px;
    background-color: #c0c0c0;
    margin: 0 10px;
  }
}
</style>
