<template>
  <div class="custom">
    <!-- 头部 -->
    <div v-if="patterIndex < productModeNum && !isFitting" class="custom-header">
      <!--  @click="addClip" -->
      <div class="custom-header-logo">
        <img src="@/assets/images/custom/DogeDiy-LOGO.png" alt="">
      </div>
      <div class="custom-header-opera">
        <div class="custom-header-opera-back" style="margin-right:16px" @click="$router.push('/catalog')">
          <img src="@/assets/images/custom/back.png" alt="">
          <span>Back to Catalog</span>
        </div>
        <!-- patterIndex >= this.productModeNum -->
        <div v-if="brandList.length > 0 || isFitting" class="custom-header-opera-btn custom-header-opera-branding" @click="handleBrand">
          Branding
        </div>
        <div v-if="isSubmit" class="custom-header-opera-btn custom-header-opera-submit2">
          <a-icon type="loading" style="color: #fff" />
          <span style="margin-left: 5px">Submit</span>
        </div>
        <div v-else class="custom-header-opera-btn custom-header-opera-submit" @click="generate">
          <span style="margin-left: 5px">Submit</span>
        </div>
      </div>
    </div>
    <div v-else class="brand-head">
      <div class="brand-head-left">
        Custom Branding
      </div>
      <div v-if="!isFitting" class="brand-head-right">
        <div class="brand-head-right-cancel" @click="handleCancelBrand">Cancel</div>
        <div class="brand-head-right-submit" @click="brandGenerateSave">Save</div>
      </div>
      <div v-if="isFitting" class="brand-head-right brand-head-right2">
        <div class="brand-head-right-cancel" @click="$router.go(-1)">Cancel</div>
        <div v-if="isSubmit" class="custom-header-opera-btn custom-header-opera-submit2">
          <a-icon type="loading" style="color: #fff" />
          <span style="margin-left: 5px">Submit</span>
        </div>
        <div v-else class="custom-header-opera-btn custom-header-opera-submit" @click="handleFittingsSubmit">
          <span style="margin-left: 5px">Submit</span>
        </div>
      </div>
    </div>
    <div class="custom-main">
      <!-- 侧边栏 -->
      <div v-if="(patterIndex <= productModeNum - 1 && !isFitting) || (patterIndex > productModeNum - 1 && isAllAreas)" class="fun">
        <a-tooltip placement="right">
          <template slot="title">
            Product
          </template>
          <div class="fun-img " :class="menuActived == 2 ? 'cur' : ''" @click="changeMenu(2)">
            <img v-show="menuActived == 1 || menuActived == 3" src="@/assets/images/custom/chizi.png" alt="">
            <img v-show="menuActived == 2" src="@/assets/images/custom/chizi_actived.png" alt="">
          </div>
        </a-tooltip>
        <a-tooltip placement="right">
          <template slot="title">
            Layer
          </template>
          <div class="fun-img " :class="menuActived == 1 ? 'cur' : ''" @click="changeMenu(1)">
            <img v-show="menuActived == 2 || menuActived == 3" src="@/assets/images/custom/maozi.png" alt="">
            <img v-show="menuActived == 1" src="@/assets/images/custom/maozi_actived.png" alt="">
          </div>
        </a-tooltip>
        <div class="psd" @click="downPsd">
          <a-tooltip placement="right">
            <template slot="title">
              Download Design Template
            </template>
            <span />
          </a-tooltip>
        </div>
      </div>
      <!-- 附件列表 -->
      <brand v-if="patterIndex > productModeNum - 1 && !isAllAreas" :brand-list="brandList" :product-mode-num="productModeNum" @changeBrand="changeBrand" />
      <!-- 图片展示区 -->
      <div v-show="menuActived == 1" class="image-contral">
        <div v-if="patterIndex <= productModeNum - 1" class="sku-title">{{ blankProductInfo.model }}</div>
        <div v-else class="sku-title">{{ brandName }}</div>
        <add-image ref="addImage" :is-all-areas2="isAllAreas" :canvas="canvas" :canvas-size="canvasSize" :oss-data="ossData" :save-canvas-state="saveCanvasState" />
      </div>
      <div v-show="menuActived == 2" class="skus">
        <div class="sku-title">{{ blankProductInfo.model }}</div>
        <knife-sku v-if="patternInfos.length > 0" :knife-set-info-list2="patternInfos[patterIndex].knifeSetInfoList" :knife-set-index2="knifeSetIndex" @changeKnifeSku="changeKnifeSku" />
        <sku v-if="patternInfos.length > 0" ref="sku" :sku-maps="skuMaps" :sku-infos="skuInfos" :first-sku-list="firstSkuList" :sku-ids="skuIds" @setSkuIds="getSkuIds" />
      </div>
      <!-- 画布区 -->
      <div class="canvas-main">
        <opera
          :canvas="canvas"
          :mode="mode"
          :product-mode-num="productModeNum"
          :patter-index="patterIndex"
          :save-canvas-state="saveCanvasState"
          :pattern-infos="patternInfos"
          @setMode="getMode"
          @undo="historyState(stateIndex - 1)"
          @redo="historyState(stateIndex + 1)"
          @clearCanvas="clearCanvas"
        />
        <div v-show="skuIds.length != 0 || isFitting == true" class="canvas-con">
          <!-- 预览 -->
          <template v-if="patternInfo">
            <div v-if="patternInfo.type == patternTypeEnum.MULTI_KNIFE.value" class="preview-design " :class="isAllAreas ? 'actived' : ''" @click="clickAllAreas">
              <span />
              Preview Design
            </div>
          </template>

          <!-- 预览画布 -->
          <div v-show="isAllAreas" :style="{ position: isAllAreas ? 'relative' : 'absolute' }" style="position: relative;margin: 0 auto;display: flex;align-items: center;">
            <canvas id="canvasPreview" />
          </div>
          <template v-for="(item,patternInfosIndex) in patternInfos">
            <template v-for="(item2,knifeSetInfoIndex) in item.knifeSetInfoList">
              <!-- 刀版列表 -->
              <div v-show="knifeSetIndex === knifeSetInfoIndex" :key="knifeSetInfoIndex+patternInfosIndex+'knife'" class="knife">
                <div class="knife-con">
                  <div class="knife-scroll">
                    <template v-for="(item3,index3) in item2.knifeInfoList">
                      <div v-show="patternInfosIndex == patterIndex && !isAllAreas" :key="index3" class="knife-list">
                        <div class="knife-list-img " :class="item3.curr && !isAllAreas ? 'actived' : ''" @click="handleChangeKnife(item3,index3)">
                          <img :src="item3.knifePlateImageUrl">
                          <!-- {{ item3.knifeDpi }} -->
                          <!-- <div class="zhiliang">
                            <span v-if="item3.knifeDpi == 'Good'" class="good" />
                            <span v-if="item3.knifeDpi == 'Normal'" class="normal" />
                            <img v-if="item3.knifeDpi == 'Bad'" src="@/assets/images/custom/bad.png" class="bad" alt="">
                          </div> -->
                        </div>
                        <p>{{ item3.name }}</p>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <template v-for="(item3,index) in item2.knifeInfoList">
                <!-- patternInfosIndex:{{ patternInfosIndex }}---patterIndex:{{ patterIndex }}--knifeSetIndex:{{ knifeSetIndex }}--knifeSetInfoIndex:{{ knifeSetInfoIndex }}--item3.curr:{{ item3.curr }} -->
                <div v-show="patternInfosIndex == patterIndex &&knifeSetIndex === knifeSetInfoIndex && item3.curr && !isAllAreas" :key="index+'-'+item3.id" style="position: relative;margin: 0 auto;display: flex;align-items: center;">
                  <!-- {width: (getKnifeToCanvasWidth(item3.canvas) * (zoom/100)) + 'px',height: (getKnifeToCanvasHeight(item3.canvas)* (zoom/100)) + 'px','margin-top': -(getKnifeToCanvasHeight(item3.canvas) / 2 * (zoom/100)) + 'px',marginLeft: -(getKnifeToCanvasWidth(item3.canvas) / 2 * (zoom/100)) + 'px'} -->
                  <div v-if="item3.canvas" class="canvas-box" :style="getCanvasBox(item3.canvas)" />
                  <div v-if="getObjectsLength(item3.canvas) == 0 && item3.enableTextAndImage" :style="{ transform: 'scale(' + (zoom / 100) + ')'}" class="adimg " @click="$refs.addImageDialog.addImageIsShowDialog = true">
                    <div class="adimg-con">

                      Upload Your Design Here
                      <p v-if="item3.recommendImageWidth && item3.recommendImageHeight">({{ item3.recommendImageWidth }}*{{ item3.recommendImageHeight }})px</p>
                    </div>
                  </div>
                  <canvas :id="`canvas${item3.id}`" />
                </div>
              </template>
            </template>
          </template>
        </div>
        <zoom v-if="!isAllAreas" :zoom="zoom" :is-move-canvas="isMoveCanvas" :canvas2="canvas" @handMoveCanvas="handMoveCanvas" @onZoom="onZoom" />
      </div>
      <div class="canvas-priview">
        <control v-if="isLoadSuccess" :generate-preview-loading="generatePreviewLoading" :real-time-preview-loading="realTimePreviewLoading" :preview-image-urls="previewImageUrls" :real-time-preview-task-info="realTimePreviewTaskInfo" :realtime-preview-image-urls="realtimePreviewImageUrls" :canvas="canvas" :save-canvas-state="saveCanvasState" @preview="generatePreview" />
      </div>
    </div>
    <addImageDialog ref="addImageDialog" :oss-data="ossData" :canvas2="canvas" @showAddImg="showAddImg" />
    <addText ref="addText" :canvas2="canvas" :save-canvas-state="saveCanvasState" />
    <!-- 提示 -->
    <Tips v-show="isTips" :product-id="productId" @close="onTipsClose" @open="onTipsOpen" />
    <div v-if="!isLoadSuccess" class="example">
      <a-icon type="loading" style="color: #444;fontSize: 60px" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { fabric } from 'fabric'
import addImageDialog from './add_image/components/add_image.vue'
import addImage from './add_image/index.vue'
import opera from './components/opera.vue'
import Control from './control/index.vue'
import { generateKnifePreviewV2, productDetail, generatePreviewV3, generatePreviewStatus, productGenerateV3, generateStatus, fittingsSubmit, fittingGenerateStatus } from '../../api/product'
import zoom from './components/zoom.vue'
import sku from './sku/index'
import knifeSku from './sku/knife'
import { fileSts } from '@/api/oss'
import BaseView from '@/components/base/BaseView'
import blankProductPatternTypeEnum from '@/enums/blank_product/blankProductPatternTypeEnum'
import { waitForMs } from '@/utils/util'
import uuid from 'licia/uuid'
import addText from './add_image/components/add_text.vue'
import brand from './brand/index'
import Tips from '@/components/fact/custom/tips'
export default {
  components: {
    addImageDialog,
    addImage,
    opera,
    Control,
    zoom,
    sku,
    knifeSku,
    addText,
    brand,
    Tips
  },
  extends: BaseView,
  data() {
    return {
      isAddImageLoad: false,
      patternTypeEnum: blankProductPatternTypeEnum,
      isLoadSuccess: false, // 画布是否加载完成
      isAllAreas: false, // 是否显示集合图
      isTips: false,
      productId: null,
      isSubmit: false,
      menuActived: 3, // 1图片  2sku
      selectImgIndex: 0,
      innerWidth: window.innerWidth, // 浏览器宽度
      currentKnifeList: [{
        name: 1
      }],
      previewNum: 0,
      // 当前模式  0单刀版  1多刀版
      mode: 1,
      // 所有的模式
      patternInfos: [],
      // 当前选中的模式
      patternInfo: {},
      // 当前选中的刀版集合
      knifeSetInfo: {},
      knifeSetIndex: 0,
      // patternInfos的索引
      patterIndex: 0,
      canvasList: [],
      currKnifeInfo: {},
      canvas: {},
      previewCollectCanvas: {},
      // canvasSize: {
      //   width: 1100,
      //   height: 700
      // },
      // 是否可以移动画布
      isMoveCanvas: false,
      // oss信息上传图片用
      ossData: {},
      // 画布是否可以缩放
      isScale: false,
      skuInfos: [],
      skuMaps: [],
      firstSkuList: [],
      blankProductInfo: {},
      // 实时预览（后台只配置了一两个key，用于实时变形）
      realTimePreviewLoading: false,
      // 生成预览（会把所有key都去生成图片）
      generatePreviewLoading: false,
      realTimePreviewTaskInfo: {
        lock: false,
        // 当前任务
        current: null,
        // 下一个任务
        next: null
      },
      // 实时预览图片列表
      realtimePreviewImageUrls: [],
      // 预览ID
      customProductGeneratePreviewId: null,
      // 预览图片列表
      previewImageUrls: [],
      // 档位
      gear: 2,
      preViewCanvas: {},
      // 选中的skuid
      skuIds: [],
      // 画布对象数量
      objectsLength: 0,
      // 上一步下一步
      isLoadCanvas: false,
      stateIndex: -1,
      canvasState: [],
      history: [],
      // zoom: 80,

      // 附件
      brandName: '',
      brandList: [],
      productModeNum: 0, // 产品模式数量
      brandRequestData: {}, // 附件提交的数据
      brandRequestDataList: [], // 附件提交的数据

      customProductId: '',
      isFitting: false
    }
  },
  computed: {
    ...mapGetters('Canvas', {
      canvasSize: 'getCanvasSize',
      zoom: 'getZoom'
    })
  },
  watch: {
    canvas: {
      handler(newValue, oldValue) {
        if (newValue) {
          const objects = newValue.getObjects()
          this.objectsLength = objects.length
        }
      }
    }
  },
  async mounted() {
    const param = {
      width: window.innerWidth - 238 - 353 - 322 - 80,
      height: window.innerHeight - 79 - 64
    }
    await this.$store.dispatch('Canvas/SET_CANVASSiZE', param)
    if (this.$route.query.type === 'fittings') {
      this.customProductId = this.$route.query.customProductId
      this.isFitting = true
    } else {
      this.isFitting = false
    }
    this.productId = this.$route.query.id
    this.getProductDetail()
    this.getOssMsg()
  },
  methods: {
    changeKnifeSku(index) {
      console.log('this.patternInfos....', this.patternInfos)
      this.knifeSetIndex = index
      this.skuIds = this.patternInfos[this.patterIndex].knifeSetInfoList[index].supportSkuIds
      this.patternInfo = this.patternInfos[this.patterIndex]
      this.knifeSetInfo = this.patternInfos[this.patterIndex].knifeSetInfoList[index]
      this.patternInfos[this.patterIndex].knifeSetInfoList.forEach(item => {
        item.curr = false
      })
      this.patternInfos[this.patterIndex].knifeSetInfoList[index].curr = true
      this.canvas = this.patternInfos[this.patterIndex].knifeSetInfoList[index].knifeInfoList[0].canvas
      this.currKnifeInfo = this.patternInfos[this.patterIndex].knifeSetInfoList[index].knifeInfoList[0]
      this.onChangeCanvas()
      this.$forceUpdate()
    },
    saveCanvasState() {
      if (!this.isLoadCanvas) {
        // if (this.previewNum > 0) {
        //   this.previewNum += 1
        this.onChangeCanvas()
        // }
        const canvasAsJson = JSON.stringify(this.canvas.toJSON(['knifeId', 'id', 'fileUrl', 'smallImageUrl', 'type', 'width', 'height', 'fileName', 'scale', 'gapX', 'gapY', 'scaleX', 'scaleX', 'scaleHeight', 'scaleWidth', 'text']))
        this.canvasState.splice(this.stateIndex + 1)
        this.canvasState.push(canvasAsJson)
        this.stateIndex = this.canvasState.length - 1
      } else {
        this.isLoadCanvas = false
      }
    },
    historyState(index) {
      if (index > 0) {
        this.isLoadCanvas = true
        this.canvas.loadFromJSON(this.canvasState[index], () => {
          this.canvas.renderAll()
          this.stateIndex = index
        })
      }
    },
    loadCanvasState(state) {
      this.canvas.loadFromJSON(state, () => {
        this.canvas.renderAll()
      })
    },
    deepClone(obj) { // 简易深拷贝
      const _obj = JSON.stringify(obj); const objClone = JSON.parse(_obj)
      return objClone
    },
    // 取消附件
    handleCancelBrand() {
      console.log('handleCancelBrand...', this.productIndex)
      this.patterIndex = this.productIndex
      this.getMode(this.patternInfos[this.productIndex].type)
    },
    // 切换到附件模式
    handleBrand() {
      // 记录当前编辑普通产品的索引
      this.productIndex = this.patterIndex
      // 显示图片部分
      this.menuActived = 1
      this.changeBrand(this.productModeNum)
    },
    // 切换附件
    changeBrand(val) {
      if (JSON.stringify(this.preViewCanvas) !== '{}') { this.preViewCanvas.clear() }
      this.patterIndex = val
      this.canvasState = []
      // this.patternInfos[this.patterIndex].knifeSetInfoList = this.preViewCanvas
      // console.log('切换附件...', this.preViewCanvas)
      // 设置默认第一个选中
      this.patternInfo = this.patternInfos[this.patterIndex]
      this.brandName = this.brandList[val - this.productModeNum].model
      this.realtimePreviewImageUrls = this.brandList[val - this.productModeNum].showImageList
      for (const item of this.patternInfos[this.patterIndex].knifeSetInfoList) {
        // this.skuIds = item.supportSkuIds
        this.knifeSetInfo = item
        item.knifeInfoList.forEach((knifeItem, index) => {
          knifeItem.curr = false
          if (index === 0) {
            knifeItem.curr = true
          }
          if (knifeItem.curr) {
            this.canvas = knifeItem.canvas
            this.currKnifeInfo = knifeItem
            this.$forceUpdate()
          }
          this.onChangeCanvas()
        })
      }
    },
    // 获取附件产品详情
    async getBrandProductDetail(id) {
      return new Promise(async resolve => {
        const { data, code } = await productDetail({
        // id: 739,
          id,
          blankProductInfo: true,
          skuInfos: true,
          showImageList: true, // 展示图片列表
          skuMap: true,
          blankPattern2: true
        })
        if (code === 200) {
          this.brandRequestDataList.push({ id: id })
          resolve(data)
        }
      })
    },
    // 监听画布操作
    onChangeCanvas(realtimePreview = true) {
      if (realtimePreview) {
        // var canvasSvg = null
        if (this.patternInfo.type === blankProductPatternTypeEnum.GATHER.value) {
          // 直接拿画布svg去生成实时预览图
          // canvas = this.canvas
          // 设置集合画布

          const canvasWidth = this.canvas.getWidth()
          const canvasHeight = this.canvas.getHeight()
          const canvasJson = this.canvas.toJSON()
          this.previewCollectCanvas = new fabric.Canvas(null, {
            width: canvasWidth,
            height: canvasHeight
          })

          this.previewCollectCanvas.loadFromJSON(canvasJson, () => {
            // 反序列化完成后的回调函数
            console.log('this.canvas.toJSON()...', this.previewCollectCanvas.toJSON())
            console.log('canvasJson...', this.previewCollectCanvas.getObjects())
            this.previewCollectCanvas.renderAll()
            const objects = this.previewCollectCanvas.getObjects()
            // 删除拿去平铺的图片
            let actived = null
            objects.forEach((item, index) => {
              if (!item.visible) {
                actived = item
              }
            })
            this.previewCollectCanvas.remove(actived, this.previewCollectCanvas.renderAll.bind(this.previewCollectCanvas))
            this.previewCollectCanvas.toJSON().objects = objects
            // 删除overlayImage
            delete this.previewCollectCanvas.overlayImage
            console.log('svg....', this.previewCollectCanvas.toSVG())
            var canvasSvg = this.previewCollectCanvas.toSVG()
            var setId = this.knifeSetInfo.id
            this.submitRealTimePreviewTask({ setId, svg: canvasSvg })
          })
          // canvasSvg = canvas.toSVG()
        } else if (this.patternInfo.type === blankProductPatternTypeEnum.MULTI_KNIFE.value) {
          // 先组合集合图画布，再拿集合图画布json去生成实时预览图
          this.setGroupImage()
          // canvasSvg = this.preViewCanvas.toSVG()
        }
      }
      // 设置刀版的dpi
      // this.setKnifeDpi()
    },
    // 设置集合图
    async setGroupImage() {
      const knifeSetInfo = this.knifeSetInfo
      const preViewCanvas = new fabric.Canvas('canvasPreview', { preserveObjectStacking: true })
      const wd = Math.min(this.canvasSize.width, this.canvasSize.height)
      preViewCanvas.setWidth(wd)
      preViewCanvas.setHeight(wd)
      this.preViewCanvas = preViewCanvas
      for (const item of knifeSetInfo.knifeInfoList) {
        await this.setGroupCanvas(item).then(async() => {
          await this.addPreviewImage(item.canvas, preViewCanvas, item)
        })
      }
      this.preViewCanvas.renderAll()
    },
    // 设置集合图画布
    setGroupCanvas(item) {
      return new Promise(resolve => {
        const preViewCanvas = this.preViewCanvas
        const positionJson = JSON.parse(this.knifeSetInfo.positionJson)
        fabric.Image.fromURL(item.knifePlateImageUrl, img => {
          img.set({
            // 通过scale来设置图片大小,这里设置和画布一样大
            scaleX: preViewCanvas.width / img.width * positionJson[item.name]['width'],
            scaleY: preViewCanvas.height / img.height * positionJson[item.name]['height'],
            left: positionJson[item.name].x * preViewCanvas.width,
            top: positionJson[item.name].y * preViewCanvas.height,
            selectable: false, // 设置为不可选中
            evented: false // 设置为不可交互
          })
          preViewCanvas.add(img)
        }, {
          crossOrigin: 'anonymous' // 可选项：如果需要跨域加载图像，请设置crossOrigin属性
        })
        resolve()
      })
    },
    // previewCanvas
    async addPreviewImage(canvas, preViewCanvas, currKnifeInfo) {
      // 多刀版模式才需要
      if (this.patternInfos[this.patterIndex].type === 1) {
        const positionJson = JSON.parse(this.knifeSetInfo.positionJson)
        const canvasActived = canvas.getActiveObject()
        // 设置背景颜色
        if (canvas.backgroundColor) {
          // 创建一个矩形对象
          let left = 0
          let top = 0
          if (canvasActived !== undefined) {
            if (canvasActived.left) {
              left = 0
            }
            if (canvasActived.top) {
              top = 0
            }
          }
          var rect = new fabric.Rect({
            knifeId: canvas.knifeId,
            id: uuid(),
            selectable: false, // 设置为不可选中
            evented: false, // 设置为不可交互
            type: 'backgroundColor',
            fileName: currKnifeInfo.name,
            left: positionJson[currKnifeInfo.name].x * preViewCanvas.width + (left | 0 / canvas.width),
            top: positionJson[currKnifeInfo.name].y * preViewCanvas.height + (top | 0 / canvas.height),
            width: preViewCanvas.width * positionJson[currKnifeInfo.name]['width'], // 矩形宽度
            height: preViewCanvas.height * positionJson[currKnifeInfo.name]['height'], // 矩形高度
            fill: canvas.backgroundColor // 矩形填充颜色
          })
          preViewCanvas.add(rect).sendToBack()
        }
        this.drawPreviewCanvas(canvas, preViewCanvas, currKnifeInfo).then((res) => {
          if (!this.preViewCanvas) {
            return
          }
          setTimeout(() => {
            // this.brandGenerate(false)
            this.patternInfos[this.patterIndex].knifeSetInfoList[0].preViewCanvas = this.preViewCanvas
            var canvasSvg = this.preViewCanvas.toSVG()
            var setId = this.knifeSetInfo.id
            this.submitRealTimePreviewTask({ setId, svg: canvasSvg })
            this.brandGenerate(this.preViewCanvas)
          }, 50)
        })
      }
    },
    // 绘制当前的画布
    drawPreviewCanvas(canvas, preViewCanvas, currKnifeInfo) {
      return new Promise((resolve) => {
        const positionJson = JSON.parse(this.knifeSetInfo.positionJson)
        const textScale = (preViewCanvas.width * positionJson[currKnifeInfo.name].width * (canvas.originalImageWidth / canvas.width)) / canvas.originalImageWidth
        const canvasObjects = canvas.getObjects()
        // 重绘当前画布的所有对象
        for (const item of canvasObjects) {
          let imgScale = 0
          var clonedObject = fabric.util.object.clone(item)
          // 平铺的背景
          if (item.type === 'image' && item.isbgImage) {
            const fileUrl = item.fileUrl
            const width = (preViewCanvas.width * positionJson[currKnifeInfo.name].width * (item.width * item.scaleX / canvas.width))
            const height = (preViewCanvas.height * positionJson[currKnifeInfo.name].height * (item.height * item.scaleY / canvas.height))
            imgScale = (preViewCanvas.width * positionJson[currKnifeInfo.name].width * (item.width * item.scaleX / canvas.width)) / item.width

            fabric.Image.fromURL(fileUrl + '?x-oss-process=image/resize,m_pad,w_' + (Math.round(width)) + ',h_' + (Math.round(height)) + ',color_FF0000,t=pattern', function(img) {
              const angle = item.angle * Math.PI / 180
              const scale = 1

              const newImage = new fabric.Image(img._element, {
                left: positionJson[currKnifeInfo.name].x * preViewCanvas.width + (item.left / canvas.width),
                top: positionJson[currKnifeInfo.name].y * preViewCanvas.height + (item.top / canvas.height),
                angle: item.angle,
                scaleX: imgScale,
                scaleY: imgScale,
                originX: 'center',
                originY: 'center'
              })

              const shape = new fabric.Rect({
                knifeId: item.knifeId,
                id: uuid(),
                type: 'repeat',
                types: 'repeat',
                width: preViewCanvas.width * positionJson[currKnifeInfo.name].width,
                height: preViewCanvas.height * positionJson[currKnifeInfo.name].height,
                left: positionJson[currKnifeInfo.name].x * preViewCanvas.width + (item.left / canvas.width),
                top: positionJson[currKnifeInfo.name].y * preViewCanvas.height + (item.top / canvas.height),
                selectable: false, // 设置为不可选中
                evented: false // 设置为不可交互
              })

              shape.set('fill', new fabric.Pattern({
                source: newImage._element,
                repeat: 'repeat',
                patternTransform: [Math.cos(angle) * scale, Math.sin(angle) * scale, -Math.sin(angle) * scale, Math.cos(angle) * scale, 0, 0]
              }))
              preViewCanvas.add(shape)
            })
          }
          if (item.type === 'image' && !item.isbgImage) {
            // getClipDistance =
            var imgPopW = (preViewCanvas.width * positionJson[currKnifeInfo.name].width * (item.width * item.scaleX / canvas.width)) / item.width
            var imgPopY = (preViewCanvas.height * positionJson[currKnifeInfo.name].height * (item.height * item.scaleY / canvas.height)) / item.height
            const imgScalW = item.width / (item.width * imgPopW)// 原始图片再画布中缩小了几倍
            const imgScalY = item.height / (item.height * imgPopY)
            // 图片顶点距离预览画布顶部的位置
            const imageToPreviewCanvasTop = positionJson[currKnifeInfo.name].y * preViewCanvas.height + (item.top / canvas.height) * positionJson[currKnifeInfo.name].height * preViewCanvas.height
            const imageToPreviewCanvasLeft = positionJson[currKnifeInfo.name].x * preViewCanvas.width + (item.left / canvas.width) * positionJson[currKnifeInfo.name].width * preViewCanvas.width
            // 刀版顶点距离预览画布顶部的位置
            const knifeTop = positionJson[currKnifeInfo.name].y * preViewCanvas.height
            const knifeLeft = positionJson[currKnifeInfo.name].x * preViewCanvas.width
            // 基于图片的中心点
            // const imageHeightCenter = (item.height * imgPopY) / 2
            // const imagewidthCenter = (item.width * imgPopW) / 2
            // 图片中心点的位置距离画布顶点的位置
            // const imageCenterToPreviewCanvasTop = imageHeightCenter + imageToPreviewCanvasTop
            // const imageCenterToPreviewCanvasLeft = imagewidthCenter + imageToPreviewCanvasLeft
            const imageCenterToPreviewCanvasTop = imageToPreviewCanvasTop
            const imageCenterToPreviewCanvasLeft = imageToPreviewCanvasLeft
            const juLiTop = (imageCenterToPreviewCanvasTop - knifeTop) * imgScalY
            const juLileft = (imageCenterToPreviewCanvasLeft - knifeLeft) * imgScalW

            const clipWidth = (positionJson[currKnifeInfo.name].width * preViewCanvas.width) * imgScalW // 将画布放大和图片一样的倍数
            const clipHeight = (positionJson[currKnifeInfo.name].height * preViewCanvas.height) * imgScalY

            var clipRect = new fabric.Rect({
              left: -juLileft,
              top: -juLiTop,
              width: clipWidth,
              height: clipHeight,
              fill: 'white', // 裁剪区域的填充颜色
              stroke: 'black',
              strokeWidth: 2
            })

            clonedObject.set({
              knifeId: item.knifeId,
              id: item.id,
              selectable: false, // 设置为不可选中
              evented: false, // 设置为不可交互
              angle: item.angle,
              visible: item.visible,
              fileUrl: item.fileUrl,
              scaleX: imgPopW,
              scaleY: imgPopY,
              flipX: item.flipX,
              flipY: item.flipY,
              left: positionJson[currKnifeInfo.name].x * preViewCanvas.width + (item.left / canvas.width) * positionJson[currKnifeInfo.name].width * preViewCanvas.width,
              top: positionJson[currKnifeInfo.name].y * preViewCanvas.height + (item.top / canvas.height) * positionJson[currKnifeInfo.name].height * preViewCanvas.height,
              clipPath: clipRect
            })
            console.log('画布的高度....', positionJson[currKnifeInfo.name].y * preViewCanvas.height)
            console.log('item.top....', item.top)
            preViewCanvas.add(clonedObject)
          } else if (item.type === 'text') {
            var textPopW = (preViewCanvas.width * positionJson[currKnifeInfo.name].width * (item.width * item.scaleX / canvas.width)) / item.width
            var textPopY = (preViewCanvas.height * positionJson[currKnifeInfo.name].height * (item.height * item.scaleY / canvas.height)) / item.height
            const imgScalW = item.width / (item.width)// 原始图片再画布中缩小了几倍
            const imgScalY = item.height / (item.height)
            // 图片顶点距离预览画布顶部的位置
            const imageToPreviewCanvasTop = positionJson[currKnifeInfo.name].y * preViewCanvas.height + (item.top / canvas.height) * positionJson[currKnifeInfo.name].height * preViewCanvas.height
            const imageToPreviewCanvasLeft = positionJson[currKnifeInfo.name].x * preViewCanvas.width + (item.left / canvas.width) * positionJson[currKnifeInfo.name].width * preViewCanvas.width
            // 刀版顶点距离预览画布顶部的位置
            const knifeTop = positionJson[currKnifeInfo.name].y * preViewCanvas.height
            const knifeLeft = positionJson[currKnifeInfo.name].x * preViewCanvas.width
            // 基于图片的中心点
            const imageHeightCenter = (item.height * textPopY) / 2
            const imagewidthCenter = (item.width * textPopW) / 2
            // 图片中心点的位置距离画布顶点的位置
            const imageCenterToPreviewCanvasTop = imageHeightCenter + imageToPreviewCanvasTop
            const imageCenterToPreviewCanvasLeft = imagewidthCenter + imageToPreviewCanvasLeft
            const juLiTop = (imageCenterToPreviewCanvasTop - knifeTop) * imgScalY
            const juLileft = (imageCenterToPreviewCanvasLeft - knifeLeft) * imgScalW

            const clipWidth = (positionJson[currKnifeInfo.name].width * preViewCanvas.width) * imgScalW // 将画布放大和图片一样的倍数
            const clipHeight = (positionJson[currKnifeInfo.name].height * preViewCanvas.height) * imgScalY

            var clipTextRect = new fabric.Rect({
              left: -juLileft,
              top: -juLiTop,
              width: clipWidth,
              height: clipHeight,
              fill: 'white', // 裁剪区域的填充颜色
              stroke: 'black',
              strokeWidth: 2
            })

            // 创建文本对象
            var text = new fabric.Text(item.text, {
              knifeId: item.knifeId,
              id: item.id,
              selectable: false, // 设置为不可选中
              evented: false, // 设置为不可交互
              angle: item.angle,
              left: positionJson[currKnifeInfo.name].x * preViewCanvas.width + (item.left / canvas.width) * positionJson[currKnifeInfo.name].width * preViewCanvas.width,
              top: positionJson[currKnifeInfo.name].y * preViewCanvas.height + (item.top / canvas.height) * positionJson[currKnifeInfo.name].height * preViewCanvas.height,
              fill: item.fill,
              visible: item.visible,
              underline: item.underline,
              fontStyle: item.fontStyle,
              fontWeight: item.fontWeight,
              fontFamily: item.fontFamily,
              originFontSize: item.fontSize,
              fontSize: item.fontSize * textScale,
              clipPath: clipTextRect
            })
            // clonedObject.zoom(0.5)
            // 将文本对象添加到画布
            preViewCanvas.add(text)
          }
        }
        preViewCanvas.renderAll() // 渲染画布
        resolve(preViewCanvas)
      })
    },
    // 提交实时预览任务
    submitRealTimePreviewTask({ setId, svg }) {
      this.realTimePreviewLoading = true
      if (this.realTimePreviewTaskInfo.lock) {
        return
      }
      this.realTimePreviewTaskInfo.lock = true
      this.realTimePreviewTaskInfo.next = { setId, svg }
      if (!this.realTimePreviewTaskInfo.current) {
        this.realTimePreviewTaskInfo.current = this.realTimePreviewTaskInfo.next
        this.realTimePreviewTaskInfo.next = null
        this.executeRealTimePreviewTask()
      }
      this.realTimePreviewTaskInfo.lock = false
    },
    // 执行实时预览任务
    executeRealTimePreviewTask() {
      if (this.realTimePreviewTaskInfo.current) {
        generateKnifePreviewV2(this.realTimePreviewTaskInfo.current, false, false).then(response => {
          if (response.code === 200) {
            this.realtimePreviewImageUrls = response.data || []
            this.realTimePreviewLoading = false
          }
        }).finally(() => {
          this.realTimePreviewTaskInfo.current = null
          if (this.realTimePreviewTaskInfo.next) {
            this.realTimePreviewTaskInfo.current = this.realTimePreviewTaskInfo.next
            this.realTimePreviewTaskInfo.next = null
            this.executeRealTimePreviewTask()
          }
        })
      }
    },
    parseSVG(canvas) {
      const fillUrl = sessionStorage.getItem('fillUrl')
      const svg = canvas.toSVG()
      try {
        // 创建一个新的 DOMParser
        const parser = new DOMParser()
        // 解析 SVG 字符串并返回 Document 对象
        const doc = parser.parseFromString(svg, 'image/svg+xml')
        // 获取所有的 <image> 元素
        const images = Array.from(doc.querySelectorAll('image'))
        for (const image of images) {
          // 获取 xlink:href 属性的值
          const href = image.getAttribute('xlink:href')
          if (href == fillUrl) {
            const width = image.getAttribute('width')
            const height = image.getAttribute('height')
            image.setAttribute('width', Number(width) + 1)
            image.setAttribute('height', Number(height) + 1)
          }
          console.log('xlink:href:', image)
        }
        console.log('parser', doc.documentElement.outerHTML)
        return doc.documentElement.outerHTML
      } catch (error) {
        console.error('SVG 解析失败：', error)
      }
    },
    // 全部预览(点击右边那个预览才调用)
    async generatePreview() {
      this.generatePreviewLoading = true
      var requestData = {}
      // requestData.patternId = 589
      // requestData.blankProductId = 740
      // requestData.selectSkuIdList = [11878]
      requestData.patternId = this.patternInfo.id
      requestData.blankProductId = this.patternInfos[this.patterIndex].blankProductId
      // TODO 选中的sku列表,先设置为全部sku
      if (this.patterIndex < this.productModeNum) {
        requestData.selectSkuIdList = this.skuIds
      } else {
        requestData.selectSkuIdList = this.patternInfos[this.patterIndex].knifeSetInfoList[0].supportSkuIds
      }

      // for (const skuInfo of this.skuInfos) {
      //   requestData.selectSkuIdList.push(skuInfo.id)
      // }
      requestData.gear = this.gear
      // TODO 配件信息
      requestData.fittingGenerateList = null
      requestData.knifeSetInfo = {}
      requestData.knifeSetInfo.setId = this.knifeSetInfo.id
      var canvas = null
      if (this.patternInfo.type === blankProductPatternTypeEnum.GATHER.value) {
        // 直接拿当前画布去生成预览
        canvas = this.previewCollectCanvas
      } else if (this.patternInfo.type === blankProductPatternTypeEnum.MULTI_KNIFE.value) {
        // 先组合集合图画布，再拿集合图画布信息去生成预览
        canvas = this.preViewCanvas
      }
      if (!canvas) {
        return
      }
      requestData.knifeSetInfo.canvasWidth = canvas.width
      requestData.knifeSetInfo.canvasHeight = canvas.height
      requestData.knifeSetInfo.canvasJson = JSON.stringify(canvas)
      requestData.knifeSetInfo.canvasSvg = this.parseSVG(canvas)
      console.log('canvasSvg....', canvas.toSVG())
      requestData.knifeSetInfo.userImages = []
      var canvasObjects = canvas.getObjects()
      console.log('全部预览canvasObjects.....' + canvasObjects)
      for (let i = 0; i < canvasObjects.length; i++) {
        if (canvasObjects[i].fileUrl && canvasObjects[i].fileUrl != null) {
          requestData.knifeSetInfo.userImages.push(canvasObjects[i].fileUrl)
        }
      }
      var generatePreviewResponse = await generatePreviewV3(requestData)
      if (generatePreviewResponse.code === 200) {
        var customProductGeneratePreviewId = generatePreviewResponse.data.customProductGeneratePreviewId
        this.customProductGeneratePreviewId = customProductGeneratePreviewId
        for (;;) {
          await waitForMs(500)
          var generatePreviewStatusResponse = await generatePreviewStatus({ customProductGeneratePreviewId })
          if (generatePreviewStatusResponse.code === 200) {
            if (generatePreviewStatusResponse.data.status === 1) {
              // 生成进行中
              continue
            }
            if (generatePreviewStatusResponse.data.status === 2) {
              // 生成成功
              this.generatePreviewLoading = false
              this.previewImageUrls = generatePreviewStatusResponse.data.imageUrls || []
              break
            }
            if (generatePreviewStatusResponse.data.status === 3) {
              // 生成失败
              this.generatePreviewLoading = false
              this.$message.error('generate preview image fail, please try later')
              break
            }
          } else {
            this.$message.error('generate preview image fail, please try later')
          }
        }
      }
    },
    // 附件的生成
    async brandGenerate(previewCanvas) {
      console.log('previewCanvas.toSVG()....', previewCanvas.toSVG())
      console.log('this.brandList....', this.brandList)
      console.log('this.patternInfos....', this.patternInfos)
      const arr = []
      this.patternInfos.forEach(patterItem => {
        patterItem.knifeSetInfoList.forEach(setInfoItem => {
          setInfoItem.knifeInfoList.forEach(knifeInfoItem => {
            var requestData = {}
            if (this.patternInfos[this.patterIndex].id === patterItem.id) {
              requestData.id = patterItem.blankProductId
              requestData.patternId = patterItem.id
              requestData.blankProductId = patterItem.blankProductId
              requestData.selectSkuIdList = setInfoItem.supportSkuIds
              requestData.gear = this.gear
              requestData.fittingGenerateList = null
              requestData.knifeSetInfo = {}
              requestData.knifeSetInfo.setId = setInfoItem.id
              var canvas = null
              if (patterItem.type === blankProductPatternTypeEnum.GATHER.value) {
              // 直接拿当前画布去生成预览
                canvas = knifeInfoItem.canvas
              } else if (patterItem.type === blankProductPatternTypeEnum.MULTI_KNIFE.value) {
              // 先组合集合图画布，再拿集合图画布信息去生成预览
                canvas = previewCanvas
              }
              if (!canvas) {
                return
              }
              requestData.knifeSetInfo.canvasWidth = canvas.width
              requestData.knifeSetInfo.canvasHeight = canvas.height
              requestData.knifeSetInfo.canvasJson = JSON.stringify(canvas)
              requestData.knifeSetInfo.canvasSvg = canvas.toSVG()
              requestData.knifeSetInfo.userImages = []
              var canvasObjects = this.canvas.getObjects()
              // console.log('canvasObjects.....' + canvasObjects)
              for (let i = 0; i < canvasObjects.length; i++) {
                if (canvasObjects[i].fileUrl && canvasObjects[i].fileUrl != null) {
                  requestData.knifeSetInfo.userImages.push(canvasObjects[i].fileUrl)
                }
              }
              this.setBrandInfo(requestData)
            }
          })
        })
      })
      console.log('arr....', arr)
      // this.brandRequestDataList.forEach((item, index) => {
      //   console.log('item.id' + item.id + 'requestData.blankProductId...', requestData.blankProductId)
      //   if (item.id === requestData.blankProductId && requestData.blankProductId !== undefined) {
      //     this.brandRequestDataList[index] = requestData
      //   }
      // })
      // if (isChangeMode) {
      //   this.getMode(this.patternInfos[this.productIndex].type)
      //   this.patterIndex = this.productIndex
      // }
      console.log('保存brandRequestDataList....', this.brandRequestDataList)
    },
    // 设置附件信息
    setBrandInfo(requestData) {
      this.brandRequestDataList.forEach((item, index) => {
        console.log('item.id' + item.id + 'requestData.blankProductId...', requestData.blankProductId)
        if (item.id === requestData.blankProductId && requestData.blankProductId !== undefined) {
          this.brandRequestDataList[index] = requestData
        }
      })
    },
    brandGenerateSave(isChangeMode = true) {
      if (isChangeMode) {
        this.getMode(this.patternInfos[this.productIndex].type)
        this.patterIndex = this.productIndex
      }
    },
    // 实时预览
    async generate() {
      this.isSubmit = true
      var requestData = {}
      requestData.patternId = this.patternInfo.id
      requestData.blankProductId = this.productId
      // TODO 选中的sku列表,先设置为全部sku
      requestData.selectSkuIdList = []
      for (const skuInfo of this.skuInfos) {
        // requestData.selectSkuIdList.push(skuInfo.id)
        requestData.selectSkuIdList = this.skuIds
      }
      requestData.gear = this.gear
      // TODO 配件信息
      requestData.fittingGenerateList = []
      let num = 0
      this.brandRequestDataList.forEach((item, index) => {
        if (item.knifeSetInfo && item.knifeSetInfo.userImages.length > 0) {
          requestData.fittingGenerateList.push(item)
          num += 1
        }
      })

      if (num === 0) {
        requestData.fittingGenerateList = null
      }
      console.log('requestData.fittingGenerateList....', requestData.fittingGenerateList)
      requestData.knifeSetInfo = {}
      requestData.knifeSetInfo.setId = this.knifeSetInfo.id
      var canvas = null
      if (this.patternInfo.type === blankProductPatternTypeEnum.GATHER.value) {
        // 直接拿当前画布去生成预览
        canvas = this.previewCollectCanvas
      } else if (this.patternInfo.type === blankProductPatternTypeEnum.MULTI_KNIFE.value) {
        // 先组合集合图画布，再拿集合图画布信息去生成预览
        canvas = this.preViewCanvas
      }
      if (!canvas) {
        return
      }
      requestData.knifeSetInfo.canvasWidth = canvas.width
      requestData.knifeSetInfo.canvasHeight = canvas.height
      requestData.knifeSetInfo.canvasJson = JSON.stringify(canvas)
      requestData.knifeSetInfo.canvasSvg = this.parseSVG(canvas)
      requestData.knifeSetInfo.userImages = []
      var canvasObjects = this.canvas.getObjects()
      for (let i = 0; i < canvasObjects.length; i++) {
        if (canvasObjects[i].fileUrl && canvasObjects[i].fileUrl != null) {
          requestData.knifeSetInfo.userImages.push(canvasObjects[i].fileUrl)
        }
      }
      if (num == 0 && requestData.knifeSetInfo.userImages.length == 0) {
        this.isSubmit = false
        return
      }
      console.log('requestData....', requestData)
      var generateResponse = await productGenerateV3(requestData)
      if (generateResponse.code === 200) {
        var customProductGenerateId = generateResponse.data.customProductGenerateId
        for (;;) {
          await waitForMs(500)
          var generateStatusResponse = await generateStatus({ customProductGenerateId })
          if (generateStatusResponse.code === 200) {
            if (generateStatusResponse.data.status === 1) {
              // 生成进行中
              continue
            }
            if (generateStatusResponse.data.status === 2) {
              // 生成成功
              var customProductId = generateStatusResponse.data.customProductId
              this.callNewPage({
                path: '/mockups',
                query: {
                  customProductId: customProductId,
                  productId: this.productId
                }
              })
              break
            }
            if (generateStatusResponse.data.status === 3) {
              // 生成失败
              this.$message.error('generate preview image fail, please try later')
              this.isSubmit = false
              break
            }
          } else {
            this.$message.error('generate preview image fail, please try later')
            this.isSubmit = false
          }
        }
      }
    },
    // 提交配件定制信息
    async handleFittingsSubmit() {
      this.isSubmit = true
      var requestData = {}
      requestData.customProductId = this.customProductId
      requestData.fittingGenerateList = []
      var fittingData = {}
      fittingData.blankProductId = this.patternInfos[this.patterIndex].blankProductId
      fittingData.fittingGenerateList = null
      fittingData.gear = this.gear
      fittingData.patternId = this.patternInfo.id
      fittingData.selectSkuIdList = this.patternInfos[this.patterIndex].knifeSetInfoList[0].supportSkuIds
      fittingData.knifeSetInfo = {}
      fittingData.knifeSetInfo.setId = this.knifeSetInfo.id
      var canvas = null
      if (this.patternInfo.type === blankProductPatternTypeEnum.GATHER.value) {
        // 直接拿当前画布去生成预览
        canvas = this.previewCollectCanvas
      } else if (this.patternInfo.type === blankProductPatternTypeEnum.MULTI_KNIFE.value) {
        // 先组合集合图画布，再拿集合图画布信息去生成预览
        canvas = this.preViewCanvas
      }
      if (!canvas) {
        return
      }
      fittingData.knifeSetInfo.canvasWidth = canvas.width
      fittingData.knifeSetInfo.canvasHeight = canvas.height
      fittingData.knifeSetInfo.canvasJson = JSON.stringify(canvas)
      fittingData.knifeSetInfo.canvasSvg = this.parseSVG(canvas)
      fittingData.knifeSetInfo.userImages = []
      requestData.fittingGenerateList.push(fittingData)
      var canvasObjects = canvas.getObjects()
      for (let i = 0; i < canvasObjects.length; i++) {
        if (canvasObjects[i].fileUrl && canvasObjects[i].fileUrl != null) {
          fittingData.knifeSetInfo.userImages.push(canvasObjects[i].fileUrl)
        }
      }
      // this.patterIndex = this.productIndex
      var generateResponse = await fittingsSubmit(requestData)
      if (generateResponse.code === 200) {
        var customProductGenerateId = generateResponse.data.customProductGenerateId
        for (;;) {
          await waitForMs(500)
          var generateStatusResponse = await generateStatus({ customProductGenerateId })
          if (generateStatusResponse.code === 200) {
            if (generateStatusResponse.data.status === 1) {
              // 生成进行中
              continue
            }
            if (generateStatusResponse.data.status === 2) {
              // 生成成功
              var customProductId = generateStatusResponse.data.customProductId
              if (this.$route.query.fitId) {
                this.callNewPage({
                  path: '/mockups',
                  query: {
                    customProductId: customProductId,
                    productId: this.$route.query.fitId
                  }
                })
              } else {
                this.callNewPage({
                  path: '/mockups',
                  query: {
                    customProductId: customProductId,
                    productId: this.productId
                  }
                })
              }
              break
            }
            if (generateStatusResponse.data.status === 3) {
              // 生成失败
              this.$message.error('generate preview image fail, please try later')
              break
            }
          } else {
            this.$message.error('generate preview image fail, please try later')
          }
        }
      }
    },
    // 获取选中的sku
    async getSkuIds(arr) {
      // this.patternInfos = []
      // this.clearCanvas()
      this.skuIds = arr
      this.changeCanvas()
    },
    // 切换画布
    async changeCanvas() {
      setTimeout(() => {
        for (const item of this.patternInfos[this.patterIndex].knifeSetInfoList) {
          for (const items of item.supportSkuIds) {
            if (this.skuIds.includes(items)) {
              item.curr = true
              this.patternInfo = this.patternInfos[this.patterIndex]
              this.knifeSetInfo = item
              // if (item.knifeInfoList.length > 0) {
              //   console.log('item.knifeInfoList[0].....', item.knifeInfoList)
              //   item.knifeInfoList[0].curr = true
              // }
              for (const knifeItem of item.knifeInfoList) {
                if (knifeItem.curr) {
                  this.canvas = knifeItem.canvas
                  this.currKnifeInfo = knifeItem
                // this.setGroupImage()
                // await this.initCanvas(knifeItem, index, index2, index3)
                }
              }
              this.$forceUpdate()
            }
          }
        }
      }, 200)
    },
    // 清空画布
    clearCanvas() {
      const canvas = this.canvas
      var canvasOverlayImage = canvas.overlayImage
      this.canvas.clear()
      canvas.setOverlayImage(canvasOverlayImage)
      this.canvas.renderAll()
    },
    async getProductDetail() {
      this.isLoadSuccess = false
      const { data, code } = await productDetail({
        id: this.productId,
        blankProductInfo: true,
        showImageList: true, // 展示图片列表
        skuInfos: true,
        skuMap: true,
        blankPattern2: true
      })
      if (code === 200) {
        data.patternInfos2.forEach((patterItem, index) => {
          patterItem.blankProductId = data.id
        })
        this.blankProductInfo = data.blankProductInfo
        this.skuInfos = data.skuInfos
        this.skuMaps = data.skuMaps
        this.patternInfos = data.patternInfos2
        this.menuActived = 2
        this.realtimePreviewImageUrls = data.showImageList
        this.productModeNum = data.patternInfos2.length
        // 重新设计
        if (this.$route.query.type === 'fittings') {
          this.mode = 1
          this.patterIndex = 0
          this.productModeNum = 1
          this.menuActived = 1
          data.blankProductInfo.showImageList = data.showImageList
          data.blankProductInfo.blankProductId = data.id
          // 设置默认sku
          this.skuIds = data.patternInfos2[this.patterIndex].knifeSetInfoList[0].supportSkuIds
          this.brandList.push(data.blankProductInfo)
          data.patternInfos2.forEach((patterItem, index) => {
            patterItem.blankProductId = data.id
            if (patterItem.type === blankProductPatternTypeEnum.MULTI_KNIFE.value) {
              this.patternInfo = patterItem
              for (const setItem of patterItem.knifeSetInfoList) {
                this.knifeSetInfo = setItem
                setItem.knifeInfoList.forEach((knifeItem, index) => {
                  knifeItem.curr = false
                  if (index === 0) {
                    knifeItem.curr = true
                  }
                  if (knifeItem.curr) {
                    this.canvas = knifeItem.canvas
                    this.currKnifeInfo = knifeItem
                    this.$forceUpdate()
                    // this.changeCanvas()
                  }
                })
              }
            }
          })
        } else {
          for (const [index, item] of this.patternInfos.entries()) {
            if (item.isDefault) {
              this.mode = item.type
              this.patterIndex = index
              this.firstSkuList = item.knifeSetInfoList[0].supportSkuIds
            }
          }
          // 获取附件
          if (this.blankProductInfo.fittingsIds) {
            this.blankProductInfo.fittingsIds.forEach(async(item, index) => {
              await this.getBrandProductDetail(item).then(res => {
                // res.patternInfos2.forEach((patterItem, index) => {
                //   patterItem.blankProductId = data.id
                // })
                res.blankProductInfo.showImageList = res.showImageList
                res.blankProductInfo.blankProductId = res.id
                this.brandList.push(res.blankProductInfo)
                res.patternInfos2.forEach((patterItem, index) => {
                  patterItem.blankProductId = res.id
                  if (patterItem.type === blankProductPatternTypeEnum.MULTI_KNIFE.value) {
                    this.patternInfos.push(patterItem)
                  }
                })
              })
            })
          }
          // 初始化画布
        }
        setTimeout(async() => {
          for (const [index, item] of this.patternInfos.entries()) {
            for (const [index2, item2] of item.knifeSetInfoList.entries()) {
              for (const [index3, item3] of item2.knifeInfoList.entries()) {
                await this.initCanvas(item, item3, index, index2, index3)
              }
            }
          }
          setTimeout(() => {
            console.log('patternInfos[patterIndex].knifeSetInfoList....', this.patternInfos[this.patterIndex].knifeSetInfoList)
            this.changeKnifeSku(0)
            this.isLoadSuccess = true
            this.menuActived = 1
          }, 200)
        }, 800)
        // this.setGroupImage()
      }
    },
    // 初始化画布
    async initCanvas(productInfo, knifeInfo, index, index2, index3) {
      // 拿到画布相关信息
      return new Promise((resolve, reject) => {
        const canvas = new fabric.Canvas(`canvas${knifeInfo.id}`, {
          preserveObjectStacking: true,
          centeredScaling: true, // 设置缩放点在中心的位置
          centeredRotation: true
        })
        canvas.knifePlateImageDpiBadMax = knifeInfo.knifePlateImageDpiBadMax
        canvas.knifePlateImageDpiBadMin = knifeInfo.knifePlateImageDpiBadMin
        canvas.knifePlateImageDpiGoodMax = knifeInfo.knifePlateImageDpiGoodMax
        canvas.knifePlateImageDpiGoodMin = knifeInfo.knifePlateImageDpiGoodMin
        canvas.knifePlateImageDpiNormalMax = knifeInfo.knifePlateImageDpiNormalMax
        canvas.knifePlateImageDpiNormalMin = knifeInfo.knifePlateImageDpiNormalMin
        canvas.knifePlateImagePortraitInch = knifeInfo.knifePlateImagePortraitInch
        canvas.knifePlateImageTransverseInch = knifeInfo.knifePlateImageTransverseInch
        canvas.knifeId = knifeInfo.id
        // 拿到当前刀版
        const maskImageUrl = knifeInfo.knifePlateImageUrl
        console.log('maskImageUrl....', maskImageUrl)
        this.setOverlayImage(canvas, maskImageUrl).then((res) => {
          canvas.enableTextAndImage = knifeInfo.enableTextAndImage
          canvas.originalImageWidth = res.originalImageWidth
          canvas.originalImageHeight = res.originalImageHeight
          canvas.knifeToCanvasWidth = res.knifeToCanvasWidth
          canvas.knifeToCanvasHeight = res.knifeToCanvasHeight
          canvas.scaleX = res.scaleY
          canvas.scaleY = res.scaleY
          if (productInfo.isDefault && index <= this.productModeNum - 1) {
            if (index3 === 0) {
              // this.$set(this.patternInfos[index]['knifeSetInfoList'][this.knifeSetIndex]['knifeInfoList'][index3], 'curr', true)
              // this.patternInfo = this.patternInfos[index]
              // this.knifeSetInfo = this.patternInfos[index]['knifeSetInfoList'][this.knifeSetIndex]
              // this.canvas = this.patternInfos[index]['knifeSetInfoList'][this.knifeSetIndex]['knifeInfoList'][index3].canvas
              this.$set(this.patternInfos[index]['knifeSetInfoList'][index2]['knifeInfoList'][index3], 'curr', true)
              this.patternInfo = this.patternInfos[index]
              this.knifeSetInfo = this.patternInfos[index]['knifeSetInfoList'][index2]
              this.canvas = this.patternInfos[index]['knifeSetInfoList'][index2]['knifeInfoList'][index3].canvas
            }
            this.$forceUpdate()

            // setTimeout(() => {
            //   this.saveCanvasState()
            // }, 200)
          }
        })
        this.patternInfos[index]['knifeSetInfoList'][index2]['knifeInfoList'][index3]['canvas'] = canvas
        // 画布事件监听
        this.canvasEvent(canvas)
        resolve()
      })
    },
    // 画布事件监听
    canvasEvent(canvas) {
      canvas.on('object:modified', event => {
        // 处理对象移动事件
        var movedObject = event.target
        // 重绘重复背景
        if (movedObject.isbgImage) {
          this.setBackgroundRepeat()
        }
        this.saveCanvasState()
        this.countDPI(2)
      })
      canvas.on('mouse:dblclick', event => {
        // 处理对象双击事件
        var movedObject = event.target
        if (movedObject.type === 'text') {
          this.$refs.addText.open(event.target)
        }
      })
      canvas.on('object:moved', event => {
        // 处理对象移动事件
        var movedObject = event.target
        // 重绘重复背景
        if (movedObject.isbgImage) {
          this.setBackgroundRepeat()
        }
      })
      // canvas.on('object:scaling', event => {
      //   // 处理对象移动事件
      //   var movedObject = event.target
      //   console.log('对象正在缩放:', movedObject)
      // })
      canvas.on('object:removed', event => {
        // 记录操作过程
        this.saveCanvasState()
        // 处理双击事件，即删除对象
        // var clickedObject = event.target
        // 记录操作过程
      })
      canvas.on('object:added', event => {
        // 处理对象添加事件
        // 记录操作过程
        setTimeout(() => {
          this.saveCanvasState()
        }, 200)
        fabric.Object.prototype.setControlVisible('ml', false)
        fabric.Object.prototype.setControlVisible('mr', false)
        fabric.Object.prototype.setControlVisible('mt', false)
        fabric.Object.prototype.setControlVisible('mb', false)
      })
      // 监听滚轮事件
      canvas.on('mouse:wheel', opt => {
        if (this.isScale) {
          const delta = opt.e.deltaY // 滚轮向上滚一下是 -100，向下滚一下是 100
          let zoom = canvas.getZoom() // 获取画布当前缩放值
          zoom *= 0.999 ** delta
          if (zoom > 20) zoom = 20
          if (zoom < 0.01) zoom = 0.01
          this.zoom = parseInt(zoom * 100)
          canvas.setZoom(zoom)
          opt.e.preventDefault()
          opt.e.stopPropagation()
        }
      })
      canvas.on('mouse:down', opt => { // 鼠标按下时触发
        const param = {
          isShow: false,
          backgroundColor: ''
        }
        this.$bus.emit('showBackgroundSet', param)
        // const evt = opt.e
        const evt = this.canvas.getActiveObject()
        if (evt) {
          evt.transparentCorners = true // 选中时，角是被填充了。true 空心；false 实心
          evt.hasBorders = true // 选中时，是否显示边，默认true（显示）
          evt.borderColor = 'red' // 选中时，边为红色
          evt.borderScaleFactor = 2 // 选中时，边的粗细
          evt.cornerColor = '#ff0000' // 选中时，角的颜色是 红色
          evt.cornerStrokeColor = '#ff0000' // 选中时，角的边框的颜色是 粉色
          evt.cornerStyle = 'rect' // 选中时，叫的属性。默认rect 矩形；circle 圆形
          evt.cornerSize = 24 // 选中时，角的大小为20
          evt.padding = 0 // 选中时，选框离图形的距离
          evt.borderOpacityWhenMoving = 0.6 // 当对象活动和移动时，对象控制边界的不透明度,
        }
        // if (this.isMoveCanvas === true) { // 是否按住alt
        //   canvas.isDragging = true // isDragging 是自定义的
        //   canvas.lastPosX = evt.clientX // lastPosX 是自定义的
        //   canvas.lastPosY = evt.clientY // lastPosY 是自定义的
        // }
      })

      // canvas.on('mouse:move', opt => { // 鼠标移动时触发
      //   console.log('鼠标在移动...', opt)
      //   console.log(opt)
      //   if (canvas.isDragging) {
      //     const evt = opt.e
      //     const vpt = canvas.viewportTransform // 聚焦视图的转换
      //     vpt[4] += evt.clientX - canvas.lastPosX
      //     vpt[5] += evt.clientY - canvas.lastPosY
      //     canvas.requestRenderAll()
      //     canvas.lastPosX = evt.clientX
      //     canvas.lastPosY = evt.clientY
      //   }
      // })

      canvas.on('mouse:up', opt => { // 鼠标松开时触发
        // if (this.isMoveCanvas) {
        //   this.isMoveCanvas = false
        // }
        canvas.setViewportTransform(canvas.viewportTransform) // 设置此画布实例的视口转换
        canvas.isDragging = false
      })
      fabric.Object.NUM_FRACTION_DIGITS = 20
      canvas.renderAll()
    },
    handleChangeKnife(item, index) {
      console.log('切换刀版.....', item, index)
      const param = {
        item,
        index
      }
      this.changeKnife(param)
    },
    // 切换刀版
    changeKnife(obj) {
      this.isAllAreas = false

      this.currKnifeInfo = obj
      this.patternInfos[this.patterIndex].knifeSetInfoList.forEach((item, index) => {
        item.knifeInfoList.forEach((item2, index2) => {
          this.$set(this.patternInfos[this.patterIndex].knifeSetInfoList[index].knifeInfoList[index2], 'curr', false)
          if (item2.id === obj.item.id) {
            this.$set(this.patternInfos[this.patterIndex].knifeSetInfoList[index].knifeInfoList[index2], 'curr', true)
            this.canvas = item2.canvas
            this.currKnifeInfo = item2
          }
        })
      })
      // console.log('我切换了刀疤.....', this.patternInfos)
    },
    getObjectsLength(canvas) {
      if (canvas) {
        const objects = canvas.getObjects()
        return objects.length
      }
    },
    getCanvasBox(canvas) {
      let width = 0
      let height = 0
      let marginTop = 0
      let marginLeft = 0
      if (canvas.knifeToCanvasWidth) {
        marginLeft = -((canvas.knifeToCanvasWidth * (canvas.getZoom())) / 2) + 'px'
        width = canvas.knifeToCanvasWidth * canvas.getZoom()
      }
      if (canvas.knifeToCanvasHeight) {
        marginTop = -((canvas.knifeToCanvasHeight * (canvas.getZoom())) / 2) + 'px'
        height = canvas.knifeToCanvasHeight * canvas.getZoom()
      }
      const param = {
        width: width + 'px',
        height: height + 'px',
        marginTop: marginTop,
        marginLeft: marginLeft
      }
      console.log('canvas...', canvas.getZoom())
      return param
    },
    getKnifeToCanvasWidth(canvas) {
      if (canvas.knifeToCanvasWidth) {
        return canvas.knifeToCanvasWidth
      } else {
        return 600
      }
    },
    getKnifeToCanvasHeight(canvas) {
      if (canvas.knifeToCanvasHeight) {
        return canvas.knifeToCanvasHeight
      } else {
        return 500
      }
    },
    // setKnifeDpi() {
    //   this.patternInfos.forEach((patterItem, index) => {
    //     patterItem.knifeSetInfoList.forEach((setInfoItem, index2) => {
    //       setInfoItem.knifeInfoList.forEach((knifeItem, index3) => {
    //         let goodsNum = 0
    //         let isBad = 0
    //         const objects = knifeItem.canvas.getObjects()
    //         objects.forEach((objItem, index4) => {
    //           if (objItem.dpiClass === 'Goods') {
    //             goodsNum += 1
    //           }
    //           if (objItem.dpiClass === 'Bad') {
    //             isBad += 1
    //           }
    //         })
    //         if (goodsNum === objects.length && objects.length !== 0) {
    //           // this.patternInfos[index].knifeSetInfoList[index2].knifeInfoList[index3].knifeDpi = 'goods'
    //           this.$set(this.patternInfos[index].knifeSetInfoList[index2].knifeInfoList[index3], 'knifeDpi', 'Goods')
    //         }
    //         if (isBad >= 1) {
    //           // this.patternInfos[index].knifeSetInfoList[index2].knifeInfoList[index3].knifeDpi = 'bad'
    //           this.$set(this.patternInfos[index].knifeSetInfoList[index2].knifeInfoList[index3], 'knifeDpi', 'Bad')
    //         }
    //         if (objects.length === 0) {
    //           // this.patternInfos[index].knifeSetInfoList[index2].knifeInfoList[index3].knifeDpi = 'none'
    //           this.$set(this.patternInfos[index].knifeSetInfoList[index2].knifeInfoList[index3], 'knifeDpi', 'None')
    //         }
    //       })
    //     })
    //   })
    // },
    // 获取OSS信息
    async getOssMsg() {
      const { data } = await fileSts()
      this.ossData = data
    },
    // 移动画布
    handMoveCanvas(val) {
      this.isMoveCanvas = val
    },
    // 居中
    alignCenter(obj) {
      obj.top = obj.top + 0.111 * this.canvasSize.height / 2 - obj.height * obj.scaleY / 2
      obj.left = obj.left + 0.218 * this.canvasSize.width / 2 - obj.width * obj.scaleX / 2
    },
    changeMenu(val) {
      this.menuActived = val
      setTimeout(() => {
        const { canvas } = this
        canvas.renderAll()
        this.$forceUpdate()
      }, 100)
    },
    // 切换模式
    getMode(val) {
      if (JSON.stringify(this.preViewCanvas) !== '{}') { this.preViewCanvas.clear() }
      // this.clearCanvas()
      // 因为附件没有模式选择，所以此处使用mode就行
      this.mode = val
      this.canvasState = []
      // this.changeCanvas()
      // 设置默认第一个选中
      this.patternInfos.forEach((patterItem, patterIndex) => {
        if (patterItem.type === val && patterIndex < this.productModeNum) {
          this.patternInfo = patterItem
          this.patterIndex = patterIndex
          // this.skuIds = this.$refs.sku.getIds()
          for (const item of patterItem.knifeSetInfoList) {
            this.knifeSetInfo = item
            for (const items of item.supportSkuIds) {
              if (this.skuIds.includes(items)) {
                item.knifeInfoList.forEach((knifeItem, index) => {
                  knifeItem.curr = false
                  if (index === 0) {
                    knifeItem.curr = true
                  }
                  if (knifeItem.curr) {
                    this.canvas = knifeItem.canvas
                    this.currKnifeInfo = knifeItem
                    this.$forceUpdate()
                    // this.changeCanvas()
                  }
                  this.onChangeCanvas()
                })
              }
            }
          }
        }
      })
    },
    async isShowKnife(arr) {
      let isTrue = false
      for (const item of this.skuIds) {
        if (arr.includes(item)) {
          isTrue = true
          break
        }
      }
      return isTrue
    },
    onZoom(value) {
      this.zoom = value
      // this.canvas.setZoom(value / 100)
    },
    showAddImg() {
      this.changeMenu(1)
    },
    clickAllAreas() {
      if (this.isAllAreas === true) {
        this.menuActived = 1
        this.isAllAreas = false
      } else {
        this.menuActived = 1
        this.isAllAreas = true
      }
      this.setGroupImage()
    },
    downPsd() {
      window.open(this.knifeSetInfo.psdFileUrl, '_blank')
    },
    onTipsOpen() {
      this.isTips = true
    },
    onTipsClose() {
      this.isTips = false
    }
  }
}
</script>

<style scoped lang="scss">
.example {
  text-align: center;
  border-radius: 4px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  z-index: 100;
}
.canvas-box {
  background-image: url("../../assets/img/bg.svg");
  background-repeat: repeat;
  background-size: 4%;
  position: absolute;
  top: 50%;
  left: 50%;
}
.knife {
  // position: absolute;
  // top: 28px;
  // left: 40px;
  margin-left: 14px;
  &-title {
    padding:2px 10px;
    background: #E7E7E7;
    border-radius: 4px;
    text-align: center;
    line-height: 30px;
    margin-top: 28px;
    margin-left: 10px;
    cursor: pointer;
    &:hover{
      color: white;
      background: #333333;
    }
    &.actived{
      color: white;
      background: #333333;
    }
  }
  .knife-con {
    margin-top: 20px;
    height: 552px;
    overflow-y: scroll;
    overflow: auto; /* 显示滚动条 */
    direction: rtl; /* 设置文本流从右向左 */
    .knife-scroll {
      direction: ltr;
      .knife-list {
        margin-bottom: 30px;
        text-align: center;
        position: relative;
        &-img{
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin: 0 auto;
          border: 1px solid #909090;
          border-radius: 8px 8px 8px 8px;
          &.actived{
            border: 2px solid #000;
            border-radius: 8px 8px 8px 8px;
          }
        }
        img {
          width: 50px;
          height: 50px;
        }
        .zhiliang {
          position: absolute;
          bottom: 3px;
          right: 3px;
          .Good {
            width: 11px;
            height: 11px;
            background: #52c41a;
            display: inline-block;
            border-radius: 20px;
          }
          .Normal {
            width: 11px;
            height: 11px;
            background: #e7e7e7;
            display: inline-block;
            border-radius: 20px;
          }
          .Bad {
            width: 16px;
            height: 16px;
          }
        }
        p {
          margin-top: 8px;
          font-size: 17px;
        }
      }
    }
  }
}
.preview-design{
  width: 156px;
  height: 40px;
  background: #EEEEEE;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  top: 30px;
  right: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  span{
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('../../assets/images/custom/zhengyan2.png') no-repeat;
    background-size: 100%;
    margin-left: 12px;
    margin-right: 10px;
  }
  &.actived{
    background: #333 !important;
    color: white;
    span{
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url('../../assets/images/custom/zhengyan.png') no-repeat;
      background-size: 100%;
    }
  }
  &:hover {
    background: #333 !important;
    color: white;
    span{
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url('../../assets/images/custom/zhengyan.png') no-repeat;
      background-size: 100%;
    }
  }
}
.custom {
  background-color: white;
  .adimg{
    position: absolute;
    text-align: center;
    width: 188px;
    height: 188px;
    background: url(../../assets/images/custom/adimg.png) no-repeat;
    background-size: 100% 100%;
    top: 50%;
    left: 50%;
    margin-top: -99px;
    margin-left: -99px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    z-index: 100;
    cursor: pointer;
    &:hover{
      background: url(../../assets/images/custom/adimg-hover.png) no-repeat;
      background-size: 100% 100%;
    }
    .adimg-con{
      padding: 10px;
      flex-wrap: wrap;
      word-break: keep-all;
      p{
        margin: 0;
      }
    }
  }
  &-header {
    height: 79px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    &-logo {
      padding-left: 19px;
      img {
        width: 162px;
        height: 66px;
      }
    }
    &-opera {
      margin-left: auto;
      display: flex;
      align-items: center;
      &-back {
        padding: 12px 20px;
        cursor: pointer;
        &:hover{
          background: #F5F5F5;
          border-radius: 4px;
        }
        img {
          width: 22px;
          height: 13px;
          cursor: pointer;
        }
        span {
          margin-left: 10px;
          color: #444444;
          font-size: 14px;
        }
      }
      &-btn {
        width: 128px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
      }
      &-branding {
        border-radius: 4px;
        border: 1px solid #ff5347;
        color: #ff5347;
      }
      &-submit {
        background: #ff5347;
        border-radius: 4px;
        font-weight: bold;
        margin-left: 16px;
        color: white;
        margin-right: 30px;
      }
      &-submit2 {
        background: rgb(255, 83, 71,0.5);
        border-radius: 4px;
        font-weight: bold;
        margin-left: 16px;
        color: white;
        margin-right: 30px;
      }
    }
  }
  .brand-head{
    width: 100%;
    height: 79px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    .brand-head-left{
      padding-left: 20px;
      font-size: 22px;
      color: #333;
    }
    .brand-head-right{
      margin-left: auto;
      width: 250px;
      display: flex;
      margin-right: 25px;
      &-cancel{
        width: 128px;
        height: 45px;
        // width: 97px;
        // height: 38px;
        line-height: 45px;
        text-align: center;
        font-size: 18px;
        border-radius: 4px 4px 4px 4px;
        cursor: pointer;
        &:hover{
          background: #F5F5F5;
        }
      }
      &-submit{
        width: 128px;
        height: 45px;
        // width: 97px;
        // height: 38px;
        line-height: 45px;
        text-align: center;
        background: #FF5347;
        border-radius: 4px 4px 4px 4px;
        color: white;
        // font-size: 14px;
        font-size: 18px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
    .brand-head-right2{
      width: 380px;
      justify-content: right;
    }
  }
  &-main {
    display: flex;
    .fun {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 64px;
      background-color: #f7f7f7;
      height: calc(100vh - 79px);
      &-img {
        display: block;
        margin: 0 auto;
        cursor: pointer;
        img {
          width: 48px;
          height: 48px;
        }
      }
      .psd{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        cursor: pointer;
        span{
          display: inline-block;
          width: 68px;
          height: 68px;
          background: url(../../assets/images/custom/pad.png) no-repeat;
          background-size: 100% 100%;
        }
        span:hover{
          display: inline-block;
          width: 68px;
          height: 68px;
          background: url(../../assets/images/custom/psds.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .image-contral {
      border-top: 0;
    }
    .canvas-main {
      flex: 1;
      position: relative;
      height: calc(100vh - 79px);
      background-color: #f7f7f7;
      .canvas-con {
        position: relative;
        height: calc(100vh - 183px);
        display: flex;
      }
    }
  }
  .canvas-priview {
    width: 322px;
  }
  .skus {
    border-top: 0;

  }
  .sku-title {
      color: #333;
      display: flex;
      align-items: center;
      width: 353px;
      height: 65px;
      font-size: 18px;
      padding: 11px 40px 11px 19px;
      box-sizing: border-box;
      font-weight: bold;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
}
</style>
