<template>
  <div>
    <mask-dialog :show="addImageIsShowDialog">
      <!--  :id="addImageIsShowDialog ? 'danRu' : 'danChu'" -->
      <div ref="addImage" class="add-image" @click="handleModalClick">
        <div class="add-image-title dis-flex-align-center">
          <span>Design library</span>
          <div class="add-image-title-close cur">
            <a-icon type="close" size="50" @click="addImageIsShowDialog = false" />
          </div>
        </div>
        <div class="dis-flex modal-con">
          <div class="modal-left">
            <div class="up-title" :class="tab == 1 ? 'actived' : ''" @click="handleTags(1)">Upload image</div>
            <div class="up-title" :class="tab == 2 ? 'actived' : ''" @click="handleTags(2)">All Tags</div>
            <div class="up-title" :class="tab == 33 ? 'actived' : ''" @click="handleTags(3)">No Tags</div>
          </div>
          <div class="modal-right m20">
            <div class="modal-contain">
              <div class="dis-flex">
                <div class="modal-right-search">
                  <!-- <img src="@/assets/images/custom/sousuo.png" alt=""> -->
                  <span />
                  <input v-model="page.fileName" type="text" placeholder="Search">
                </div>
                <div class="ml-auto dis-flex" style="margin-right:12px">
                  <a-upload class="upload-demo" :show-upload-list="false" :custom-request="uploadImage" accept=".jpg, .jpeg, .png" action>
                    <div class="modal-right-upload">
                      Upload image
                    </div>
                  </a-upload>
                </div>
              </div>
              <!-- upladImages -->
              <div v-if="tab == 1" class="uploadImages">
                <div class="folders">
                  <div class="title">
                    Common Tags
                  </div>
                  <div class="folders-con">
                    <div v-for="(item,index) in tagList" :key="index">
                      <template v-if="index < 11">
                        <div v-if="!item.edit" class="tag" style="margin-top: 16px" :style="item.edit ? 'padding: 0;border: 0' : ''" @contextmenu.prevent="handleRightClick(index)" @click="handleTag(item.tag)">
                          <span class="tag-name">{{ item.tag }}</span>
                          <span class="tag-num">{{ item.num }}</span>
                          <div v-if="tagIndex === index" class="tag-opera">
                            <div class="tag-opera-li" @click.stop="handleReName(index)">Rename</div>
                            <div class="tag-opera-li" @click.stop="handleRemoveTag(item)">Delete</div>
                          </div>
                        </div>
                        <span v-else :ref="'editableSpanAll'+index" style="border: 1px solid #FF564A;margin-top: 16px" class="tag" contenteditable="true" @input="(event) => handleTagInput(event,index)" @keyup.enter="handleTagEnter(event,index)" @blur="(event) => handleTagBlur(event,index)" v-text="item.tag" />
                      </template>
                    </div>
                  </div>
                </div>
                <div class="image mt30">
                  <div class="title">
                    Image
                  </div>
                  <div class="image-con mt20">
                    <tag-image :image-list="imageList" :canvas2="canvas" :is-loading-img2="isLoadingImg" @edit="openEditImageDialog" @close="handClose" />
                  </div>
                </div>
              </div>
              <!-- All tags -->
              <div v-if="tab == 2" class="allTags">
                <div class="tag-common">
                  <img src="@/assets/images/custom/shijian.png" alt="">
                  <span>Common Tags</span>
                </div>
                <div class="folders-con">
                  <div v-for="(item,index) in commonTagList" :key="index" style="margin-bottom: 16px">
                    <div v-if="!item.edit" class="tag" style="display: inline-block;" :style="item.edit ? 'padding: 0;border: 0' : ''" @contextmenu.prevent="handleRightClick(index)" @click="handleTag(item.tag)">
                      <span class="tag-name">{{ item.tag }}</span>
                      <span class="tag-num">{{ item.num }}</span>
                      <div v-if="tagIndex === index" class="tag-opera">
                        <div class="tag-opera-li" @click.stop="handleReName3(index)">Rename</div>
                        <div class="tag-opera-li" @click.stop="handleRemoveTag3(item)">Delete</div>
                      </div>
                    </div>
                    <span v-else :ref="'editableSpancommon'+index" style="border: 1px solid #FF564A;display: inline-block;" class="tag" contenteditable="true" @keyup.enter="(event) => handleTagEnter3(event,index)" @blur="(event) => handleTagBlur3(event,index)">{{ item.tag }}</span>
                  </div>
                </div>
                <div class="tag-scroll" style="height: 480px">
                  <div v-for="(value, key) in indexList" :key="key">
                    <a v-if="value.length > 0" :id="key" class="tag-title">{{ key }}</a>
                    <div class="folders-con">
                      <div v-for="(item,index) in value" :key="index">
                        <div v-if="!item.edit" class="tag" style="display: inline-block;margin-bottom: 10px" :style="item.edit ? 'padding: 0;border: 0' : ''" @contextmenu.prevent="handleAZRightClick(index,key)" @click="handleTag(item.tag)">
                          <span class="tag-name">{{ item.tag }}</span>
                          <span class="tag-num">{{ item.num }}</span>
                          <div v-if="tagAZIndex === index && tagAZKey === key" class="tag-opera">
                            <div class="tag-opera-li" @click.stop="handleReName2(key,index)">Rename</div>
                            <div class="tag-opera-li" @click.stop="handleRemoveTag2(key,item)">Delete</div>
                          </div>
                        </div>
                        <span v-else :ref="'editableSpan'+index" style="border: 1px solid #FF564A;display: inline-block;" class="tag" contenteditable="true" @keyup.enter="(event) => handleTagEnter3(event,key,index)" @blur="(event) => handleTagBlur2(event,key, index)">{{ item.tag }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="az">
                    <a v-for="(item,index) in AZList" :key="index" class="az-li" :href="'#' + item">{{ item }}</a>
                  </div>
                </div>
              </div>
              <div v-if="tab == 22 || tab == 33" class="image-con" style="height: 606px">
                <div v-if="tab == 22 || tab == 33" class="tag-img">
                  <div v-if="tab == 22" class="tag-img-title"><span @click="handleTags(1)">All Tags</span>/{{ page.tag }}({{ imageList.length }})</div>
                  <tag-image :image-list="imageList" :canvas2="canvas" :is-loading-img2="isLoadingImg" @edit="openEditImageDialog" @close="handClose" />
                </div>
              </div>
            </div>
            <div class="pages dis-flex-align-center">
              <a-pagination v-model="page.current" :total="page.total" :page-size="page.size" @showSizeChange="onShowSizeChange" @change="pageChange" />
            </div>
          </div>
        </div>
      </div>
    </mask-dialog>
    <edit-image-dialog ref="editImageDialog" />
  </div>
</template>
<script>
import maskDialog from '../../components/mask.vue'
import { getFileSize } from '@/utils/util.js'
import OSS from 'ali-oss'
import uuid from 'licia/uuid'
import { submitOssFile2, filePage, tagList, tagRemove, tagRename } from '@/api/file'
import tagImage from './tag_image'
import editImageDialog from './edit_image.vue'
export default {
  name: 'AddImage',
  components: {
    maskDialog,
    tagImage,
    editImageDialog
  },
  props: {
    canvas2: {
      type: Object,
      default: () => ({})
    },
    ossData: {
      type: Object
    },
    saveCanvasState: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isLoadingImg: false,
      canvas: {},
      tab: 1,
      // 弹窗
      addImageIsShowDialog: false,
      // 分页
      page: {
        current: 1,
        total: 50,
        size: 40,
        fileType: 1,
        tagStatus: null,
        tag: null,
        fileName: ''
      },
      tagName: '',
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      imageList: [],
      tagList: [],
      indexList: [],
      commonTagList: [],
      AZList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '#'],
      tagIndex: '',
      tagAZIndex: '',
      tagAZKey: ''
    }
  },
  watch: {
    canvas2(newValue, oldValue) {
      this.canvas = newValue
    },
    'page.fileName': {
      handler(newValue, oldValue) {
        this.imageList = []
        this.getImageList()
      }
    }
    // 'page.current': {

    // }
  },
  created() {
    if (this.canvas2) {
      this.canvas = this.canvas2
    }
  },
  mounted() {
    this.getImageList()
    this.getTagList()
  },
  methods: {
    pageChange(current) {
      this.imageList = []
      this.page.current = current
      this.getImageList()
    },
    handleModalClick() {
      console.log('1111...')
      this.tagIndex = ''
      this.tagAZIndex = ''
    },
    handleAZRightClick(index, key) {
      this.tagAZIndex = index
      this.tagAZKey = key
    },
    handleRightClick(index) {
      this.tagIndex = index
    },
    // 标签重命名
    handleReName(index) {
      this.tagName = this.tagList[index].tag
      this.$set(this.tagList[index], 'edit', true)
      setTimeout(() => {
        this.$refs['editableSpanAll' + index][0].focus()
      }, 100)
    },
    async handleTagBlur(e, index) {
      this.$set(this.tagList[index], 'edit', false)
      const { code } = await tagRename({
        oldTag: this.tagList[index].tag,
        newTag: e.target.innerText
      })
      if (code === 200) {
        this.$set(this.tagList[index], 'tag', e.target.innerText)
      }
    },
    handleTagEnter(e, index) {
      if (e.key === 'Enter') {
        e.preventDefault()
        this.$set(this.tagList[index], 'edit', false)
      }
    },
    // 删除标签
    async handleRemoveTag(item) {
      const { code } = await tagRemove({ tag: item.tag })
      if (code === 200) {
        this.$message.success('Success')
        this.tagIndex = ''
        this.getTagList()
      }
    },
    // 标签重命名
    handleReName2(key, index) {
      this.$set(this.indexList[key][index], 'edit', true)
      console.log(this.indexList[key])
      setTimeout(() => {
        this.$refs['editableSpan' + index][0].focus()
      }, 100)
    },
    async handleTagBlur2(e, key, index) {
      this.$set(this.indexList[key][index], 'edit', false)
      const { code } = await tagRename({
        oldTag: this.indexList[key][index].tag,
        newTag: e.target.innerText
      })
      if (code === 200) {
        this.$set(this.indexList[key][index], 'tag', e.target.innerText)
      }
    },
    handleTagEnter2(e, key, index) {
      if (e.key === 'Enter') {
        e.preventDefault()
        this.$set(this.indexList[key][index], 'edit', false)
      }
      // 暂时没接口
    },
    // 删除标签
    async handleRemoveTag2(item) {
      const { code } = await tagRemove({ tag: item.tag })
      if (code === 200) {
        this.getTagList()
      }
    },
    // 标签重命名
    handleReName3(index) {
      this.$set(this.commonTagList[index], 'edit', true)
      setTimeout(() => {
        this.$refs['editableSpancommon' + index][0].focus()
      }, 100)
    },
    async handleTagBlur3(e, index) {
      this.$set(this.commonTagList[index], 'edit', false)
      const { code } = await tagRename({
        oldTag: this.commonTagList[index].tag,
        newTag: e.target.innerText
      })
      if (code === 200) {
        this.$set(this.commonTagList[index], 'tag', e.target.innerText)
      }
    },
    handleTagEnter3(index) {
      this.$set(this.commonTagList[index], 'edit', false)
      // 暂时没接口
    },
    // 删除标签
    async handleRemoveTag3(item) {
      const { code } = await tagRemove({ tag: item.tag })
      if (code === 200) {
        this.getTagList()
      }
    },
    handleTag(name) {
      this.imageList = []
      this.page.tag = name
      this.page.current = 1
      this.page.tagStatus = 2
      this.tab = 22
      this.getImageList()
    },
    handleTags(data) {
      this.imageList = []
      this.page.tag = null
      this.page.current = 1
      if (data === 1) {
        this.page.tagStatus = null
        this.tab = 1
        this.page.tag = null
        this.getImageList()
      }
      if (data === 2) {
        this.page.tagStatus = null
        this.tab = 2
        this.page.tag = null
        this.getImageList()
      }
      if (data === 3) {
        this.page.tagStatus = 1
        this.tab = 33
        this.getImageList()
      }
    },
    handClose() {
      this.tab = 1
      this.addImageIsShowDialog = false
      this.$emit('showAddImg')
    },
    // 打开编辑图片弹窗
    openEditImageDialog(param) {
      this.$refs.editImageDialog.open(param)
    },
    // 上传文件
    async uploadImage(option) {
      if (option.file.size > 80 * 1024 * 1024) {
        this.$notification.error({ message: 'Maximum file size 80 MB' })
        return
      }

      try {
        this.isLoadingImg = true
        const fileSize = getFileSize(option.file.size)
        const client = new OSS({
          endpoint: this.ossData.ossEndpoint,
          accessKeyId: this.ossData.accessKeyId,
          accessKeySecret: this.ossData.accessKeySecret,
          bucket: this.ossData.bucket,
          stsToken: this.ossData.securityToken
        })

        const file = option.file
        const point = file.name.lastIndexOf('.')
        const suffix = file.name.substr(point)
        const fileName = file.name.substr(0, point)
        const uuidValue = uuid()
        const fileNames = `${this.ossData.ossObjectNamePrefix}${uuidValue}${suffix}`

        const res = await client.multipartUpload(fileNames, file, {
          progress: async function(p) {
            console.log(p)
            console.log('分片。。。。。')
          },
          headers: {
            'Cache-Control': 'public, max-age=360000'
          }
        })

        if (res.res.statusCode === 200) {
          const imageUrl = this.ossData.domain + '/' + fileNames
          const imageInfo = await this.processImage(file)
          const { width, height } = imageInfo

          await this.submitFile(
            fileSize,
            fileName,
            width,
            height,
            imageUrl,
            res.bucket,
            res.name
          )
        } else {
          this.$message.error('Image upload failed due to unstable network')
        }
      } catch (error) {
        this.$message.error('Image upload failed due to unstable network')
      }
    },
    // 读取图片信息
    async processImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = function(evt) {
          const image = new Image()
          image.onload = function() {
            resolve({
              width: image.width,
              height: image.height,
              imageUrl: evt.target.result
            })
          }
          image.onerror = function(error) {
            reject(error)
          }
          image.src = evt.target.result
        }
        reader.onerror = function(error) {
          reject(error)
        }
        reader.readAsDataURL(file)
      })
    },
    async submitFile(
      fileSize,
      fileName,
      width,
      height,
      fileUrl,
      bucketName,
      objectName
    ) {
      const { code } = await submitOssFile2({
        fileSize: fileSize,
        fileName: fileName,
        fileType: 1,
        width: width,
        height: height,
        fileUrl: fileUrl,
        bucketName: bucketName,
        objectName: objectName,
        tags: null
      })

      if (code === 200) {
        this.isLoadingImg = false
        this.$emit('update:loading', false)
        this.resetImagePage()
      }
    },
    resetImagePage() {
      this.page.current = 1
      this.imageList = []
      this.getImageList()
    },
    // 文件列表（分页）
    async getImageList() {
      const { data, code } = await filePage({
        current: this.page.current,
        size: this.page.size,
        fileType: this.page.fileType,
        tag: this.page.tag,
        tagStatus: this.page.tagStatus,
        fileName: this.page.fileName
      })
      if (code === 200 && data.records) {
        this.imageList.push(...data.records)
        this.page.total = data.total
      }
    },
    // 标签列表
    async getTagList() {
      this.commonTagList = []
      this.tagList = []
      this.indexList = []
      const { data, code, msg } = await tagList({
      })
      if (code === 200) {
        this.tagList = data
        this.getIndeList(data)
        this.getComonTag(data)
      } else {
        this.$message.error(msg)
      }
    },
    getComonTag(arr) {
      arr.forEach((item, index) => {
        if (index <= 10) {
          this.commonTagList.push(item)
        }
      })
    },
    getIndeList(arr) {
      const obj = {}
      const arr2 = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '#']

      // 初始化 obj 中每个字母对应的数组为空数组
      arr2.forEach(letter => {
        obj[letter] = []
      })

      arr.forEach(item => {
        const firstLetter = item.tag.charAt(0).toUpperCase()

        // 根据首字母将元素分组到对应的数组中
        if (arr2.includes(firstLetter)) {
          obj[firstLetter].push(item)
        } else {
          obj['#'].push(item)
        }
      })

      console.log('obj...', JSON.stringify(obj))
      this.indexList = obj
    },
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize
    }
  }
}
</script>
<style>
.add-image .ant-pagination-item.ant-pagination-item-active{
  background-color: white !important;
  color: #FF5347 !important;
}
.add-image .ant-pagination-item-active{
  background-color: white !important;
  color: #FF5347 !important;
}
.add-image .ant-pagination-item:hover{
  border-color: #FF5347 !important;
  color: #FF5347 !important;
}
.add-image .ant-pagination-item a:hover{
  color: #FF5347 !important;
}
.add-image .ant-pagination-item-active a, .ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a{
  color: #FF5347 !important;
}
.add-image .ant-pagination-prev .ant-pagination-item-link:hover, .ant-pagination-next .ant-pagination-item-link:hover{
  border-color: #FF5347 !important;
  color: #FF5347 !important;
}
.add-image .ant-pagination-item-active{
  border-color: #FF5347;
}
</style>
<style lang="scss" scoped>
.modal-contain{
  height: calc(800px - 140px);
}
.folders-tooltip .ant-tooltip-inner {
  background-color: white;
}
.folders-tooltip .ant-tooltip-arrow::before {
  background-color: white;
}
.folders-tooltip .folders-opera-list {
  color: #ff9a40;
  height: 39px;
  line-height: 39px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.folders-tooltip .folders-opera-list:last-child {
  color: #ff5347;
}

.ant-pagination-item{
  min-width: 25px;
  width: 25px;
}

</style>
<style scoped lang="scss">

#danRu{
-webkit-animation:bounceIn 1s .2s ease both;
-moz-animation:bounceIn 1s .2s ease both;}
@-webkit-keyframes bounceIn{
0%{opacity:0;
-webkit-transform:scale(.3)}
50%{opacity:1;
-webkit-transform:scale(1.05)}
70%{-webkit-transform:scale(.9)}
100%{-webkit-transform:scale(1)}
}
@-moz-keyframes bounceIn{
0%{opacity:0;
-moz-transform:scale(.3)}
50%{opacity:1;
-moz-transform:scale(1.05)}
70%{-moz-transform:scale(.9)}
100%{-moz-transform:scale(1)}
}
#danChu{
-webkit-animation:fadeInDown 1s .2s ease both;
-moz-animation:fadeInDown 1s .2s ease both;}
@-webkit-keyframes fadeInDown{
0%{opacity:0;
-webkit-transform:translateY(-20px)}
100%{opacity:1;
-webkit-transform:translateY(0)}
}
@-moz-keyframes fadeInDown{
0%{opacity:0;
-moz-transform:translateY(-20px)}
100%{opacity:1;
-moz-transform:translateY(0)}
}
.add-image {
  width: 1152px;
  height: 800px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -576px;
  margin-top: -400px;
  &-title {
    height: 65px;
    width: 100%;
    border-bottom: 1px solid #cbcbcb;
    display: flex;
    align-items: center;
    padding: 0 35px;
    box-sizing: border-box;
    font-size: 20px;
    color: #333;
    span {
      font-size: 22px;
      font-weight: medium;
    }
    &-close {
      margin-left: auto;
      font-size: 18px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        background-color: #ddd;
      }
    }
  }
}
.modal-con{
  height: calc(800px - 65px);
}
.modal-left {
  padding-left: 40px;
  width: 213px;
  border-right: 1px solid #cbcbcb;
}
.up-title {
  height: 50px;
  padding-top: 16px;
  font-weight: Medium;
  font-size: 18px;
  color: #333;
  cursor: pointer;
  &.actived{
    color: #ff5347;
  }
}
.modal-right {
  position: relative;
  margin-bottom: 0 !important;
  flex: 1;
  .modal-contain{
    overflow: hidden;
    overflow-y: scroll;
  }
  &-search {
    width: 233px;
    height: 46px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 2px solid #bababa;
    display: flex;
    &:hover{
      border: 2px solid #333;
      color: #000;
      span{
        display: inline-block;
        width: 42px;
        height: 42px;
        background: url('../../../../assets/images/custom/souSuo2.png') no-repeat;
        background-size: 100%;
      }
      input::placeholder{
        color: #000;
      }
    }
    span {
      width: 42px;
      height: 42px;
      background: url('../../../../assets/images/custom/sousuo.png') no-repeat;
      background-size: 100%;
    }
    input {
      width: 160px;
      height: 41px;
      outline: none;
      font-size: 16px;
    }
  }
  &-wjj {
    width: 55px;
    height: 46px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 2px solid #bababa;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 27px;
      height: 24px;
    }
  }
  &-upload {
    width: 181px;
    height: 46px;
    background: #ff5347;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    color: white;
    text-align: center;
    line-height: 46px;
    margin-left: 16px;
    font-size: 18px;
    cursor: pointer;
  }
  .allTags{
    .tag-scroll{
      height: 530px;
      // overflow-y: scroll;
      position: relative;
      margin-top: 10px;
    }
    .tag-common{
      margin-top: 31px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      img{
        width: 16px;
        height: 16px;
      }
      span{
        color: #6F6F6F;
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}
.title {
  font-size: 18px;
  line-height: 18px;
  font-weight: medium;
  color: #333;
}

.folders {
  margin-top: 30px;
  &-opera {
    width: 109px;
    height: 127px;
    background: #ffffff;
  }
  &-con {
    display: flex;
    flex-wrap: wrap;
    margin-right: 8px;
  }
}
.az{
  position: fixed;
  left: 50%;
  top: calc(100vh - 62vh);
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-left: 512px;
  a{
    color: #333;
    font-size: 12px;
    line-height: 17px;
  }
}
.tag-opera{
  // display: none;
  position: absolute;
  bottom: -60px;
  right: -69px;
  background-color: white;
  box-shadow: 0 0 10px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  .tag-opera-li{
    width: 88px;
    height: 36px;
    line-height: 36px;
    text-align: center;
  }
  .tag-opera-li:nth-child(1){
    color: #2260B5;
  }
  .tag-opera-li:nth-child(2){
    color: #FF5347;
  }
}
.tag-title{
  display: block;
  color: #6E6E6E;
  font-size: 14px;
  margin-bottom: 8px;
}
.tag{
  position: relative;
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid #BFBFBF;
  color: #4D4D4D;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 20px;
  &-num{
    margin-left: 13px;
  }
  // &:hover .tag-opera{
  //   display: block;
  // }
  &:focus{
    outline: none;
    border: 1px solid rgb(255, 86, 74)
  }
  // &:hover .tag-close{
  //   display: block;
  // }
  // &:hover .tag-num{
  //   display: none;
  // }
  // &-close{
  //   display: none;
  //   margin-left: 13px;
  //   img{
  //     width: 14px;
  //     height: 14px;
  //   }
  // }
}
.tag-img{
  margin-top: 30px;
  &-title{
    font-size: 18px;
    margin-bottom: 20px;
    color: #656565;
  }
}

.pages {
  position: sticky;
  bottom: -1px;
  left: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  height: 55px;
  justify-content: center;
  background-color: white;
}

.my-images{

}
</style>
