<template>
  <div>
    <div v-if="!isShowBg">
      <preview :generate-preview-loading="generatePreviewLoading" :real-time-preview-loading="realTimePreviewLoading" :realtime-preview-image-urls="realtimePreviewImageUrls" :preview-image-urls="previewImageUrls" @preview="$emit('preview')" />
      <image-opera v-if="canvasActived.type == 'image' && canvas.enableTextAndImage" :canvas="canvas" :save-canvas-state="saveCanvasState" />
      <canvasTextSetting v-if="canvasActived.type == 'text' && canvas.enableTextAndImage" :canvas2="canvas" :save-canvas-state="saveCanvasState" />
      <!-- 有的刀版只能设置背景颜色 -->
      <backgroundColorSetting v-if="!canvas.enableTextAndImage" :canvas2="canvas" :back-ground-color="backGroundColor" />
    </div>
    <div v-else>
      <preview :generate-preview-loading="generatePreviewLoading" :real-time-preview-loading="realTimePreviewLoading" :realtime-preview-image-urls="realtimePreviewImageUrls" :preview-image-urls="previewImageUrls" @preview="$emit('preview')" />
      <backgroundColorSetting :canvas2="canvas" :back-ground-color="backGroundColor" />
    </div>
  </div>
</template>
<script>
import Preview from './components/preview.vue'
import ImageOpera from './components/image-opera.vue'
import canvasTextSetting from './components/canvas_text_setting.vue'
import backgroundColorSetting from './components/background-color-setting.vue'
export default {
  components: {
    Preview,
    ImageOpera,
    canvasTextSetting,
    backgroundColorSetting
  },
  props: {
    canvas: {
      type: Object,
      default: () => ({})
    },
    realTimePreviewTaskInfo: {
      type: Object,
      default: () => []
    },
    // 预览图片列表
    previewImageUrls: {
      type: Array,
      default: () => []
    },
    // 实时预览图片列表
    realtimePreviewImageUrls: {
      type: Array,
      default: () => []
    },
    saveCanvasState: {
      type: Function
    },
    realTimePreviewLoading: {
      type: Boolean,
      default: false
    },
    generatePreviewLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      canvasActived: {},
      isShowBg: false,
      backGroundColor: ''
    }
  },
  watch: {
    canvas: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== undefined) {
          const actived = newValue.getActiveObject()
          if (actived) {
            this.canvasActived = actived
          } else {
            this.canvasActived = {}
          }
        }
      },
      deep: true
    }
  },
  created() {
    this.$bus.on('showBackgroundSet', (res) => {
      this.backGroundColor = res.backgroundColor
      this.isShowBg = res.isShow
    })
  }
}
</script>
