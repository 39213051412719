<template>
  <mask-dialog :show="previewIsShowDialog">
    <div class="pre-dialog">
      <div class="pre-left">
        <div class="pre-close cur" @click="previewIsShowDialog = false">
          <a-icon type="close" :style="{color: '#fff','fontSize': '20px'}" />
        </div>
        <div class="pre-con">
          <template v-if="!generatePreviewLoading2">
            <div>
              <img :src="mainImage" alt="">
              <div class="icon-left" @click="handPre">
                <img src="@/assets/images/custom/left-icon.png" alt="">
              </div>
              <div class="icon-right" @click="handNext">
                <img src="@/assets/images/custom/right-icon.png" alt="">
              </div>
            </div>
          </template>
          <template v-else>
            <div class="example">
              <!-- <a-spin size="large" /> -->
              <a-icon type="loading" style="color: #444;fontSize: 60px" />
            </div>
          </template>
        </div>
      </div>
      <div class="pre-right">
        <div v-if="!generatePreviewLoading2">
          <div v-for="(item,index) in realtimePreviewImageUrls" :key="index" class="pre-list" :class="activedIndex == index ? 'actived' : ''" @click="handleGoTo(index)">
            <img :src="item" alt="">
          </div>
        </div>
        <div v-if="generatePreviewLoading2" class="example">
          <a-icon type="loading" style="color: #444;fontSize: 60px" />
        </div>
      </div>
    </div>
  </mask-dialog>
</template>
<script>
import maskDialog from '../../components/mask.vue'
export default {
  components: {
    maskDialog
  },
  props: {
    // 预览图片列表
    realtimePreviewImageUrls: {
      type: Array,
      default: () => []
    },
    generatePreviewLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      previewIsShowDialog: false,
      mainImage: '',
      activedIndex: 0,
      generatePreviewLoading2: true
    }
  },
  watch: {
    realtimePreviewImageUrls: {
      handler(newValue, oldValue) {
        console.log('realtimePreviewImageUrlssss....', newValue)
        this.mainImage = newValue[0]
      }
    },
    generatePreviewLoading(val) {
      this.generatePreviewLoading2 = val
    }
  },
  methods: {
    handleGoTo(index) {
      this.activedIndex = index
      this.mainImage = this.realtimePreviewImageUrls[index]
    },
    handPre() {
      if (this.activedIndex >= 1) {
        this.activedIndex = this.activedIndex - 1
        this.mainImage = this.realtimePreviewImageUrls[this.activedIndex]
      }
    },
    handNext() {
      if (this.activedIndex < this.realtimePreviewImageUrls.length - 1) {
        this.activedIndex = this.activedIndex + 1
        this.mainImage = this.realtimePreviewImageUrls[this.activedIndex]
      }
    }
  }
}
</script>
<style scoped>
.example {
  text-align: center;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  z-index: 100;
}
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 863px;
  line-height: 863px;
  overflow: hidden;
  background-color: #cbcbcb;
}
.ant-carousel >>> .slick-slide img {
  width: 860px;
  height: 863px;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.pre-dialog .custom-slick-arrow{
  width: 33px;
  height: 60px;
  background: #D9D9D9;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #3D3D3D;
}
</style>
<style scoped lang="scss">
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.pre-dialog {
  display: flex;
  .pre-left {
    flex: 1;
    position: relative;
    .pre-close {
      position: fixed;
      top: 0;
      right: 329px;
      width: 48px;
      height: 48px;
      background-color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pre-con {
      width: 800px;
      height: 800px;
      margin: 0 auto;
      position: fixed;
      top: 50%;
      left: 50%;
      margin-top: -400px;
      margin-left: -400px;
      >img{
        border-radius: 24px;
      }
      .icon-left{
        position: absolute;
        top: 50%;
        left: 22px;
        width: 40px;
        height: 40px;
        margin-top: -30px;
        text-align: center;
        cursor: pointer;
      }
      .icon-right{
        position: absolute;
        top: 50%;
        right: 22px;
        width: 40px;
        height: 40px;
        margin-top: -30px;
        text-align: center;
        cursor: pointer;
      }
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
  }
  .pre-right {
    padding: 20px 0;
    max-width: 329px;
    min-width: 329px;
    min-height: 100vh;
    background-color: white;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // align-content: flex-start;
    // justify-content: space-between;
    position: relative;
    // box-sizing: border-box;
    .pre-list {
    // padding: 20px;
      margin-left: 20px;
      height: 136px;
      margin-bottom: 20px;
      border: 2px solid #fff;
      float: left;
      &.actived{
        border: 2px solid #000;
        border-radius: 12px;
      }
      img {
        // display: block;
        width: 132px;
        height: 132px;
        border-radius: 12px;
      }
    }
    .flex > .pre-list:first-child {
      flex: 0 1 100%;
    }
  }
}
</style>
