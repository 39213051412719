<template>
  <div class="image-con">
    <div v-if="isLoadingImg" class="image-list" style="position: relative;">
      <div class="image-spin">
        <a-spin />
      </div>
      <div class="image-img-hover" />
      <div class="file-name ellipsis" />
    </div>
    <div v-for="(item,index) in imageList2" :key="index" class="image-list" style="position: relative;">
      <images :url="item.smallImageUrl" class="my-img" alt="" />
      <div class="canvas-box" />
      <div class="image-img-hover">
        <div class="image-img-hover-btn" @click="addImages(item)">Select</div>
        <span @click="handleEdit(item)">
          <i>
            Edit
          </i>
        </span>
      </div>
      <div class="file-name ellipsis">{{ item.fileName }}</div>
    </div>
  </div>
</template>
<script>
import images from '@/components/image'
import BaseView from '@/components/base/BaseView'
export default {
  components: {
    images
  },
  extends: BaseView,
  props: {
    canvas2: {
      type: Object,
      default: () => {}
    },
    imageList: {
      type: Array,
      default: () => []
    },
    isLoadingImg2: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoadingImg: false,
      imageList2: [],
      canvas: {}
    }
  },
  watch: {
    canvas2(newValue, oldValue) {
      this.canvas = newValue
    },
    imageList(newValue, oldValue) {
      this.imageList2 = newValue
    },
    isLoadingImg2(newValue, oldValue) {
      this.isLoadingImg = newValue
    }
  },
  created() {
    if (this.imageList) {
      this.imageList2 = this.imageList
    }
    if (this.canvas2) {
      this.canvas = this.canvas2
    }
  },

  methods: {
    handleEdit(param) {
      this.$emit('edit', param)
    },
    addImages(item) {
      this.addImage(item)
      // this.$emit('addImageLoading')
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss">
.image-list .ant-spin-dot-item{
  background-color: #FF5347;
}
</style>
<style scoped lang="scss">
.image {
  &-con {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    .image-list{
      width: 198px;
      height: 229px;
      margin-left: 20px;
      border-radius: 4px;
      text-align: center;
      border: 1px solid #C9C9C9;
      margin-bottom: 45px;
      position: relative;
      .canvas-box {
        background-image: url("../../../../assets/img/bg.svg");
        background-repeat: repeat;
        background-size: 8%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .image-spin{
        position: absolute;
        width: 198px;
        height: 229px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .file-name{
        margin-top: 8px;
        margin-bottom: 20px;
        font-size: 14px;
        text-align: left;
      }
      .image-img-hover{
        display: none;
        background-color: rgba(62,62,62,0.2);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        span{
          position: absolute;
          top: 8px;
          right: 8px;
          display: inline-block;
          width: 36px;
          height: 36px;
          background: url(../../../../assets/images/custom/img-bianji.png) no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          &:hover{
            background: url(../../../../assets/images/custom/img-bianji2.png) no-repeat;
            background-size: 100% 100%;
          }
          i{
            display: none;
            position: absolute;
            right: 0px;
            top: 38px;
            width: 42px;
            height: 28px;
            background: url(../../../../assets/images/custom/qipao.png) no-repeat;
            background-size: 100% 100%;
            color: white;
            font-style: normal;
            line-height: 32px;
            font-size: 12px;
          }
          &:hover i{
            display: block;
          }
        }
        &-btn{
          width: 115px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          background-color: rgb(255, 83, 71);
          position: absolute;
          bottom: 57px;
          left: 44px;
          color: white;
          border-radius: 4px;
          font-size: 20px;
          cursor: pointer;
        }
      }
      &:hover .image-img-hover{
          display: block;
        }
      // &:hover .image-img-hover{
      //   display: block;
      // }
    }
    .my-img{
      width: 198px;
      height: 229px;
      object-fit: contain;
      overflow: hidden;
    }
  }
}
</style>
