<template>
  <div class="text">
    <div class="title">Text</div>
    <div class="font-style">
      <div class="color">
        <input v-model="BgColor" type="color" class="colorpick" @input="setFontColor">
      </div>
      <div class="font-size-select">
        <a-input v-model="fontFamily" style="flex: 1" @click.stop="isShowFontFamilySelect = !isShowFontFamilySelect">
          <a-icon slot="suffix" type="down" style="color: rgba(0,0,0,.45)" @click.stop="isShowFontFamilySelect = !isShowFontFamilySelect" />
        </a-input>
        <div v-if="isShowFontFamilySelect" style="flex: 1" class="select">
          <div v-for="(item,index) in fontFamilyList" :key="index" :style="{'font-family': item.fontFamily}" class="select-li" @click.stop="handleFontFamilyChange(item)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- <div class="font-size-select">
        <a-input v-model="fontSize" style="width: 68px" @click="isShowFontSizeSelect = !isShowFontSizeSelect">
          <a-icon slot="suffix" type="down" style="color: rgba(0,0,0,.45)" @click="isShowFontSizeSelect = !isShowFontSizeSelect" />
        </a-input>
        <div v-if="isShowFontSizeSelect" style="width: 68px" class="select">
          <div v-for="(item,index) in fontSizeList" :key="index" :style="{'font-family': item.value}" style="width: 68px" class="select-li" @click="handleFontSizeChange(item)">
            {{ item.name }}
          </div>
        </div>
      </div> -->
    </div>
    <div class="dis-space mt20">
      <div class="">
        <div class="align-title">
          Align
        </div>
        <div class="dis-flex align-box mt10">
          <div class="dis-space-left" :class="textAlign == 'left' ? 'dis-space-left-actived' : ''" @click="handAlign('left')" />
          <div class="dis-space-center" :class="textAlign == 'center' ? 'dis-space-center-actived' : ''" @click="handAlign('center')" />
          <div class="dis-space-right" :class="textAlign == 'right' ? 'dis-space-right-actived' : ''" @click="handAlign('right')" />
        </div>
      </div>
      <div class="">
        <div class="align-title">
          Special
        </div>
        <div class="dis-flex align-box mt10" :class="fontWeight == 'bold' || fontStyle == 'italic' || underline == 'dis-space-u-actived' ? 'align-box-actived' : '' ">
          <div class="dis-space-b" :class="fontWeight == 'bold' ? 'dis-space-b-actived' : ''" @click="handleFontSpecial('weight')" />
          <div class="dis-space-i" :class="fontStyle == 'italic' ? 'dis-space-i-actived' : ''" @click="handleFontSpecial('italic')" />
          <div class="dis-space-u" :class="underline ? 'dis-space-u-actived' : ''" @click="handleFontSpecial('underline')" />
        </div>
      </div>
    </div>
    <div class="font-opera-scale dis-space">
      <div class="font-opera-scale-list">
        <div class="font-opera-scale-list-top">
          Text Spacing
        </div>
        <div class="font-opera-scale-list-box">
          <img src="@/assets/images/custom/f-hang.png" class="font-opera-scale-list-box-img">
          <input ref="charSpacing" :value="(charSpacing/10).toFixed(0)" type="text" @blur="blurInput('charSpacing')">
          <img src="@/assets/images/custom/sanshu.png" style="width: 24px;height: 24px; margin-left: auto;cursor: e-resize;" alt="" @mousedown="onMouseDown($event, 'charSpacing')">
        </div>
      </div>
      <div class="font-opera-scale-list" style="margin-left: 15px">
        <div class="font-opera-scale-list-top">
          Line Spacing
        </div>
        <div class="font-opera-scale-list-box">
          <img src="@/assets/images/custom/f-duan.png" class="font-opera-scale-list-box-img">
          <input ref="lineHeight" :value="((lineHeight*10)).toFixed(0)" type="text" @blur="blurInput('lineHeight')">
          <img src="@/assets/images/custom/sanshu.png" style="width: 24px;height: 24px; margin-left: auto;cursor: e-resize;" alt="" @mousedown="onMouseDown($event, 'lineHeight')">
        </div>
      </div>
    </div>
    <div class="font-opera-scale">
      <div class="font-opera-scale-list">
        <div class="font-opera-scale-list-top">
          Rotate
        </div>
        <div class="font-opera-scale-list-box">
          <img src="@/assets/images/custom/xuanzhuan.png" class="font-opera-scale-list-box-img">
          <input ref="angle" :value="angle.toFixed(0)" type="text" @blur="blurInput('angle')">
          <img src="@/assets/images/custom/sanshu.png" style="width: 24px;height: 24px; margin-left: auto;cursor: e-resize;" alt="" @mousedown="onMouseDown($event, 'angle')">
        </div>
      </div>
      <div class="font-opera-scale-list" style="margin-left: 20px">
        <div class="font-opera-scale-list-top">
          Opacity
        </div>
        <div class="font-opera-scale-list-box">
          <img src="@/assets/images/custom/eyes.png" class="font-opera-scale-list-box-img">
          <input ref="opacity" :value="opacity.toFixed(1)" type="text" @blur="blurInput('opacity')">
          <img src="@/assets/images/custom/sanshu.png" style="width: 24px;height: 24px; margin-left: auto;cursor: e-resize;" alt="" @mousedown="onMouseDown($event, 'opacity')">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseView from '@/components/base/BaseView'
import { fonts } from '@/api/font'
export default {
  extends: BaseView,
  props: {
    canvas2: {
      type: Object,
      default: () => ({})
    },
    saveCanvasState: {
      type: Function
    }
  },
  data() {
    return {
      BgColor: '',
      isShowFontFamilySelect: false,
      fontStyleList: [],
      isShowFontSizeSelect: false,
      fontSizeList: [],
      fontStyle: '',
      fontSize: 12,
      canvasActived: {},
      canvas: {},
      charSpacing: 0,
      lineHeight: 0,
      angle: 0,
      opacity: 0,
      fontWeight: '',
      fontFamily: '',
      underline: '',
      textAlign: ''
    }
  },
  watch: {
    canvas2: {
      handler(newValue, oldValue) {
        this.canvas = newValue
        const actived = newValue.getActiveObject()
        if (actived) {
          this.canvasActived = actived
          this.fontWeight = actived.fontWeight
          this.fontSize = actived.fontSize
          this.fontFamily = actived.fontFamily
          this.fontStyle = actived.fontStyle
          this.charSpacing = actived.charSpacing
          this.lineHeight = actived.lineHeight
          this.angle = actived.angle
          this.underline = actived.underline
          this.opacity = actived.opacity
          this.textAlign = actived.textAlign
        }
      },
      deep: true
    }
  },
  mounted() {
    const _this = this
    this.getFonts()
    for (let i = 12; i <= 40; i += 2) {
      const fontSize = {
        name: i + 'px',
        value: i
      }
      this.fontSizeList.push(fontSize)
    }
    document.addEventListener('mouseup', function(event) {
      console.log('鼠标放开了....')
      if (_this.isDragging) {
        _this.onMouseUp()
      }
    })
    window.addEventListener('mousemove', this.onMouseMove) // 监听鼠标移动事件

    document.addEventListener('click', this.closeFontFamily)
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.onMouseUp)
    document.removeEventListener('mousemove', this.onMouseMove) // 停止监听鼠标移动事件
    document.removeEventListener('click', this.closeFontFamily) // 停止监听鼠标移动事件
  },
  methods: {
    closeFontFamily() {
      const isClickInsidePopup = event.target.closest('.select') !== null
      // 如果不是弹窗内部的元素，则关闭弹窗
      if (!isClickInsidePopup) {
        this.isShowFontFamilySelect = false
      }
    },
    handleFontSizeChange(data) {
      this.fontSize = data.name
      this.isShowFontSizeSelect = false
      this.canvasActived.set('fontSize', data.value)
      this.canvas.renderAll()
      this.saveCanvasState()
    },
    // 设置字体
    handleFontFamilyChange(val) {
      this.fontFamily = val.name
      this.isShowFontFamilySelect = false
      this.canvasActived.set('fontFamily', val.fontFamily)
      this.canvas.renderAll()
      this.saveCanvasState()
    },
    // 获取字体
    async getFonts() {
      const { data } = await fonts()
      this.fontFamilyList = data.records
      data.records.forEach(item => {
        this.onloadFont(item.name, item.url)
      })
    },
    onloadFont(name, url) {
      const fontFace = new FontFace(name, url, {
        weight: 'normal',
        style: 'normal'
      })

      fontFace.load().then(() => {
        document.fonts.add(fontFace)
        this.$forceUpdate() // 强制更新VNode，以便使用新的字体样式
      })
    },
    handleFontSpecial(val) {
      if (val === 'weight') {
        this.handleFW().then(() => {
          this.saveCanvasState()
        })
      }
      if (val === 'italic') {
        this.handleI().then(() => {
          this.saveCanvasState()
        })
      }
      if (val === 'underline') {
        this.handleU().then(() => {
          this.saveCanvasState()
        })
      }
    },
    handAlign(val) {
      this.textAligns(val).then(res => {
        this.saveCanvasState()
      })
    },
    // 设置字体颜色
    setFontColor() {
      this.canvasActived.set('fill', this.BgColor)
      this.canvas.renderAll()
      this.saveCanvasState()
    },
    // 鼠标按下
    onMouseDown(event, val) {
      event.preventDefault()
      this.selectChange = val
      this.startX = event.clientX
      this.isDragging = true
    },
    // 鼠标移动
    onMouseMove(event) {
      if (this.isDragging) {
        this.currentX = event.clientX // 记录当前鼠标位置的x坐标
        const distance = this.currentX - this.lastX // 计算鼠标移动的距离

        if (distance > 0) {
          this.onChange('add')
          console.log('向右滑动')
        // 在这里执行右滑逻辑
        } else if (distance < 0) {
          this.onChange('del')
          console.log('向左滑动')
        // 在这里执行左滑逻辑
        } else {
          console.log('未滑动')
        // 在这里执行未滑动逻辑
        }

        this.lastX = this.currentX // 更新上一次鼠标位置的x坐标
      }
    },
    onChange(isAdd) {
      if (this.selectChange === 'fontSize') {
        this.fontSize += isAdd === 'add' ? 1 : -1
        this.canvasActived.set('fontSize', this.fontSize)
        this.canvas.renderAll()
      }
      // text spacing
      if (this.selectChange === 'charSpacing') {
        if (isAdd === 'add') {
          this.charSpacing = this.charSpacing + 50
        } else {
          this.charSpacing = this.charSpacing - 50
        }
        console.log('charSpacing....', this.charSpacing)
        this.canvasActived.set('charSpacing', this.charSpacing)
      }
      if (this.selectChange === 'lineHeight') {
        this.lineHeight += isAdd === 'add' ? 0.1 : -0.1
        console.log('滑动了行高...', this.lineHeight)
        this.canvasActived.set('lineHeight', this.lineHeight)
      }
      if (this.selectChange === 'angle') {
        this.angle += isAdd === 'add' ? 1 : -1
        this.canvasActived.rotate(this.angle)
      }
      if (this.selectChange === 'opacity') {
        if (this.opacity >= 1 && isAdd === 'add' || this.opacity <= 0.1 && isAdd === 'del') {
          return
        }
        this.opacity += isAdd === 'add' ? 0.1 : -0.1
        console.log('option....', this.opacity)
        this.canvasActived.set('opacity', this.opacity)
      }
      this.canvas.renderAll()
    },
    // 鼠标放开
    onMouseUp() {
      console.log('放开了.......')
      this.isDragging = false
      this.saveCanvasState()
    },
    blurInput(val) {
      let value = 0
      if (val === 'charSpacing') {
        value = this.$refs.charSpacing.value.slice(0, -1)
        console.log(value * 10)
        this.canvasActived.set('charSpacing', parseInt(value * 100))
      }
      if (val === 'lineHeight') {
        value = this.$refs.lineHeight.value.slice(0, -1)
        this.canvasActived.set('lineHeight', parseInt(value))
      }
      if (val === 'angle') {
        value = this.$refs.angle.value.slice(0, -1)
        this.canvasActived.rotate(parseInt(value * 10))
      }
      if (val === 'opacity') {
        value = this.$refs.opacity.value
        console.log('blurInput....', value)
        this.canvasActived.set('opacity', parseFloat(value))
      }
      this.canvas.renderAll()
      this.$forceUpdate()
    }
  }
}
</script>
<style>
.text .ant-input{
  background-color: white !important;
  border: 1px solid #D9D9D9 !important;
}
</style>
<style scoped lang="scss">
.text{
  padding: 0 30px;
  // border-top: 1px solid #d0d0d0;
  .title{
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    border-top: 1px dashed #d0d0d0;
    padding-top: 24px;
    padding-bottom: 20px;
    color: #333;
  }
  .font-style{
    display: flex;
    .color{
      padding: 6px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #BABABA;
      display: flex;
      align-items: center;
      justify-content: center;
      input{
        width: 24px;
        height: 24px;
      }
    }
  }
  .font{
    margin: 6px 29px;
    &-size{
      display: flex;
      margin-bottom: 14px;
      &-select{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: 12px;
        flex: 1;
        .select{
          position: absolute;
          top: 45px;
          z-index: 10;
          width: 100%;
          background: white;
          height: 264px;
          overflow: hidden;
          overflow-y: scroll;
        }
        .select-li{
          width: 100%;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #FFFFFF;
          border: 1px solid #EBEBEB;
          margin-bottom: 4px;
          font-size: 12px;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 10px;
          &:hover{
            background-color: #FFF4F4;
            color: #FF5347;
          }
        }
      }
    }
    &-box{
      width: 36px;
      height: 36px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #D9D9D9;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 9px;
      cursor: pointer;
      img{
        width: 36px;
        height: 36px;
      }
    }
  }
  .font-opera{
    &-scale {
      display: flex;
      margin-top: 18px;
      margin-bottom: 20px;
      &-list {
        &-top {
          color: #bababa;
          font-size: 12px;
        }
        &-box {
          width: 121px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid #777;
          display: flex;
          align-items: center;
          margin-top: 8px;
          padding-left: 6px;
          box-sizing: border-box;
          &:hover{
            border: 2px solid #444;
          }
          &-btn{
            width: 30px;
            height: 30px;
            margin-left: auto;
            img{
              width: 26px;
              height: 14px;
              display: block;
            }
          }
          &-img {
            width: 24px;
            height: 24px;
          }
          input {
            outline: none;
            margin-left: 5px;
            text-align: center;
            width: 40px;
          }
          &-color {
            width: 24px;
            height: 24px;
            background: #333333;
            border-radius: 4px;
          }
          &-bs {
            margin-left: auto;
            cursor: ew-resize;
            img {
              padding-right: 6px;
            }
          }
        }
      }
    }
  }
  .image-opera {
    &-scale {
      &-list {
        &-box {
          margin-left: 12px;
          width: 70px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid #BABABA;
          display: flex;
          align-items: center;
          padding-left: 6px;
          box-sizing: border-box;
          .colorpick{
            width: 24px;
            height: 24px;
            position: absolute;
            opacity: 0;
          }
          &-touming{
            width: 24px;
            height: 24px;
          }
          &-img {
            width: 24px;
            height: 24px;
          }
          input {
            outline: none;
            margin-left: 5px;
            text-align: center;
            width: 40px;
          }
          &-color {
            width: 24px;
            height: 24px;
            background: #333333;
            border-radius: 4px;
          }
          &-bs {
            margin-left: auto;
            cursor: ew-resize;
            img {
              padding-right: 6px;
            }
          }
        }
      }
    }
  }
  .align-title{
    font-size: 12px;
    color: #BABABA;
  }
  .align-box{
    width: 121px;
    height: 36px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #777;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-actived{
      border: 2px solid #444;
    }
    &:hover{
      border: 2px solid #444;
    }
    .dis-space-left{
      background: url('../../../../assets/images/custom/f-left.png');
      width: 32px;
      height: 32px;
      background-size: 100% 100%;
      &-actived{
        background: url('../../../../assets/images/custom/f-left2.png');
        background-size: 100% 100%;
      }
      &:hover{
        background: url('../../../../assets/images/custom/f-left2.png');
        background-size: 100% 100%;
      }
    }
    .dis-space-center{
      background: url('../../../../assets/images/custom/f-center.png');
      width: 32px;
      height: 32px;
      background-size: 100% 100%;
      &-actived{
        background: url('../../../../assets/images/custom/f-center2.png');
        background-size: 100% 100%;
      }
      &:hover{
        background: url('../../../../assets/images/custom/f-center2.png');
        background-size: 100% 100%;
      }
    }
    .dis-space-right{
      background: url('../../../../assets/images/custom/f-right.png');
      width: 32px;
      height: 32px;
      background-size: 100% 100%;
      &-actived{
        background: url('../../../../assets/images/custom/f-right2.png');
        background-size: 100% 100%;
      }
      &:hover{
        background: url('../../../../assets/images/custom/f-right2.png');
        background-size: 100% 100%;
      }
    }
    .dis-space-b{
      background: url('../../../../assets/images/custom/f-b.png');
      width: 32px;
      height: 32px;
      background-size: 100% 100%;
      &-actived{
        background: url('../../../../assets/images/custom/f-b2.png');
        background-size: 100% 100%;
      }
      &:hover{
        background: url('../../../../assets/images/custom/f-b2.png');
        background-size: 100% 100%;
      }
    }
    .dis-space-i{
      background: url('../../../../assets/images/custom/f-i.png');
      width: 32px;
      height: 32px;
      background-size: 100% 100%;
      &-actived{
        background: url('../../../../assets/images/custom/f-i2.png');
        background-size: 100% 100%;
      }
      &:hover{
        background: url('../../../../assets/images/custom/f-i2.png');
        background-size: 100% 100%;
      }
    }
    .dis-space-u{
      background: url('../../../../assets/images/custom/f-u.png');
      width: 32px;
      height: 32px;
      background-size: 100% 100%;
      &-actived{
        background: url('../../../../assets/images/custom/f-u2.png');
        background-size: 100% 100%;
      }
      &:hover{
        background: url('../../../../assets/images/custom/f-u2.png');
        background-size: 100% 100%;
      }
    }
    img{
      width: 32px;
      height: 32px;
    }
  }
}
</style>
