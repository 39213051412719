<template>
  <div class="">
    <mask-dialog ref="addImage" :show="editImageIsShowDialog">
      <div class="edit">
        <div class="edit-title dis-flex-align-center">
          <span>Edit Images</span>
          <div class="edit-title-close cur" @click="editImageIsShowDialog = false">
            <a-icon type="close" size="50" />
          </div>
        </div>
        <div class="edit-main">
          <div class="edit-left">
            <!-- 正常情况的 -->
            <!-- <div v-if="baseInfo.style == 'nomal'" class="edit-left-nomal">
              <img :src="baseInfo.fileUrl" mode="" alt="">
            </div> -->
            <!-- 长图 -->
            <!-- <div v-if="baseInfo.style == 'long'" class="edit-left-long">
              <img :src="baseInfo.fileUrl" alt="">
            </div> -->
            <!-- 透明 -->
            <!-- <div v-if="baseInfo.style == 'tran'" class="edit-left-tran">
              <img :src="baseInfo.fileUrl" alt="">
            </div> -->
            <div v-if="baseInfo.style == 'tran'" class="edit-left-tran">
              <div class="trans-bg" />
              <img :src="baseInfo.fileUrl" alt="">
            </div>
            <div class="edit-left-desc">
              <p>Dimensions:{{ baseInfo.imageWidth }}*{{ baseInfo.imageHeight }}</p>
              <p>Size:{{ baseInfo.fileSize }}</p>
              <p>Format:{{ getFileFormat(baseInfo.fileUrl) }}</p>
              <p>{{ baseInfo.addDate }}</p>
            </div>
          </div>
          <div class="edit-right">
            <div class="edit-right-title">Image Title</div>
            <input v-model="baseInfo.fileName" type="text" placeholder="placeholder">
            <div class="edit-right-title" style="margin-top: 40px">Tags</div>
            <input v-model="tag" type="text" placeholder="Add a tag" @keydown.enter="handleTag">
            <div class="tags">
              <div v-for="(item,index) in baseInfo.tags" :key="index" class="tag">
                <span class="tag-name">{{ item }}</span>
                <span class="tag-close" @click="closeTag(index)">
                  <img src="@/assets/images/custom/tag-close.png" alt="">
                </span>
              </div>
            </div>
            <div class="btns">
              <div class="btn-delete" @click="delImageDialog = true">Delete</div>
              <div class="btn-cancel" @click="editImageIsShowDialog = false">Cancel</div>
              <div class="btn-confirm" @click="handleSubmit">Confirm</div>
            </div>
          </div>
        </div>
      </div>
    </mask-dialog>
    <!-- 删除图片确认弹框 -->
    <mask-dialog :show="delImageDialog">
      <div class="create-folder">
        <div class="create-folder-title">
          Delete file
          <img src="@/assets/images/custom/gandanhao.png" alt="">
        </div>
        <div class="create-con">
          Are you sure to delete the selected images?
        </div>
        <div class="image-list-btns dis-flex">
          <div class="image-list-btns-cancel" @click="delImageDialog = false">Cancel</div>
          <div class="image-list-btns-done" @click="handleDel">Delete</div>
        </div>
      </div>
    </mask-dialog>
  </div>
</template>
<script>
import maskDialog from '../../components/mask.vue'
import { fileUpdateFileInfo, removeFile } from '@/api/file'
export default {
  components: {
    maskDialog
  },
  data() {
    return {
      editImageIsShowDialog: false,
      delImageDialog: false,
      tag: '',
      baseInfo: {}
    }
  },
  methods: {
    handleTag() {
      if (this.isOnlySpaces(this.tag)) {
        return
      }
      for (const item of this.baseInfo.tags) {
        if (item === this.tag) {
          this.$message.warn('The label already exists')
          return
        }
      }
      this.baseInfo.tags.push(this.tag)
      this.tag = ''
    },
    isOnlySpaces(str) {
      return str.trim().length === 0
    },
    closeTag(index) {
      this.baseInfo.tags.splice(index, 1)
    },
    open(item) {
      if (!item.tags) {
        item.tags = []
      }
      const image = new Image()
      image.src = item.fileUrl
      image.onload = () => {
        console.log('image.width...', image.width)
        console.log('image.height...', image.height)
        const { width, height } = image
        // if (width >= height && width > 287 || width >= height && height > 287) {
        //   item.style = 'nomal'
        // } else if (height >= (width * 1.5)) {
        //   item.style = 'long'
        // } else if (width < 287 && height < 287) {
        //   item.style = 'tran'
        // } else {
        //   item.style = 'nomal'
        // }
        item.style = 'tran'
        this.baseInfo = item
        this.editImageIsShowDialog = true
      }
    },
    getFileFormat(fileName) {
      if (fileName) {
        const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1)
        return fileExtension
      }
    },
    async handleSubmit() {
      const { baseInfo } = this
      const param = {
        fileId: baseInfo.id,
        fileName: baseInfo.fileName,
        tags: baseInfo.tags
      }
      const { code } = await fileUpdateFileInfo(param)
      if (code === 200) {
        this.editImageIsShowDialog = false
        this.$parent.resetImagePage()
        this.$parent.getTagList()
        this.$message.success('Success')
      }
    },
    async handleDel() {
      const { code } = await removeFile({
        fileId: this.baseInfo.id
      })
      if (code === 200) {
        this.editImageIsShowDialog = false
        this.delImageDialog = false
        this.$parent.resetImagePage()
        this.$message.success('Success')
      }
    }
  }
}
</script>
<style scoped lang="scss">
.edit {
  width: 785px;
  height: 529px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -392.5px;
  margin-top: -264.5px;
  &-title {
    height: 65px;
    width: 100%;
    border-bottom: 1px solid #cbcbcb;
    display: flex;
    align-items: center;
    padding: 0 35px;
    box-sizing: border-box;
    span {
      font-size: 20px;
      font-weight: Medium;
      color: #333;
    }
    &-close {
      margin-left: auto;
      font-size: 20px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        background-color: #ddd;
      }
    }
  }
  &-main{
    display: flex;
    .edit-left{
      width: 335px;
      height: 461px;
      padding: 24px;
      background-color: #F8F8F8;
      &-desc{
        font-size: 14px;
        color: #7B7B7B;
        p{
          margin-top: 12px;
        }
      }
      &-nomal{
        .trans-bg{
          background-image: url("../../../../assets/img/bg.svg");
          background-repeat: repeat;
          background-size: 38%;
          z-index: 1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        img{
          max-height: 280px;
          width: 287px;
          object-fit: contain;
        }
      }
      &-long{
        border: 1px dashed #A3A3A3;
        width: 287px;
        text-align: center;
        img{
          width: 160px;
          height: 287px;
          object-fit: contain;
        }
      }
      &-tran{
        width: 287px;
        height: 287px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('../../../../assets/images/custom/tran.png') repeat;
        img{
          width: 287px;
          height: 287px;
          object-fit: contain;
        }
      }
      // img{
      //   object-fit: contain;
      // }
    }
    .edit-right{
      padding: 24px;
      box-sizing: border-box;
      position: relative;
      &-title{
        font-size: 14px;
        font-weight: medium;
        margin-bottom: 8px;
        color: #333;
      }
      input{
        width: 402px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid#D9D9D9;
        padding-left: 16px;
        &:hover{
          border: 1px solid #FFCBC8;
        }
      }
      input[type="text"]:focus{
        border-color: #FFCBC8 !important;
      }

      .tags{
        width: 400px;
        .tag{
          display: inline-block;
          padding: 10px 12px;
          border: 1px solid #BFBFBF;
          color: #4D4D4D;
          background-color: #F5F5F5;
          font-size: 12px;
          border-radius: 4px;
          margin-right: 12px;
          cursor: pointer;
          margin-top: 16px;
          &-close{
            margin-left: 13px;
            img{
              width: 14px;
              height: 14px;
            }
          }
        }
      }
      .btns{
        position: absolute;
        bottom: 23px;
        width: 100%;
        display: flex;
        align-items: center;
        .btn-delete{
          width: 70px;
          height: 36px;
          color: #FF5347;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
          &:hover{
            border-radius: 3px 3px 3px 3px;
            background: #FFF4F4;
          }
        }
        .btn-cancel{
          width: 93px;
          height: 38px;
          border-radius: 4px 4px 4px 4px;
          color: #bababa;
          line-height: 38px;
          font-size: 16px;
          text-align: center;
          margin-left: auto;
          cursor: pointer;
          color: #8D8D8D;
          background: #F5F5F5;
        }
        .btn-confirm{
          width: 93px;
          height: 38px;
          background: #ff5347;
          border-radius: 4px 4px 4px 4px;
          color: white;
          line-height: 38px;
          font-size: 16px;
          text-align: center;
          margin-right: 50px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
.create-folder {
  width: 401px;
  height: 229px;
  background: #ffffff;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 8px 8px;
  padding: 18px 20px;
  padding-top: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -86px;
  margin-left: -180px;
  &-name {
    height: 34px;
    line-height: 34px;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    span:nth-child(2) {
      margin-left: 50px;
    }
  }
  &-title {
    font-size: 18px;
    font-weight: Regular;
    height: 61px;
    line-height: 61px;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    color: #333;
    img{
      margin-left: auto;
      width: 30px;
      height: 30px;
    }
  }
  .create-con {
    padding-top: 12px;
    height: 75px;
    font-size: 16px;
    color: #555555;
    span{
      font-weight: bold;
    }
  }
  .image-list-btns {
    margin-top: 30px;
    .image-list-btns-done {
      width: 93px;
      height: 38px;
      background: #ff5347;
      border-radius: 4px 4px 4px 4px;
      color: white;
      line-height: 38px;
      font-size: 16px;
      text-align: center;
      margin-left: 10px;
      cursor: pointer;
    }
    .image-list-btns-cancel {
      width: 93px;
      height: 38px;
      border-radius: 4px 4px 4px 4px;
      color: #bababa;
      line-height: 38px;
      font-size: 16px;
      text-align: center;
      margin-left: auto;
      cursor: pointer;
      color: #8D8D8D;
      background: #F5F5F5;
    }
  }
}
</style>
