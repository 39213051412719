<template>
  <div class="opera">
    <div v-if="patterIndex2 < productModeNum2 && productModeNum2 > 1" class="switchMode">
      <div class="switchMode-button" @click="isShwoMode = true">
        Switch Mode<a-icon type="down" style="margin-left: 5px" />
      </div>
      <div v-show="isShwoMode" class="switchMode-mask" @click="isShwoMode = false" />
      <div v-show="isShwoMode" class="switchMode-con">
        <div class="switchMode-title">Switch Mode</div>
        <div class="switchMode-desc">Switch another design mode? If so, the design content in the current mode will not be applied to the new design mode, and the current design content will be cleared.</div>
        <div class="switchMode-radios">
          <div class="switchMode-radio " :class="mode2 == 2 ? 'actived' : ''" @click="changeMode(2)">
            <i :class="mode2 == 2 ? 'actived' : ''" />
            <span>One-piece design</span>
          </div>
          <div class="switchMode-radio " :class="mode2 == 1 ? 'actived' : ''" @click="changeMode(1)">
            <i :class="mode2 == 1 ? 'actived' : ''" />
            <span>Multi-piece design</span>
          </div>
        </div>
        <div class="btns">
          <div class="btn-cancel" @click="isShwoMode = false">Cancel</div>
          <div class="btn-confirm" @click="handleModeSubmit">Yes</div>
        </div>
      </div>
    </div>
    <!-- <div v-if="patterIndex2 < productModeNum2 && productModeNum2 > 1" class="opera-tishi">
      <a-tooltip placement="top" style="word-break: keep-all" @click="undo">
        <template slot="title">
          Wanna switch a design mode? If so, there's no design content in the new mode, please redesign the product.
        </template>
        <div class="switch" @click="handleSwitch">
          <div class="switch-li">
            <img v-if="mode2 === 1" src="@/assets/images/custom/dan.png" alt="">
          </div>
          <div class="switch-li">
            <img v-if="mode2 === 2" src="@/assets/images/custom/duo.png" alt="">
          </div>
        </div>
      </a-tooltip>
    </div> -->
    <div class="opera-main">
      <a-tooltip placement="bottom" @click="undo">
        <template slot="title">
          Undo
        </template>
        <div class="opera-main-list huitui" />
      </a-tooltip>
      <a-tooltip placement="bottom" @click="redo">
        <template slot="title">
          Redo
        </template>
        <div class="opera-main-list qianjin" />
      </a-tooltip>
      <a-tooltip overlay-class-name="folders-tooltip" :overlay-style="{'background-color': '#fff'}" placement="bottom" trigger="click">
        <template slot="title">
          <div class="opera-align">
            <div class="opera-align-list" @click="copyObject('horizontal')">
              <span class="ch" />
              <!-- <img src="@/assets/images/custom/jxsp2.png" alt=""> -->
              <p>Clone Horizontal</p>
            </div>
            <div class="opera-align-list" @click="copyObject('vertical')">
              <span class="cv" />
              <!-- <img src="@/assets/images/custom/jxcz2.png" alt=""> -->
              <p>Clone Vertical</p>
            </div>
            <div class="opera-align-list" @click="handFlip('horizontal')">
              <span class="mh" />
              <!-- <img src="@/assets/images/custom/jxsp.png" alt=""> -->
              <p>Mirror Horizonta</p>
            </div>
            <div class="opera-align-list" @click="handFlip('vertical')">
              <span class="mv" />
              <!-- <img src="@/assets/images/custom/jxcz.png" alt=""> -->
              <p>Mirror Vertical</p>
            </div>
          </div>
        </template>
        <!-- <img src="@/assets/images/custom/duichengfuzhi.png" alt=""> -->
        <a-tooltip placement="bottom">
          <template slot="title">
            Mirror
          </template>
          <div class="opera-main-list duichengfuzhi" />
        </a-tooltip>
      </a-tooltip>
      <a-tooltip placement="bottom">
        <template slot="title">
          Copy
        </template>
        <div class="opera-main-list fuzhi" @click="handleCopyObject('copy')" />
      </a-tooltip>
      <a-tooltip placement="bottom">
        <template slot="title">
          Background Color
        </template>
        <div class="opera-main-list background">
          <input v-model="BgColor" type="color" name="" class="colorpick">
        </div>
      </a-tooltip>
      <a-tooltip placement="bottom">
        <template slot="title">
          Delete
        </template>
        <div class="opera-main-list head-shanchu" @click="removeObject" />
      </a-tooltip>
      <a-tooltip placement="bottom">
        <template slot="title">
          Reset
        </template>
        <div class="opera-main-list shuaxin" @click="handleClearCanvas" />
      </a-tooltip>
      <a-tooltip placement="bottom" trigger="hover" :overlay-class-name="isShowTips ? 'opera-tip' : 'opera-tips'" @visibleChange="visibleChange">
        <template slot="title">
          <!-- <div>Design Guideline</div> -->
          <div v-if="isShowTips">Design Guidelines</div>
          <div v-else class="tips-content">
            <div class="tips-title">Design Guidelines</div>
            <div class="tips-con">Double check the design for bleed areas</div>
            <div class="tips-con">
              Please make sure your design covers the entire print area. This includes the bleeding area (marked in green). Otherwise, the sides of the product may be left empty. Important design elements should be kept within the safe area (marked with a dotted line) as they may be cut off if left outside.
            </div>
          </div>
        </template>
        <div class="opera-main-list tishi" @click="handleTips" />
      </a-tooltip>
    </div>
    <div class="dpi-con">
      <template v-if="canvasObjects.length > 0">
        <div v-if="JSON.stringify(canvasActived) != '{}'" class="dpi" :class="canvasActived.dpiClass">
          Print Quality: {{ canvasActived.dpiClass }}
        </div>
        <div v-else class="dpi wait" :class="canvasActived.dpiClass">
          Print Quality:
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import BaseView from '@/components/base/BaseView'
export default {
  extends: BaseView,
  props: {
    canvas: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: Number,
      default: 1
    },
    patternInfos: {
      type: Array,
      default: () => []
    },
    saveCanvasState: {
      type: Function
    },
    patterIndex: {
      type: Number,
      default: 0
    },
    productModeNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isShwoMode: false,
      switchs: 1,
      canvasActived: {},
      mode2: '',
      isShowTips: true,
      patterIndex2: 0,
      productModeNum2: 0,
      canvasObjects: [],
      BgColor: 'transparent'
    }
  },
  watch: {
    canvas: {
      handler(newValue, oldValue) {
        if (newValue && newValue != undefined) {
          const actived = newValue.getActiveObject()
          if (actived) {
            // console.log('actived...aaaa...', newValue)
            this.canvasActived = actived
            this.$forceUpdate()
          } else {
            this.canvasActived = {}
          }
          this.canvasObjects = newValue.getObjects()
        } else {
          this.canvasActived = {}
          this.canvasObjects = []
        }
      },
      deep: true
    },
    BgColor(newValue, oldValue) {
      if (newValue) {
        if (newValue !== 'transparent') {
          this.canvas.backgroundColor = newValue
          this.canvas.setBackgroundColor(newValue, this.canvas.renderAll.bind(this.canvas))
          this.isTran = false
          this.saveCanvasState()
        }
      }
    },
    mode(newValue, oldValue) {
      this.mode2 = newValue
    },
    productModeNum(newValue, oldValue) {
      this.productModeNum2 = newValue
    },
    patterIndex(newValue, oldValue) {
      this.patterIndex2 = newValue
    }
  },
  created() {
    if (this.mode) {
      this.mode2 = this.mode
    }
    if (this.productModeNum) {
      this.productModeNum2 = this.productModeNum
    }
    if (this.patterIndex) {
      this.patterIndex2 = this.patterIndex
    }
  },
  methods: {
    handleModeSubmit() {
      this.$emit('setMode', this.mode2)
      this.isShwoMode = false
    },
    changeMode(val) {
      this.mode2 = val
    },
    handleBackGround(value) {
      console.log('背景塞俄......', value)
      this.canvas.backgroundColor = value
      this.canvas.setBackgroundColor(value, this.canvas.renderAll.bind(this.canvas))
    },
    visibleChange(e) {
      console.log('visi...', e)
      if (!e) {
        this.isShowTips = true
      }
    },
    handleTips() {
      this.isShowTips = false
    },
    undo() {
      this.$emit('undo')
    },
    redo() {
      this.$emit('redo')
    },
    handFlip(val) {
      this.copyFlip(val).then(res => {
        this.saveCanvasState()
      })
    },
    handleClearCanvas() {
      this.$emit('clearCanvas')
    },
    // 复制图层
    handleCopyObject() {
      this.copyObject()
      this.saveCanvasState()
    },
    duplicateHorizontally() {
      this.$emit('duplicateHorizontally')
    },
    handleSwitch() {
      if (this.mode2 === 1) {
        this.$emit('setMode', 2)
      } else {
        this.$emit('setMode', 1)
      }
    }
  }
}
</script>
<style >
.opera-tips .ant-tooltip-inner {
  background-color: white !important;
  word-break: keep-all !important;
  color: #333 !important;
  width: 439px;
}
.opera-tips .ant-tooltip-arrow::before {
  background-color: white !important;
}
.opera-tips .folders-tooltip .ant-tooltip-inner {
  background-color: white !important;
  word-break: keep-all !important;
}

.ant-tooltip-inner {
  background-color: white !important;
  word-break: keep-all !important;
}
.folders-tooltip .ant-tooltip-inner {
  background-color: white !important;
  word-break: keep-all !important;
}
.folders-tooltip .ant-tooltip-arrow::before {
  background-color: white !important;
}
.folders-tooltip .folders-opera-list {
  color: #ff9a40;
  height: 39px;
  line-height: 39px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.folders-tooltip .folders-opera-list:last-child {
  color: #ff5347;
}
</style>
<style scoped lang="scss">
.switchMode{
  position: relative;
  .switchMode-button{
    width: 136px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background: #434343;
    color: white;
    position: relative;
    z-index: 100;
    margin-left: 20px;
    border-radius: 8px;
    cursor: pointer;
    &:hover{
      background: #333;
    }
  }
  .switchMode-mask{
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(77,77,77,0.33);
    z-index: 100;
    top: 0;
    left: 0;
  }
  .switchMode-con{
    text-align: left;
    position: absolute;
    top: 50px;
    left: 20px;
    width: 506px;
    height: 272px;
    background: white;
    z-index: 100;
    color: #333;
    padding: 24px;
    border-radius: 8px;
    .switchMode-title{
      font-size: 16px;
      font-weight: 500;
    }
    .switchMode-desc{
      font-size: 12px;
      margin-top: 16px;
      line-height: 1.5;
    }
    .switchMode-radios{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      box-sizing: border-box;
      .switchMode-radio{
        padding: 0 16px;
        width: 219px;
        height: 52px;
        display: flex;
        align-items: center;
        border-radius: 8px 8px 8px 8px;
        box-sizing: border-box;
        border: 1px solid #DEDEDE;
        cursor: pointer;
        &.actived{
          border: 2px solid #333333;
        }
        i{
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url(../../../assets/images/custom/radio-wxz.png) no-repeat center;
          background-size: 100%;
          &.actived{
            background: url(../../../assets/images/custom/radio-xz.png) no-repeat center;
            background-size: 100%;
          }
        }
        span{
          margin-left: 20px;
        }
      }
    }
    .btns{
      position: absolute;
      bottom: 23px;
      width: 100%;
      display: flex;
      align-items: center;
      .btn-delete{
        width: 70px;
        height: 36px;
        color: #FF5347;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        &:hover{
          border-radius: 3px 3px 3px 3px;
          background: #FFF4F4;
        }
      }
      .btn-cancel{
        width: 93px;
        height: 38px;
        border-radius: 4px 4px 4px 4px;
        color: #bababa;
        line-height: 38px;
        font-size: 16px;
        text-align: center;
        margin-left: auto;
        cursor: pointer;
        color: #8D8D8D;
        background: #F5F5F5;
      }
      .btn-confirm{
        width: 93px;
        height: 38px;
        background: #ff5347;
        border-radius: 4px 4px 4px 4px;
        color: white;
        line-height: 38px;
        font-size: 16px;
        text-align: center;
        margin-right: 50px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}

.tips-content{
  padding: 13px 8px;
}
.tips-title{
  font-size: 14px;
  font-weight: bold;
  color: #000;
}
.tips-con{
  color: #333;
  font-size: 12px;
  line-height: 18px;
  padding-top: 4px;
}
.opera {
  height: 64px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  .dpi-con{
    width: 250px;
    display: flex;
    margin-left: auto;
  }
  .dpi{
    padding: 8px 15px;
    background: #FF9A40;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    color: white;
    z-index: 100;
    display: inline-block;
    margin-left: auto;
    margin-right: 20px;

    &.Good{
      background: #46CE51;
    }
    &.Normal{
      background: #FF9A40;
    }
    &.Bad{
      background: red;
    }
    &.wait{
      background: #C8C8C8;
    }
  }
  &-tishi {
    position: relative;
    z-index: 100;
    width: 200px;
    display: flex;
    align-items: center;
    .switch{
      width: 71px;
      height: 38px;
      background: #ECECEC;
      border-radius: 135px 135px 135px 135px;
      display: flex;
      align-items: center;
      position: relative;
      left: 24px;
      .switch-li{
        flex: 1;
        text-align: center;
        width: 30px;
        height: 30px;
        img{
          position: relative;
          top: 2px;
          width: 30px;
          height: 30px;
          z-index: 10;
        }
      }
      .switch-active{
        width: 28px;
        height: 28px;
        border-radius: 50px;
        position: absolute;
        top: 5px;
        left: 5px;
      }
      .one{
        background-color: white;
        left: 5px;
      }
      .more{
        background-color: #333;
        left: 40px;
      }
    }
    img {
      width: 64px;
      height: 64px;
    }
    &-goods {
      width: 141px;
      height: 32px;
      background: #ff9a40;
      border-radius: 4px 4px 4px 4px;
      color: white;
      line-height: 32px;
      text-align: center;
      margin-left: auto;
      margin-right: 23px;
    }
  }
  &-main {
    background-color: white;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    justify-content: center;
    height: 64px;
    z-index: 10;
    position: absolute;
    &-line {
      width: 0px;
      height: 34px;
      opacity: 1;
      border: 1px solid #ebebeb;
      margin: 0 10px;
    }
    &-list {
      display: flex;
      align-items: center;
      margin: 0 10px;
      position: relative;
      img {
        width: 40px;
      }
    }
    .colorpick{
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      text-align: center;
      opacity: 0;
      z-index: 1;
    }
    .huitui{
      width: 40px;
      height: 40px;
      background: url('../../../assets/images/custom/huitui.png') no-repeat;
      background-size: 100% 100%;
      &:hover{
        background: url('../../../assets/images/custom/huitui2.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .qianjin{
      width: 40px;
      height: 40px;
      background: url('../../../assets/images/custom/qianjin.png') no-repeat;
      background-size: 100% 100%;
      &:hover{
        background: url('../../../assets/images/custom/qianjin2.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .head-shanchu{
      width: 40px;
      height: 40px;
      background: url('../../../assets/images/custom/head-shanchu.png') no-repeat;
      background-size: 100% 100%;
      &:hover{
        background: url('../../../assets/images/custom/head-shanchu2.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .shuaxin{
      width: 40px;
      height: 40px;
      background: url('../../../assets/images/custom/shuaxin.png') no-repeat;
      background-size: 100% 100%;
      &:hover{
        background: url('../../../assets/images/custom/shuaxin2.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .tishi{
      width: 40px;
      height: 40px;
      background: url('../../../assets/images/custom/tishi_moren.svg') no-repeat;
      background-size: 100% 100%;
      &:hover{
        background: url('../../../assets/images/custom/tishi_moren2.svg') no-repeat;
        background-size: 100% 100%;
      }
    }
    .duichengfuzhi{
      width: 40px;
      height: 40px;
      background: url('../../../assets/images/custom/duichengfuzhi.png') no-repeat;
      background-size: 100% 100%;
      &:hover{
        background: url('../../../assets/images/custom/duichengfuzhi2.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .fuzhi{
      width: 40px;
      height: 40px;
      background: url('../../../assets/images/custom/fuzhi.png') no-repeat;
      background-size: 100% 100%;
      &:hover{
        background: url('../../../assets/images/custom/fuzhi2.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .background{
      width: 40px;
      height: 40px;
      background: url('../../../assets/images/custom/beijing-icon.png') no-repeat;
      background-size: 100% 100%;
      &:hover{
        background: url('../../../assets/images/custom/beijing-icon2.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  &-align {
    display: flex;
    &-list {
      width: 80px;
      text-align: center;
      span.ch{
        display: inline-block;
        width: 40px;
        height: 38px;
        background: url('../../../assets/images/custom/jxsp.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../../../assets/images/custom/jxsp2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      span.cv{
        display: inline-block;
        width: 40px;
        height: 38px;
        background: url('../../../assets/images/custom/jxcv.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../../../assets/images/custom/jxcv2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      span.mh{
        display: inline-block;
        width: 40px;
        height: 38px;
        background: url('../../../assets/images/custom/jxmh.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../../../assets/images/custom/jxmh2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      span.mv{
        display: inline-block;
        width: 40px;
        height: 38px;
        background: url('../../../assets/images/custom/jxcz.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../../../assets/images/custom/jxcz2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      // img {
      //   width: 40px;
      //   height: 38px;
      // }
      p {
        word-break: keep-all;
        font-size: 12px;
        color: #BABABA;
        margin-top: 7px;
      }
      &:hover p{
        color: #444 !important;
      }
    }
  }
}
</style>
