<template>
  <div class="preview">
    <a-carousel arrows :dots="false" :after-change="afterChange">
      <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
        <!-- <a-icon type="left-circle" /> -->
        <img src="@/assets/images/custom/left-icon.png" alt="">
      </div>
      <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
        <!-- <a-icon type="right-circle" /> -->
        <img src="@/assets/images/custom/right-icon.png" alt="">
      </div>
      <div v-for="(item,index) in realtimePreviewImageUrls" :key="index">
        <img :src="item" alt="">
      </div>
    </a-carousel>
    <div v-show="realTimePreviewLoading" class="dis-progress">
      <div class="progress">
        <span class="progress-text">GENERATING...</span>
        <span>{{ progress }}%</span>
      </div>
      <a-progress
        :percent="progress"
        :show-info="false"
        stroke-color="#444"
      />
    </div>
    <div class="pages">
      {{ currIndex }}/{{ realtimePreviewImageUrls.length }}
    </div>
    <div class="yulan cur" @click="toPreview">
      <img src="@/assets/images/custom/yulan.png" alt="">
      <span>Preview</span>
    </div>
    <!-- 预览弹窗 -->
    <preview-dialog ref="previewDialog" :generate-preview-loading="generatePreviewLoading" :realtime-preview-image-urls="previewImageUrls" />
  </div>
</template>
<script>
import previewDialog from './preview-dialog.vue'
export default {
  components: {
    previewDialog
  },
  props: {
    // 实时预览图片列表
    realtimePreviewImageUrls: {
      type: Array,
      default: () => []
    },
    // 预览图片列表
    previewImageUrls: {
      type: Array,
      default: () => []
    },
    // 实时预览
    realTimePreviewLoading: {
      type: Boolean,
      default: false
    },
    generatePreviewLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      progress: 0,
      currIndex: 1
    }
  },
  watch: {
    realTimePreviewLoading(val) {
      const _this = this
      let set = null
      let count = 0
      this.progress = 0
      if (val === true) {
        set = setInterval(() => {
          count = count + 1
          if (count === 8) {
            clearInterval(set)
          }
          _this.progress = _this.progress + 10
        }, 1000)
      } else {
        clearInterval(set)
        this.progress = 100
      }
    }
  },
  methods: {
    toPreview() {
      this.$emit('preview')
      this.$refs.previewDialog.previewIsShowDialog = true
    },
    afterChange(e) {
      this.currIndex = e + 1
      console.log('afterChange....', e)
    }
  }
}
</script>
<style scoped  lang="scss">
.dis-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    top: 10px;
    z-index: 1;
  }
  .progress{
    display: flex;
    align-items: center;
  }
  .progress-text {
    display: inline-block;
    padding: 5px 10px;
    color: white;
    border-radius: 50px;
    background: #444;
  }
  span:nth-child(2) {
    margin-left: auto;
    color: #444;
  }
</style>
<style scoped >
.pages{
  position: absolute;
  bottom: 12px;
  left: 16px;
  color: #BEBEBE;
  font-size: 14px;
  line-height: 14px;
}
.preview {
  margin: 20px 31px 20px 27px;
  width: 264px;
  height: 264px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}
.yulan {
  width: 113px !important;
  height: 36px;
  background: rgba(51, 51, 51, 0.2);
  border-radius: 10px;
  border-top-right-radius: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
}
.yulan:hover{
    background: #333;
  }
.yulan img {
  width: 22px;
  height: 22px;
  margin-left: 8px;
}
.yulan span {
  margin-left: 6px;
  color: white;
  font-size: 16px;
}
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 264px;
  line-height: 264px;
  overflow: hidden;
  background-color: #cbcbcb;
}
.ant-carousel >>> .slick-slide img {
  width: 264px;
  height: 264px;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  /* background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3; */
}
.ant-carousel >>> .custom-slick-arrow img{
  width: 25px;
  height: 25px;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>
