<template>
  <div class="sku">
    <div class="sku-main">
      <div>
        <div class="sku-titles">Design version selection</div>
        <div class="sku-con">
          <div v-for="(item,index) in knifeSetInfoList" :key="index+item.name">
            <div class="sku-list" :class="{'actived': item.checked}" @click="changeSku(index,item)">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    knifeSetInfoList2: {
      type: Array,
      default: () => []
    },
    knifeSetIndex2: {
      type: Number
    }
  },
  data() {
    return {
      knifeSetInfoList: []
    }
  },
  watch: {
    knifeSetIndex2(newValue, oldValue) {
      debugger
      this.knifeSetInfoList[newValue].checked = true
      console.log('set....', this.knifeSetInfoList)
    },
    knifeSetInfoList2(newValue, oldValue) {
      this.knifeSetInfoList = newValue
    }
  },
  created() {
    if (this.knifeSetInfoList2) {
      console.log('this.knifeSetInfoList2....', this.knifeSetInfoList2)
      this.knifeSetInfoList = this.knifeSetInfoList2
    }
    if (this.knifeSetIndex2 || this.knifeSetIndex2 === 0) {
      this.knifeSetInfoList[this.knifeSetIndex2].checked = true
      console.log('set....', this.knifeSetInfoList)
    }
  },
  methods: {
    changeSku(index, item) {
      this.knifeSetInfoList.forEach(item => {
        item.checked = false
      })
      item.checked = !item.checked
      console.log('item...', item.checked)
      this.$emit('changeKnifeSku', index)
      this.$forceUpdate()
    }
  }
}
</script>
<style scoped lang="scss">
.sku-list {
  display: inline-block;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 10px;
  margin-top: 12px;
  cursor: pointer;
  border: 1px solid #ACACAC;
  color: #454545;
  img{
    width: 20px;
    height: 20px;
  }
  &.actived {
    border: 2px solid #333;
    color: #333;
  }
}
.sku-color-li{
  width: 28px;
  height: 28px;
  border: 2px solid #fff;
  border-radius: 28px;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  &.actived{
    border: 2px solid #333;
  }
  .border-white{
    border: 2px solid #C5C5C5;
  }
}
.sku-color {
  width: 20px;
  height: 20px;
  // background: #777777;
  border-radius: 20px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  top: 3px;
  left: 3px;
}
.sku-image {
  padding: 4px 8px;
  border-radius:4px;
  margin-right: 10px;
  margin-top: 12px;
  display: inline-block;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #777;
  img{
    width: 24px;
    height: 24px;
  }
  span{
    margin-left: 8px
  }
  &.actived {
    border: 2px solid #2D2D2D;;
    color: #2D2D2D;
  }
}

.sku {
  .sku-main {
    width: 353px;
    padding: 0 20px;
  }
  &-titles {
    font-size: 16px;
    font-weight: Medium;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  .disabled{
    cursor: no-drop !important;
    border: 1px solid #EFEFEF;
    color: #C0C0C0;
  }
  .sku-con {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
