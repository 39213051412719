<template>
  <div class="brand">
    <div v-for="(item, index) in brandList2" :key="index" class="brand-box " :class="actived == index ? 'actived' : ''" @click="changeBrand(index)">
      <img :src="item.mainImageUrl" alt="">
      <div class="brand-con">
        <!-- <div class="brand-name ellipsis">{{ item.model }}</div> -->
        <div class="brand-price">${{ item.price }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    brandList: {
      type: Array,
      default: () => []
    },
    productModeNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      brandList2: [],
      actived: 0
    }
  },
  watch: {
    brandList: {
      handler(newValue, oldValue) {
        this.brandList2 = newValue
      },
      deep: true
    }
  },
  created() {
    if (this.brandList) {
      this.brandList2 = this.brandList
    }
  },
  methods: {
    changeBrand(index) {
      this.actived = index
      this.$emit('changeBrand', index + this.productModeNum)
    }
  }
}
</script>
<style scoped lang="scss">
.brand {
  width: 204px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  .brand-box {
    width: 164px;
    height: 62px;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    border: 1px solid #dcdcdc;
    margin: 0 20px;
    margin-top: 20px;
    border-radius: 5px 5px 5px 5px;
    cursor: pointer;
    &:hover{
      border: 2px solid #333;
    }
    &.actived {
      border: 2px solid #333;
    }
    img {
      width: 35px;
      height: 42px;
    }
    .brand-con {
      margin-left: 8px;
      .brand-name {
        font-size: 18px;
        color: #444;
        font-weight: 500;
        width: 90px;
      }
      .brand-price {
        font-size: 18px;
        color: #ff5347;
      }
    }
  }
}
</style>
